import { num_format, range } from "@/js/util/math";

const SPARTA_TIME = {
    START_HOUR : 5,
    START_MINSTAMP : (5 * 60),
    END_HOUR : 2,
    END_MINSTAMP : (27 * 60),
    
    MS_IN_HOUR : (1000 * 60 * 60),
    MS_IN_MIN : (60 * 1000),
    SECTION_MARGIN : 10, // 섹션 앞뒤로 10분

    START_WEEK_WITH_SUNDAY : false,

    GetSpartaDate(date = new Date()) {
        let curr_date = date;
        const curr_hour = curr_date.getHours();
        if (curr_hour <= this.END_HOUR + 1) curr_date.setHours(curr_hour - this.END_HOUR - 1);
        return curr_date;
    },
    GetNowDate() {
        return this.GetSpartaDate(new Date());
    },
    GetWeekDay(t)
    {
        var week_index = t.getDay();
        if(this.START_WEEK_WITH_SUNDAY == false)
        {
            week_index--;
            if(week_index == -1) week_index = 6;
        }

        return week_index;
    },

    GetDateToday0()
    {
        var t = new Date();
        t.setHours(0, 0, 0);

        return t;
    },

    GetSectionPinInfo(section, t)
    {
        const stay = section.stay;
        const section_start = this.MinstampToTimestamp(t, stay.start.minstamp - this.SECTION_MARGIN).getTime();
        const section_end = this.MinstampToTimestamp(t, stay.end.minstamp + this.SECTION_MARGIN).getTime();

        const t_t = t.getTime();
        var timeinfo = null;

        if(section_start <= t_t && t_t <= section_end)
        {
            timeinfo = {
                jts_start : section_start,
                jts_end : section_end,
                jts_pin : (section_end - section_start) / 2 + section_start,
            };
        }

        return timeinfo;
    },

    FindSectionByTimestamp(stay_sections, timestamp)
    {
        var r = -1;
        const t = new Date(timestamp);
        const week_index = this.GetWeekDay(t);
        const sections = stay_sections[week_index]?.sections;
        var timeinfo = {};
        console.log(sections);
        sections.forEach((item, index) => {
            var ti = this.GetSectionPinInfo(item, t);
            if(ti != null)
            {
                r = index;
                timeinfo = ti;
            }
        });

        return {
            week_index : week_index,
            section_index : r,
            timeinfo : timeinfo,
        }
    },

    MinstampTo24(minstamp)
    {
        var h = Math.floor(minstamp / 60);
        var m = minstamp % 60;

        return num_format(h) + ":" + num_format(m);
    },

    MinstampToTimestamp(t, minstamp)
    {
        var now = new Date(t);
        now.setHours(0, 0, 0);
        var nt = now.getTime();
        nt += (minstamp * 60 * 1000);
        return new Date(nt);
    },

    GetTimeStampForDay(time)
    {
        if(time != undefined)
        {
            var dt = new Date(time.year + "-" + time.month + "-" + time.day);
            return dt.getTime();
        }
    },

    GetMinStampStucture(min_stamp)
    {
        var start_t = new Date("2020-01-01 00:00").getTime();
        start_t += (min_stamp * 1000 * 60);
        start_t = new Date(start_t);

        var h = start_t.getHours();
        if(h < 5) h += 24;
        var hour = num_format(h);

        var min = num_format(start_t.getMinutes());
        return {
            hour : hour,
            min : min,
        }
    },

    GetTodayStr()
    {
        var date = new Date();
        var hour = date.getHours();
        if (hour < 4) 
        {
            hour += 24;
            date = new Date(date.getTime() - (1000 * 60 * 60 * 5));
        }
        return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    },


    GetSparta30MinValue(t)
    {
        return Math.floor(this.GetSpartaMinStampValue(t) / 30) * 30;
    },

    GetSpartaMinStampValue(t, h, m)
    {
        var start_t = new Date("2020-01-01 00:00").getTime();
        var cur_t = new Date(t);

        if(t == null && h != undefined && m != undefined)
        {
            cur_t = this.SpartTimeToStdTime("2020-01-01 " + h + ":" + m + ":00").getTime();
            // console.log(h, m);
        } else {
            var cur_hour = cur_t.getHours();
            var cur_min = cur_t.getMinutes();
            cur_t = new Date("2020-01-01 " + cur_hour + ":" + cur_min).getTime();
        }

        // console.log("start_t", start_t / 1000 / 60);
        // console.log("cur_t", cur_t / 1000 / 60);

        return Math.floor((cur_t - start_t) / 1000 / 60);
    },

    StdTimeToSpartaTime(time)  // timestamp of date
    {
        var dt = new Date(time);
        // console.log(dt);
        var ssparta_time = {
            year : Number(dt.getFullYear()),
            month : Number(dt.getMonth() + 1),
            day : Number(dt.getDate()),
            hour : Number(dt.getHours()),
            minute : Number(dt.getMinutes()),
            second : Number(dt.getSeconds()),
        };

        var timestamp = this.GetTimeStampForDay(ssparta_time);
        // console.warn(new Date(timestamp));
        var offset_day = 0;
        while(ssparta_time.hour <= 4)
        {
            timestamp -= (this.MS_IN_HOUR * 24);
            ssparta_time.hour += 24;
        }
        var ndt = new Date(timestamp);
        ssparta_time = {
            year : Number(ndt.getFullYear()),
            month : Number(ndt.getMonth() + 1),
            day : Number(ndt.getDate()),
            hour : ssparta_time.hour,
            minute : Number(dt.getMinutes()),
            second : Number(dt.getSeconds()),
        };

        return ssparta_time;
    },

    SpartTimeToStdTime(sparta_time)
    {
        if(typeof sparta_time === 'string')    // 시간 string
        {
            while(sparta_time.indexOf("  ") != -1)  // 공백 제거
                sparta_time.replace("  "," ");

            const strs = sparta_time.split(" ");
            const str_date =  strs[0];
            const str_dates = str_date.split("-");
            const str_time = strs[1];
            const str_times = str_time.split(":");

            const ssparta_time = {
                year : Number(str_dates[0]),
                month : Number(str_dates[1]),
                day : Number(str_dates[2]),
                hour : Number(str_times[0]),
                minute : Number(str_times[1]),
                second : Number(str_times[2]),
            };
            
            var timestamp = this.GetTimeStampForDay(ssparta_time);// + (1000 * 60 * new Date().getTimezoneOffset());
            // var timestamp = this.GetTimeStampForDay(ssparta_time) + (1000 * 60 * new Date().getTimezoneOffset());
            var offset_day = 0;
            while(ssparta_time.hour >= 24)
            {
                ssparta_time.hour -= 24;
                offset_day++;
            }
            timestamp += (
                ((this.MS_IN_HOUR * 24) * offset_day) // day
                + ((this.MS_IN_HOUR * ssparta_time.hour)) // hour
                + ((this.MS_IN_HOUR / 60 * ssparta_time.minute)) // min
                + ((ssparta_time.second * 1000)) // second
            );
            return new Date(timestamp);
        }
    },

    GetMinuteValue: function (m, unit) {
        if (unit == undefined) unit = 30;

        var min_value = this.GetSpartaTime(m) / (1000 * 60); // 5시간 shift
        var r = Math.floor(min_value / unit);
        console.log(m, r);
        return r;
    },

    CheckSectionCollision: function (t, n) {
        // var r = false;

        var t_start = t.start.minstamp;
        var t_end = t.end.minstamp;

        var n_start = n.start.minstamp;
        var n_end = n.end.minstamp;

        if(t_end <= n_start || n_end <= t_start)
            return false;
        else
            return true;

        // if (
        //     (t_start <= n_start && n_start < t_end) ||
        //     (t_start < n_end && n_end <= t_end)
        // ) {
        //     console.log(t, n, t_start, t_end, n_start, n_end);
        //     return true;
        // }

        // if (
        //     (n_start <= t_start && t_start < n_end) ||
        //     (n_start < t_end && t_end <= n_end)
        // ) {
        //     console.log(t, n, t_start, t_end, n_start, n_end);
        //     return true;
        // }

        // if(n_start == t_start && n_end == t_end){
        //     console.log(t, n, t_start, t_end, n_start, n_end);
        //     return true;
        // }
        // return false;
    },

    CheckCollision: function (
        target_section_array,
        new_section,
        exclude_index
    ) {
        var r = false;
        target_section_array.forEach((item, index) => {
            if (index != exclude_index) {
                if (this.CheckSectionCollision(item.stay, new_section.stay)) {
                    r = true;
                    return;
                }
            }
        });

        return r;
    },
    
    GetMinStampRangeForInterval(start_minstamp, end_minstamp, interval = 30) {
        const range_start_number = 0;
        const range_end_number =  Math.ceil((end_minstamp - start_minstamp) / interval);
        return range(range_start_number, range_end_number).map(item => (item * interval) + start_minstamp )
    },

    GetSpartaUtsRange(date = new Date()) {
        if(date) date = new Date(date);
        if(!date && date?.toString() !== "Invalid Date" && !isNaN(date)) date = new Date();

        if (date.getHours() < this.START_HOUR) {
            date.setDate(date.getDate() - 1);
        }

        const timezone = date.getTimezoneOffset() * this.MS_IN_MIN;
        let dateTime = new Date(date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()).getTime() + timezone;
        const start = (dateTime + this.MS_IN_HOUR * this.START_HOUR) / 1000;
        const end = (dateTime + this.MS_IN_HOUR * (this.START_HOUR + 24)) / 1000;

        return { start, end };
    },
};

export default SPARTA_TIME;