<template>
    <div class="select_study_data_table">
        <div>
            <div class="select_table_title">
                선택한 학습 자료
            </div>
            <div>
                총 {{study_data.check_number}}건
            </div>
        </div>
        <ul>
            <li v-for="(data, idx) in study_data.data" :key="idx" v-show="data.check">
                <div class="same_row01">{{data.category}}</div>
                <div class="same_row02">{{data.name}}</div>
                <div class="same_row03">{{pay_to_string(data.price)}}</div>
                <div class="same_row06">{{String.fromCharCode((data.security - 1) + "A".charCodeAt(0))}}</div>
            </li>
        </ul>
    </div>
</template>

<script>
import {pay_to_string} from "@/js/util/math"

export default {
    props : {
        study_data : Object,
    },
    methods : {
        pay_to_string(value) {
            return pay_to_string(value)
        },
    }
}
</script>

<style scoped>

.select_study_data_table::-webkit-scrollbar  {
    width: 16px;
}
.select_study_data_table::-webkit-scrollbar-thumb{
    background-color: #d9d9d9;
}
.select_study_data_table::-webkit-scrollbar-track {
    background-color: #f2f2f2;
}
.select_study_data_table {
    width: 876px;
    height: 198px;
    margin: 0 auto;
    padding-right: 10px;
    box-sizing: border-box;
    overflow-y: scroll;
}
.select_study_data_table > div {
    display: flex;
    justify-content: space-between;
    height: 41px;
    line-height: 41px;
    background-color: #f2f2f2;
    position: sticky;
    top: 0;
    border-bottom: 1px solid #9c9c9c;
    padding: 0 20px;
    transform:translateY(-1px);
}
.select_table_title {
    font-weight: bold;
    color: #1E3560;
}
.select_study_data_table > ul > li {
    display: flex;
    text-align: center;
    height: 41px;
    border-bottom:1px solid #9c9c9c;
    line-height: 41px;
}
.same_row01,
.same_row03 {
    width: 100px;
}
.same_row02 {
    width: 500px;
    text-align: left;
}
.same_row04 {
    width: 60px;
}
.same_row05 {
    width: 90px;
}
.same_row06 {
    width: 150px;
}

</style>