const routes = [
    {
        path : "/admin",
        component : ()=>import("@/apps/AppAdmin"),
        redirect: "/admin/main",
        children : [
            {
                path : "/admin/main",
                name : "/admin/main",
                component : ()=> import("@/components/admin/main")
            },
            {
                path : "/admin/student",
                name : "/admin/student",
                component : ()=> import("@/components/admin/student")
            },
            {
                path : "/admin/employee",
                name : "/admin/employee",
                component : ()=> import("@/components/admin/employee")
            },
            {
                path : "/admin/sms",
                name : "/admin/sms",
                component : ()=> import("@/components/admin/sms")
            },
            {
                path : "/admin/accountancy",
                name : "/admin/accountancy",
                component : ()=> import("@/components/admin/accountancy")
            },
            {
                path : "/admin/accountancystatic",
                name : "/admin/accountancystatic",
                component : ()=> import("@/components/admin/accountancystatic")
            },
            {
                path : "/admin/system",
                name : "/admin/system",
                component : ()=> import("@/components/admin/system")
            },
            {
                path: "/admin/account",
                component: () => import("@/components/manager/account/ManagerAccountEdit.vue")
            },
            // {
            //     path : "/admin/schedule",
            //     name : "/admin/schedule",
            //     component : ()=> import("@/components/admin/schedule/ConsultingSchoolScheduleLayout")
            // },
            
        ]
    }
]

export default routes;