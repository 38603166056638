import CommonData from "@/components/common/data/CommonData";
import {range} from "@/js/util/math"
import SPARTA_TIME from "./sparta_time";

const WEEKDAYS = {
    weekdays: ["월", "화", "수", "목", "금", "토", "일",],
    GetWeekDayString: function (weekday) {
        return this.weekdays[weekday];
    },
    GetWeekIndex : function(date) {
        // 오늘의 요일 index 전달.
        const weekindex = range(0,7).map(item => {
            // 일월화 순서를 월화수 index로 변경.
            // sparta time 적용.
            return WEEKDAYS.GetWeekDayString(item) == CommonData.week_type[date.getDay()] ? item : ""
        }).join("")
        return Number(weekindex)
    },
    GetTodayIndex :function(is_sparta_time = true) {
        if(is_sparta_time) {
            return this.GetWeekIndex(SPARTA_TIME.GetNowDate())
        }
        else {
            return this.GetWeekIndex(new Date())
        }
    }
};

export { WEEKDAYS };
