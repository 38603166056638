import jwtService from "@/services/jwt.service";

const ROLE = {
    UNDEFINED : undefined,
    STUDENT : 0,
    FRONT : 1,
    CONSULT_TEACHER : 2,
    MANAGER : 3,
    ADMIN : 4,
    FRANCHISEADMIN : 5,
    COMPANYADMIN : 7,
    SYSTEMADMIN : 10,

    get_string(type)
    {
        switch(type)
        {
            case this.STUDENT:return "학생";
            case this.FRONT:return "프론트";
            case this.CONSULT_TEACHER:return "상담";
            case this.MANAGER:return "관리";
            case this.ADMIN:return "경영";
            case this.FRANCHISEADMIN:return "가맹대표";
            case this.SYSTEMADMIN:return "시스템";
            case this.COMPANYADMIN:return "경영대표";
            default: return "정의안됨";
        }
    },

    get_teacher_map()
    {

        return [
            [this.FRONT, this.get_string(this.FRONT)],
            [this.CONSULT_TEACHER, this.get_string(this.CONSULT_TEACHER)],
            [this.MANAGER, this.get_string(this.MANAGER)],
            [this.ADMIN, this.get_string(this.ADMIN)],
            [this.SYSTEMADMIN, this.get_string(this.SYSTEMADMIN)],
            // [this.COMPANYADMIN, this.get_string(this.COMPANYADMIN)],
        ];
        
    }
}

const CommonUtils = {
    redirect_by_role : function(context) {
        const user_info = jwtService.getUserInfo();

        console.log(user_info);

        var redirect_path = "/";
        switch(user_info.role)
        {
            case ROLE.STUDENT:
                redirect_path = "/student";
                break;
            case ROLE.FRONT:
                redirect_path = "/front";
                break;
            case ROLE.CONSULT_TEACHER:
                redirect_path = "/consulting";
                break;
            case ROLE.MANAGER:
                redirect_path = "/manager"; //관리 T
                break;
            // case ROLE.ADMIN:
            //     redirect_path = "/admin";
            //     break;

        }
        context.$router.push(redirect_path);
    },

    get_current_page_role(context, default_role = ROLE.UNDEFINED) {
        var current_path;
        var current_page_role = default_role
        if(typeof context == "string") {
            current_path = context
        }
        else if(typeof context == "object") {
            current_path = context.$route.path
        }
        
        if(current_path.startsWith("/front")) {
            current_page_role = ROLE.FRONT
        }
        else if(current_path.startsWith("/consulting")) {
            current_page_role = ROLE.CONSULT_TEACHER
        }
        else if(current_path.startsWith("/manager")) {
            current_page_role = ROLE.MANAGER
        }
        return current_page_role;
    },

    deep_copy: function (obj) {
        if (obj === null || typeof obj !== "object") {
            return obj;
        }

        let copy = {};
        for (let key in obj) {
            copy[key] = this.deep_copy(obj[key]);
        }
        return copy;
    },

}




export { CommonUtils, ROLE};