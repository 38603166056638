const range = (start, end) => {
    const arr = Array(end - start);
    const arrFill = arr.fill()
    arrFill.forEach((item, index) => {
        arrFill[index] = index + start
    })
    
    return arrFill;
}

const date_range = (start, end) => {
    const year_diff = end.year - start.year
    const month_diff = end.month - start.month
    const date_arr_fill = Array(year_diff * 12 + month_diff + 1).fill(0)
    date_arr_fill.forEach(function(item, index) {
        date_arr_fill[index] = {'month' : (index + start.month) % 12 === 0 ? 12 : (index + start.month) % 12,
                                'year' : start.year + Math.floor((index + (start.month - 1)) / 12)}
    })
    return date_arr_fill;
}

const num_format = (value, length = 2) => {
    const num_length = value.toString().length
    var r = "";
    for(var i = 0; i < length - num_length ; i++) {
        r += "0";
    }
    r += value.toString()
    return r;
}

const pay_to_string = (value) => {
    if(typeof value === 'string') {
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +'원' 
    }
    else if(typeof value === 'number'){
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +'원' 
    }
    return value
}

const string_time_to_diff = (start, end) => {
    let start_time = start.split(":")
    let end_time = end.split(":")
    if(start_time.length > 1 && end_time.length > 1) {
        let hour_diff = Number(end_time[0]) - Number(start_time[0])
        let minute_diff = Number(end_time[1]) - Number(start_time[1])
        let diff = hour_diff * 60 + minute_diff
        if(diff < 0) {
            diff = 1440 + diff
        }
        return diff
    }
    return null
}

const is_vaild_date = (date) => {
    if(date) {
        date = new Date(date)
    }
    return date.toString() !== "Invalid Date" && !isNaN(date)
}

const week_type = {
    0 : "일",
    1 : "월",
    2 : "화",
    3 : "수",
    4 : "목",
    5 : "금",
    6 : "토"
}

const date_format = (date, format = "YYYY/MM/DD") => {
    const p_date = new Date(date)
    if (!is_vaild_date(p_date) || !date) {
        return false;
    }
    let date_array = [p_date.getFullYear(), p_date.getMonth() + 1, p_date.getDate(), p_date.getHours(), p_date.getMinutes(), p_date.getSeconds(), week_type[p_date.getDay()]]
    date_array = date_array.map(item => { return {value : String(item).split(""), index : String(item).length -1} })
    let string_date = "", is_not_format = false
    format.split("").reverse().forEach(item => {
        if(item === '}') {
            is_not_format = true
        }
        else if(item === '{') {
            is_not_format = false
        }
        if(!is_not_format)
        {
            let date_array_index = null
            //년 0
            if(item === 'y' || item === 'Y') {
                date_array_index = 0
            }
            //월 1
            else if(item === 'M') {
                date_array_index = 1
            }
            //일 2
            else if(item === 'd' || item === 'D') {
                date_array_index = 2
            }
            //시 3
            else if(item === 'h' || item === 'H') {
                date_array_index = 3
            } 
            //분 4
            else if(item === 'm') {
                date_array_index = 4
            }
            //초 5
            else if(item === 's' || item === 'S') {
                date_array_index = 5
            }
            //요일
            else if(item === 'w'){
                date_array_index = 6
            }
            if(date_array_index !== null) {
                if(date_array[date_array_index].index < 0) {
                    string_date += "0"
                }
                else {
                    string_date += date_array[date_array_index].value[date_array[date_array_index].index]
                    date_array[date_array_index].index -= 1
                }
            }
            else {
                if(!(item === "{" || item === "}")) {
                    string_date += item
                }
            }
        }
        else {
            if(!(item === "{" || item === "}")) {
                string_date += item
            }
        }
    })
    return string_date.split("").reverse().join("")
}

const date_format_without_timezone = (timestamp, format = "HH 시간 mm 분") => {
    let dateFormat =new Date(timestamp)
    // without timezone
    dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset())
    return date_format(dateFormat, format)
}
const time_format = (time, unit = "min", hour_format = " 시간 ", minute_format = " 분") => {
    let unit_time = time
    if(unit.indexOf("min") !== -1) {
        unit_time = time
    }
    else if(unit.indexOf("sec") !== -1) {
        unit_time = Math.floor(time / 60)
    }
    else if(unit.indexOf("hour") !== -1) {
        unit_time = time * 60
    }
    else if(unit.indexOf("stamp") !== -1) {
        unit_time = Math.floor(time / 1000 / 60)
    }
    return num_format(Math.floor(unit_time / 60)) + hour_format + num_format(Math.floor(unit_time % 60)) + minute_format
}
const time_format_for_string = (time) => {
    const time_arr = time.split(":")
    let res = {}
    if(time_arr.length > 1) {
        res.hour = time_arr[0]
        res.minute = time_arr[1]
    }
    if(time_arr.length > 2) {
        res.second = time_arr[2]
    }
    return res
}
const sum_table_array = (array1, array2) => {
    if( array1.length > 0 &&
        array1.length == array2.length &&
        array1.some(item => array1[0].length && item.length == array1[0].length) &&
        array2.some(item => array2[0].length && item.length == array2[0].length) &&
        array1[0].length == array2[0].length
    ) {
        let array = array1
        return array.map((col_data, col_index) => {
            return col_data.map((row_data, row_index) => {
                if(row_data) {
                    return row_data
                }
                else {
                    return array2[col_index][row_index]
                }
            })
        })
    }
    return false
} 


export {
    range, 
    date_range, 
    num_format, 
    pay_to_string, 
    string_time_to_diff, 
    date_format,
    date_format_without_timezone,
    time_format,
    time_format_for_string,
    is_vaild_date,
    sum_table_array,
}