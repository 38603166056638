import ApiService from "@/services/api.service"
import studentStorage from "@/globalstorage/studentStorage"
import consultingStorage from "@/globalstorage/consultingStorage"
import {reactive} from 'vue'
import jwtService from "@/services/jwt.service"
import ManagerStorage from "@/globalstorage/ManagerStorage"
import { ROLE } from "@/js/util/common"

class gsClass {
    vue = {}
    user_role = new Map([
        [ROLE.STUDENT, studentStorage],
        [ROLE.FRONT, {}],
        [ROLE.MANAGER, ManagerStorage],
        [ROLE.CONSULT_TEACHER, consultingStorage],
        [ROLE.ADMIN, {...ManagerStorage, ...consultingStorage}],
    ])
    check_token() {
        if(jwtService.getAccessToken() === "" && jwtService.getRefreshToken() === "") {
            return false
        }
        return true
    }
    /*  
        key : refresh 해야될 키
        parent_refresh : 해당 키를 가져올 때, 필요한 모든 데이터를 refresh 하는 경우 true
    */
    refresh_data(key, parent_refresh = false) {
        if(!this.check_token()) {
            return false
        }
        const user_info = jwtService.getUserInfo()
        /* USER ROLE 에 따라 정보 확인 필요. */
        if(user_info !== "") {
            const data =this.convert_data_for_request(this.user_role.get(user_info.role), key, {parent_force : parent_refresh})
            this.dependence_request_data(data?.data)
            return true
        }
        return false
    }
    request_data_to_key(key) {
        return this.refresh_data(key , {parent_refresh : true})
    }
    convert_data_for_request(p_data, find_key, depth = 0, parent_force = false) {
        let force = false
        if(!p_data) {
            return ;
        } 
        const data = JSON.parse(JSON.stringify(p_data))
        Object.keys(data).forEach(key => {
            if(key === find_key) {
                data[key].force = true
                force = true
            }
            else if(data[key].dependence) {
                const res = this.convert_data_for_request(data[key].dependence, find_key, depth + 1)
                data[key].dependence = res.data
                if(res.force) {
                    if(parent_force) {
                        data[key].force = true
                    } else {
                        data[key].dependence_force = true
                    }
                }
            }
        })
        if(depth === 0) {
            return data
        }
        return {force : force , data : data}
    }
    async request_data() {
        if(!this.check_token()) {
            return false
        }
        const user_info = jwtService.getUserInfo()
        /* USER ROLE 에 따라 정보 확인 필요. */
        if(user_info) {
            this.clean_data()
            this.dependence_request_data(this.user_role.get(user_info.role))
            return true
        }
        return false
    }
    dependence_request_data(data, dependence_res = null) {
        if(data != undefined)
        Object.keys(data).forEach(key => {
            const value = data[key]
            if(!this.check_global_data(key) || value.force) {
                /* URL 세팅 */
                let url = value.url
                if(value.variable && dependence_res) {
                    const replace_array = Object.entries(value.variable).map((item) => {return dependence_res[item[0]][item[1]]})
                    url = url.replace(/{(\d+)}/g, function (match, number) {
                        return typeof replace_array[number] != 'undefined' ? replace_array[number] : match
                    })
                }
                if(dependence_res === null) {
                    dependence_res = {}
                }
                // GET, POST, PUT, DELETE
                let method = ApiService.GET; // default
                if(value.function) {
                    method = ApiService[value.function];
                }

                this.request(url, method, value.param).then(res => {
                    if(res) {
                        this.set_global_storage(key, res)
                    }
                    if(value.dependence) {
                        dependence_res[key] = res
                        this.dependence_request_data(value.dependence, dependence_res)
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
            else if(value.dependence_force) {
                const res = this.vue.config.globalProperties.$globalStorage.getters[key]
                this.dependence_request_data(value.dependence, res)
            }
        });
    }
    request(url, method, param) {
        return new Promise ((reslove, reject) => {
            ApiService.call(method, url, param).then(res => {
                reslove(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    set_global_storage(key, data) {
        this.vue.config.globalProperties.$globalStorage.getters[key] = reactive(data)
    }
    check_global_data(key) {
        if(this.vue.config.globalProperties.$globalStorage.getters[key]) {
            return true
        }
        return false
    }
    set_vue(vue){
        this.vue = vue
        this.vue.config.globalProperties.$globalStorage = reactive({})
        this.clean_data()
    }
    clean_data() {
        this.vue.config.globalProperties.$globalStorage.getters = reactive({})
    }
}

export default gsClass