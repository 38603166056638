<template>
    <section class="box">
        <div class="title_bar">
            {{ this.title }}
            <p v-if="this.msg" v-html="this.msg"></p>
        </div>
        <div class="circle_box">
            <p v-if="this.isPercentage">{{ this.per_text + "%" }}</p>
            <p v-else>Loading...</p>
            <svg class="circular cir_rotate">
                <circle class="dash" cx="100" cy="100" r="60" />
            </svg>
        </div>
        <div v-if="this.closeBtn"><button @click="this.close()">닫 기</button></div>
    </section>
</template>

<script>
let BTN_TIMER = null;
let ANI_TIMER = null;
export default {
    name: "LoadingModal",
    props: {
        title: String,
        msg: String,
        isPercentage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            closeBtn: false,
            percentage: 0,
            per_text: 0,
        };
    },
    methods: {
        close() {
            this.$ModalManager.close(this);
        },
        setPercent(e) {
            this.percentage = e;
            this.aniText();
        },
        aniText() {
            if (ANI_TIMER) clearTimeout(ANI_TIMER);
            ANI_TIMER = setTimeout(() => {
                if (this.per_text < this.percentage) {
                    this.per_text++;
                    this.aniText();
                }
            }, 20);
        },
    },
    mounted() {
        if (this.isPercentage) this.$EventManager.AddListener("loading_percentage", this, this.setPercent);
        BTN_TIMER = setTimeout(() => {
            this.closeBtn = true;
        }, 1000 * 60 * 3);
    },
    unmounted() {
        if (this.isPercentage) this.$EventManager.RemoveListener("loading_percentage", this.setPercent);
        clearTimeout(BTN_TIMER);
        clearTimeout(ANI_TIMER);
    },
};
</script>

<style lang="scss" scoped>
.box {
    cursor: wait;
    user-select: none;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.title_bar {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    p {
        color: #ccc;
        margin: 5px;
        font-size: 16px;
    }
}
.circle_box {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        position: absolute;
        color: #fff;
    }
}
.circular {
    height: 200px;
    position: relative;
    width: 200px;
    circle {
        fill: none;
        stroke-width: 10;
        stroke-linecap: round;
        stroke: #aaa;
    }
}
.cir_rotate {
    animation: rotate 3s linear infinite;
}

.dash {
    animation: dash 2s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes dash {
    0% {
        stroke-dasharray: 2, 400;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 300, 400;
        stroke-dashoffset: -70;
        stroke: #1e3560;
    }
    100% {
        stroke-dasharray: 200, 400;
        stroke-dashoffset: -375;
    }
}

button {
    margin-top: 30px;
    width: 100px;
    height: 30px;
    color: #fff;
    border-radius: 20px;
    border: none;
    font-size: 16px;
    background-color: #1e3560;
}
</style>