import { createApp } from 'vue'
import { createPinia } from 'pinia'
import AppMain from '@/App'
import router from "./router"
import ApiService from "@/services/api.service"
import EventManager from "@/services/eventmanager.service"
import ModalManager from "@/services/modal.service"
import ToastManager from "@/services/toastmsg.serviece"

import GlobalStorage from "@/services/storage.service"
import StrUtils from "@/js/str.utils"
import SpartaWS from './services/ws.sparta.service'
// import Vuex from "vuex"
import store from "@/store";

import SelectList from "@/components/common/SelectList.vue"
import SearchInput from "@/components/common/CommonSearchInput.vue"
import DayPicker from "@/components/common/DayPicker.vue"
import SearchBar from "@/components/common/SearchBar.vue"
import axios from "axios";
import VueAxios from "vue-axios";

import AwaitingButton from "@/components/common/AwaitingButton.vue"

import { EXSETTING } from "@/services/exapi.service.js"
EXSETTING.LoadExSetting();

ApiService.init();
const app = createApp(AppMain);
const pinia = createPinia();
app.component('select-list', SelectList);
app.component('search-text', SearchInput);
app.component('day-picker', DayPicker);
app.component('await-button', AwaitingButton);
app.component('search-bar', SearchBar);
app
    .use(VueAxios, axios)
    .use(EventManager)
    .use(SpartaWS)
    .use(router)
    .use(ModalManager)
    .use(store)
    .use(GlobalStorage)
    .use(ToastManager)
    .use(StrUtils)
    .use(pinia)
    .mount('#app');
// app.use(EventManager).use(SpartaWS).use(router).use(ModalManager).use(store).mount('#app');