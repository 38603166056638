const routes = [
    {
        path : "/student",
        component : ()=>import("@/apps/AppStudent"),
        redirect: "/student/main",
        children : [
            {
                path : "/student/main",
                name : "/student/main",
                component : ()=> import("@/components/student/main/StudentMainLayout")
            },
            {
                path : "/student/inouttime",
                name : "/student/inouttime",
                component : ()=> import("@/components/student/inoutupdate/StudentInOutLayout")
            },
            {
                path : "/student/planner",
                name : "/student/planner",
                component : ()=> import("@/components/student/planner/StudentPlannerUpdate")
            },
            {
                path : "/student/consutlinglist",
                name : "/student/consultinglist",
                component : ()=> import("@/components/student/consulting/StudentConsultingList")
            },
            {
                path : "/student/qnalist",
                name : "/student/qnalist",
                component : ()=> import("@/components/student/qna/StudentQnALayout")
            },
            {
                path : "/student/mindlist",
                name : "/student/mindlist",
                component : ()=> import("@/components/student/mind/StudentMindLayout")
            },
            {
                path : "/student/schedule",
                name : "/student/schedule",
                component : ()=> import("@/components/student/schedule/StudentScheduleLayout")
            },
            {
                path : "/student/point",
                name : "/student/point",
                component : ()=> import("@/components/student/point/StudentPointTable")
            },
            {
                path : "/student/account",
                name : "/student/account",
                component : ()=> import("@/components/student/StudentAccountFix")
            },
        ]
    }
]

export default routes;