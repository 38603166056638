<template>
    <li class="menuitem" :class="{ centername : item.centername }" v-bind:selected="item.selected" :notyet="item.disable">
        <p class="m_item" @click="menu_click(item)">
            {{item.title}}
        </p>
        <div v-if="item.description != undefined && item.description != ''" class="description" >
            <p v-html="item.description"></p>
            <img src="@/assets/images/trianglebig.png">
        </div>
    </li>
</template>

<script>
export default ({
    name : "ManuBarItem",
    props : {
        item : {},
    },
    emit : ["menu_item_clicked"],
    data() {
        return {
            
        }
    },
    created() {
        //console.log(this.item.description);
    },
    methods : {
        menu_click(item) {
            if(item.on_click == undefined)
                this.$emit("menu_item_clicked", item);
            else
            {
                item.on_click();
            }
        },
    }
})
</script>

<style scoped>
* {
    font-weight: bold;
    user-select: none;
}

li {
    text-align: center;
    box-sizing: border-box;
    position: relative;
    height: 30px;
    color: #fff;
    position: relative;
    line-height: 30px;
    border-radius: 40px;
    margin: 0 5px;
    min-width: fit-content;
    padding-left : 10px;
    padding-right : 10px;
    width : 100%;
}

li.centername > p 
{
    color: rgb(233, 254, 0);
    font-size : 15px !important;
    font-weight: normal !important;
}
li a {
    color: #fff;
    position: relative;
    font-size: 18px !important;
}

p.m_item {
    cursor: pointer;
    font-size: 18px !important;
}

li[selected="true"]
{
    background-color: #fff;
}

li[selected="true"] > p
{ 
    color: #1E3560;
}



li:hover .description {
    display: block;
 }

.menuitem::before {
    content: '';
    width: 1px;
    height: 20px;
    background-color: #666;
    position: absolute;
    right: -6px;
    top: 50%;
    transform: translateY(-50%);   
}
.menuitem:first-child:after {
    content: '';
    width: 1px;
    height: 20px;
    background-color: #666;
    position: absolute;
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
}

li.centername:after {
    width : 0px !important;
}



.description {
    position: absolute;
    font-size: 8px !important;
    width : fit-content;
    padding : 8px;
    border-radius: 15px;
    background-color: #61c1be;
    top: 54px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    line-height: 14px;
    text-align:left;
}
.description img {
    width: 15px;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
}

.description p, .description p div p {
    top: 50%;
    left: 50%;
    font-size : 12px !important;
    line-height: 15px;
    color: #fff;
}
</style>
