import { ApiReq } from "@/components/manager/ManagerUtils"
import { is_vaild_date } from "@/js/util/math"
import ApiService from "@/services/api.service"
import CommonData from "./CommonData"
import PANELTY_DATA from "@/components/common/student_week_schedule/js/panelty_data"
import SpartaWS from "@/services/ws.sparta.service"

export default {
    CONSULTING_TYPE : {
        WAITING : 0,     // 확정 대리
        REJECT : 1,      // 상담 반려
        CONFIRM : 2,     // 확정 완료
        COMPLETE : 3,    // 상담 완료
        CANCEL : -1,     // 상담 취소
    },
    get_consulting_type_title(status) {
        switch(Number(status)) {
            case this.CONSULTING_TYPE.WAITING : return "대기"
            case this.CONSULTING_TYPE.REJECT : return "반려"
            case this.CONSULTING_TYPE.CONFIRM : return "확정"
            case this.CONSULTING_TYPE.COMPLETE : return "진행"
            case this.CONSULTING_TYPE.CANCEL : return "취소"
            default : return ""
        }
    },

    qna_type : Object.entries(CommonData.consulting_qna_title).map(item => {
        return {description : item[1], value : item[0]}
    }),

    default_subject_QNA_consulting_type : "1",  // 서버에서 사용중인 과목 상담 type 값
    default_mind_consulting_type : "2",         // 서버에서 사용중인 마인드 상담 type 값
    find_subject_QNA_string : "과목",
    find_mind_string : "마인드",
    get_consulting_status_type_data(status) {
        switch(Number(status)) {
            case this.CONSULTING_TYPE.WAITING : {
                return {
                    name : "확정 대기",
                    button : "신청 취소"
                }
            }
            case this.CONSULTING_TYPE.REJECT : {
                return {
                    name : "상담 반려",
                    button : "반려 사유"
                }
            }
            case this.CONSULTING_TYPE.CONFIRM : {
                return {
                    name : "상담일 확정",
                }
            }
            case this.CONSULTING_TYPE.COMPLETE : {
                return {
                    name : "상담 완료",
                }
            }
            case this.CONSULTING_TYPE.CANCEL : {
                return {
                    name : "상담 취소",
                }
            }
            default : {
                return {}
            }
        }
    },
    // 현재 사용중인 상담 타입 업데이트
    // server -> vue
    update_default_consulting_type() {
        return new Promise((resolve, reject) => {
            const params = {
                p : 0
            }
            ApiService.call(ApiService.GET, "/counseling/types", params).then(res => {
                if(res && res.data) {
                    res.data.forEach(type_item => {
                        // 과목 상담 종류 번호 찾기
                        if(type_item.name.split(this.find_subject_QNA_string).length > 1) {
                            this.default_subject_QNA_consulting_type = type_item.no
                        }
                        // 마인드 상담 종류 번호 찾기
                        else if(type_item.name.split(this.find_mind_string).length > 1) {
                            this.default_mind_consulting_type = type_item.no
                        }
                    });
                    resolve({
                        mind_no : this.default_mind_consulting_type,
                        subject_no : this.default_subject_QNA_consulting_type,    
                    })
                }
                else {
                    reject("res or res.data is none")
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    // server -> vue
    // 상담 데이터 테이블 형태 변환
    convert_consulting_data_to_table_data(data) {
        let table_data = []
        data.forEach(item => {
            let status_value = null
            Object.values(this.CONSULTING_TYPE).forEach(value => {
                if(value == Number(item.status)) {
                    status_value = this.get_consulting_status_type_data(value)
                }
            })
            table_data.push({
                ...item,
                /* 추가 */
                status_value : status_value,
            })
        })
        return table_data
    },
    // server -> vue
    // 하위 상담 타입 select list 내 사용 가능 array 형태 전달.
    convert_consulting_type_to_list_array(raw_data) {
        let list_array = []
        raw_data.forEach(item => {
            list_array.push({
                ...item,
                value : item.no,
                description : item.name,
            })
        })
        return list_array
    },
    
    //server -> vue
    // 전체 상담 타입 select list 내 사용 가능 array 형태 전달.
    convert_all_consulting_type_to_list_array(raw_data, system = false, teacher = null) {
        let list_array = []
        let list_data = [
            // {type : this.default_mind_consulting_type, children : []},
            // {type : this.default_subject_QNA_consulting_type, children : []}
        ]
        //TODO 선생님 정보에서 가능한 상담을 선택 한 후 상담 신청시 가능한 상담만 노출
        // if (teacher) {
        //     try {
        //         const posible_counselings = JSON.parse(teacher.counseling_subject_qna)?.split(",");
        //         if (posible_counselings) raw_data = raw_data.filter(data => !data.parent_no || posible_counselings.includes(data.no));
        //     }
        //     catch (e) {
        //         console.log(e);
        //     }
        // }
        
        // 데이터 형태 
        // [
        //     {
        //         type : parent_data1,
        //         children : { child1 , child2, child3}
        //     },
        //     {
        //         type : parent_data2,
        //         children : { child1 , child2, child3}
        //     },
        // ]
        raw_data.forEach(item => {
            let b_check = false
            // system에서 요청되는 상담은 상담 요청시 표출 하지 않도록 수정.
            if(system && item.system && Number(item.system))  {
                return;
            }

            list_data.forEach((data_item, data_index) => {
                if(item.no == data_item.type) {
                    list_data[data_index] = {
                        ...data_item,
                        ...item,
                    }
                    b_check = true
                }
                else if (item.parent_no == data_item.type) {
                    list_data[data_index].children.push(item)
                    b_check = true
                }
            })
            // 신규 추가된 카테고리 리스트 추가
            if(!b_check) {
                list_data.push({type : item.no, children : [], ...item})
            }
        })
        // select_list에서 사용 가능 array로 변경.
        list_data.forEach(item => {
            list_array.push({
                ...item,
                description : item.name,
                isGroup : true,
            })
            item.children.forEach(sub_item => {
                list_array.push({
                    ...sub_item,
                    value : sub_item.no,
                    description : sub_item.name,
                })
            })
        })
        return list_array
    },

    get_consulting_date(item, counseling_check = true, cofirmed_check = true, request_check = true) {
        let date = ""
        // 상담 완료가 된 날짜
        if(counseling_check && item?.counseling_datetime && is_vaild_date(item.counseling_datetime)) {
            date = item.counseling_datetime
        }
        // 상담 확정이 된 날짜
        else if(cofirmed_check && item?.confirmed_datetime && is_vaild_date(item.confirmed_datetime)) {
            date = item.confirmed_datetime
        }
        // 상담 요청이 된 날짜
        else if(request_check && item?.request_datetime && is_vaild_date(item.request_datetime)) {
            date = item.request_datetime
        }
        return date
    },

    get_counseling_types(counseling, types = []){
        let counseling_types = [];
        if (counseling?.type_no) {
            counseling_types = JSON.parse(counseling.type_no);
            counseling_types = counseling_types instanceof Array ? [...counseling_types] : [counseling_types];
            counseling_types = types.filter(type => counseling_types.includes(Number(type.no))).sort((a,b)=>a-b);
        }
        return counseling_types;
    },

    async update_auto_counseling(req = {}) {
        const {
            student_id = null,
            status = null,
            center_no = null,
            type_no = null,
            req_is_auto = req?.requester_id == PANELTY_DATA.TEACHER_ID,
            request_message = null
        } = req;

        if (status != this.CONSULTING_TYPE.COMPLETE || !student_id) return;

        const param = {
            per_page: 999,
            status: [this.CONSULTING_TYPE.WAITING].join(),
            ctr: center_no,
            req: PANELTY_DATA.TEACHER_ID
        }

        new ApiReq("/counselings", param)
            .setResultCallback(async (res) => {
                if (res instanceof Array) {
                    // 학생의 자동 상담
                    const student_auto_counselings = res?.filter(e => e.student_id == student_id);
                    // 학생의 자동 상담이 없는 경우
                    if (!student_auto_counselings.length) return;

                    // 삭제할 자동 상담 종류
                    let is_elapsed, is_point;
                    if (type_no) {
                        // type_no 가 있을 경우에는 자동상담(상담경과일) 모두 삭제
                        // type_no include 10(벌점상담) 인 경우 자동상담(벌점) 모두 삭제
                        const point_type_no = 10;
                        const type = JSON.parse(type_no);
                        //벌점 상담만 할 경우 경과일삭제 x
                        is_elapsed = type.every(e => e == point_type_no) ? false : true;
                        //벌점 상담이 포함되어 있을 경우 벌점상담 삭제
                        is_point = type.some(e => e == point_type_no) ? true : false;
                    } else {
                        // type_no 가 null 인경우 자동상담,
                        is_elapsed = request_message.indexOf("상담") != -1 && req_is_auto ? true : false;
                        is_point = request_message.indexOf("벌점") != -1 && req_is_auto ? true : false;
                    }
                    // 삭제할 자동 상담 종류가 없는 경우
                    if (!is_elapsed && !is_point) return;

                    // 삭제할 상담 필터
                    const ret = student_auto_counselings.filter(cur => (is_elapsed && cur.request_message.indexOf("상담") != -1) || (is_point && cur.request_message.indexOf("벌점") != -1));
                    // 삭제할 상담이 없는 경우
                    if (!ret.length) return;

                    const param = {
                        status: this.CONSULTING_TYPE.CANCEL,
                        teacher_id: PANELTY_DATA.TEACHER_ID,
                        rejection_reason: "별개 상담으로 인한 자동상담 삭제"
                    };
                    for await (const counseling of ret) await new ApiReq("/counseling/" + counseling.no, param).put();

                    const dst_address = "__teacher/" + (center_no ?? "*") + "/*";
                    SpartaWS.socket.send("msg/check_list_update", { refresh: true }, dst_address);
                }
            })
            .get();
    }
}