<template>
    <section class="floating_section">
        <div class="toast_main">
            <transition-group name="tran_list">
                <template v-for="(msg, i) in this.messages" :key="i">
                    <div class="msg_box">
                        <div class="msg_title" @click="this.del(msg)">
                            <p>
                                {{ msg.title ?? "제목없음" }}
                            </p>
                            <div>
                                <button v-if="msg.call" @click="this.callBack(msg.call)">{{ msg.call.btnStr ?? "상세" }}</button>
                                <i class="material-icons">close</i>
                            </div>
                        </div>
                        <div class="msg_content">
                            {{ msg.content ?? "내용없음" }}
                        </div>
                    </div>
                </template>
            </transition-group>
        </div>
    </section>
</template>

<script>
import $ from "jquery";
export default {
    name: "ToastMsg",
    data() {
        return {
            messages: [],
        };
    },
    methods: {
        add(msg) {
            this.messages.push(msg);
        },
        del(msg) {
            this.messages = this.messages.filter((e) => e.no != msg.no);
        },
        clear() {
            this.messages = [];
        },
        get_msg_length() {
            return this.messages.length;
        },
        callBack(call) {
            if (call.callBack) call.callBack();
        },
    },
    beforeCreate() {
        this.$ToastManager.registerBase(this);
        $(document).ready(function () {
            const currentPosition = document.querySelector(".floating_section").offsetTop;
            $(window).scroll(function () {
                var position = $(window).scrollTop();
                $(".floating_section")
                    .stop()
                    .animate({ top: position + currentPosition + "px" }, 0);
            });
        });
    },
};
</script>

<style lang="scss" scoped>
.floating_section {
    z-index: 999;
    position: absolute;
    height: 100%;
    right: 0;
}
.toast_main {
    position: absolute;
    right: 20px;
    bottom: 0;
    color: #fff;
    font-size: 15px;
    width: 300px;
}
.msg_box {
    width: 300px;
    margin: 10px 0;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    > div {
        text-align: left;
        padding: 5px;
    }
    .msg_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 20px;
        background-color: #1e3560;
         cursor: pointer;
        > p {
            overflow: hidden;
            white-space: nowrap;
            margin-right: 10px;
            font-weight: bold;
        }
        > div {
            display: flex;
            align-items: center;
            button {
                width: 80px;
                height: 20px;
                border-radius: 20px;
                margin-right: 5px;
                overflow: hidden;
                white-space: nowrap;
                color: #6c6c6c;
            }
        }
    }
    .msg_content {
        overflow-y: auto;
        height: 50px;
        background-color: #fff;
        color: #6c6c6c;
        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #d9d9d9;
        }
        &::-webkit-scrollbar-track {
            background-color: #f2f2f2;
        }
    }
}

.tran_list-move,
.tran_list-enter-active {
    transition: all 0.5s ease;
}

.tran_list-enter-from {
    opacity: 0;
    transform: translateY(30px);
}

.tran_list-leave-active {
    position: absolute;
}
</style>