<template>
    <nav>
        <div class="nav_in">
            <ul>
                <MenuBarItem v-for="(item, index) in r_menu_items" v-bind:key="index" v-bind:item="item" @menu_item_clicked="menu_item_clicked(item)" />
            </ul>
        </div>
    </nav>
</template>

<script>
import MenuBarItem from "./MenuBarItem.vue";
import jwtService from "@/services/jwt.service.js";
import { ROLE } from "@/js/util/common";
import { EXAPI } from '@/services/exapi.service';

export default {
    name: "MenuBar",
    props: {
        user_type: String,
        menu_items: {}
    },
    emit: ["menu_item_clicked"],
    components: {
        MenuBarItem
    },
    data() {
        return {
            current_url: "",
            r_menu_items: {}
        };
    },
    mounted() {
        
    },
    created() {
        this.init_menubar_item();
        
    },
    methods: {
        async check_update()
        {
            if(!(await EXAPI.CheckVersion()))   // need to update
            {
                this.$ModalManager.showdialog("업데이트가 필요합니다.<br><br>Ctrl+F5를 눌러 새로고침 해주세요");
            }
        },
        menu_item_clicked(item) {
            // console.log("menu_item_clicked", item);
            this.check_update();
            this.r_menu_items.forEach((element) => {
                if (element.url == item.url) {
                    element.selected = true;
                    // console.log(element.url, true);
                } else {
                    element.selected = false;
                    // console.log(element.url, false);
                }
            });

            if (item.url === this.$route.path) {
                // url 동일한 경우 push시 새로고침 안됨. 히스토리에 쌓이지 않음.
                this.$router.go(item.url);
            } else {
                this.$router.push(item.url);
            }
        },
        update_menu_bar_highlight() {
            this.current_url = this.$route.path;
            // 해당 URL이 메뉴바에 존재하는지 확인. 없다면, 무시.
            if (!this.r_menu_items.some((element) => element.url == this.current_url)) {
                return;
            }
            this.r_menu_items.forEach((element) => {
                if (element.url == this.current_url && !element.disable) {
                    element.selected = true;
                } else {
                    element.selected = false;
                }
            });
        },
        init_menubar_item() {
            const _this = this;
            this.r_menu_items = this.menu_items;
            var user_info = jwtService.getUserInfo();

            if (this.r_menu_items[0].id != "admin_main" && !this.$route.path.startsWith("/admin") && user_info?.is_admin) {
            // if (this.r_menu_items[0].id != "admin_main" && !this.$route.path.startsWith("/admin") && (user_info?.role == ROLE.ADMIN || user_info?.role == ROLE.COMPANYADMIN)) {
                this.r_menu_items = [
                    {
                        id: "admin_main",
                        title: "경영메인",
                        url: "/admin"
                    },
                    ...this.r_menu_items
                ];
            }

            if (user_info?.is_admin) {
            // if (user_info.role == ROLE.COMPANYADMIN) {
                this.r_menu_items = [
                    {
                        id: "centername",
                        title: jwtService.getCompanyAdminCenterInfo().name,
                        disable: true,
                        centername: true,
                        url: "/"
                    },
                    ...this.r_menu_items
                ];

                // if (this.$route.path.startsWith("/admin")) {
                //     this.r_menu_items.splice(1, 0, {
                //         id: "selectcenter",
                //         title: "센터선택",
                //         url: "/selectcenter"
                //     });
                // }
            }

            // if(this.r_menu_items[this.r_menu_items.length - 1].id != "feedback" && user_info?.role != ROLE.STUDENT)
            //     this.r_menu_items.push(
            //         {
            //             id : "feedback",
            //             title : "feedback",
            //             on_click : ()=>{
            //                 _this.$ModalManager.showEx(feedback_write,
            //                 {
            //                     bind : {

            //                     },
            //                     on : {

            //                     }
            //                 });
            //             },
            //         },
            //     );

            this.update_menu_bar_highlight();
        }
    },
    watch: {
        "$route.path": function (value) {
            this.update_menu_bar_highlight();
            // console.log(this.$route.path);
        },
        menu_items: {
            handler: function (value) {
                this.init_menubar_item();
            },
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped>
nav {
    div.centername {
        color: white;
        padding: 5px;
    }
    position: fixed;
    left: 50%;
    top: 100px;
    transform: translateX(-50%);
    width: 100%;
    margin: 0 auto;
    height: 50px;
    background-color: #1e3560;
    display: flex;
    align-items: center;
    z-index: 90;
    font-size: 18px;
    font-weight: bold;
}
.nav_in {
    width: 1600px;
    height: 30px;
    margin: 0 auto;
}
.nav_in ul {
    width: 1600px;
    position: fixed;
    display: flex;
}
</style>
