import { createStore } from "vuex";
import userStorage from "./user.storage";

const store = createStore(
    {
        modules : {
            userStorage,
        }
    }
);

export default store;