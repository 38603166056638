const routes = [
    {
        path : "/front",
        component : ()=>import("@/apps/AppFront"),
        redirect: "/front/main",
        children : [
            {
                path : "/front/main",
                name : "/front/main",
                component : ()=> import("@/components/front/FrontMain")
            },
            {
                path: "/front/search",
                name: "/front/search",
                component: () => import("@/components/front/FrontSearch")
            },
            {
                path: "/front/planer",
                name: "/front/planer",
                component: () => import("@/components/front/FrontPlaner")
            },
            {
                path: "/front/student",
                name: "/front/student",
                component: () => import("@/components/front/FrontStudent")
            },
            {
                path: "/front/notice",
                name: "/front/notice",
                component: () => import("@/components/common/SendNotice/SendNotice")
            },
            {
                path: "/front/record",
                name: "/front/record",
                component : () => import("@/components/manager/ManagerRecordMain.vue"),
                props: true,
            },
            {
                path: "/front/score",
                name: "/front/score",
                component: () => import("@/components/front/FrontBadScore")
            },
            {
                path: "/front/sms",
                name: "/front/sms",
                component: () => import("@/components/admin/sms")
            },
            {
                path: "/front/center",
                name: "/front/center",
                component: () => import("@/components/common/CenterNotify/CenterNotify")
            },
            {
                path: "/front/schedule",
                name: "/front/schedule",
                component: () => import("@/components/front/Schedule/Schedule")
            },
            {
                path: "/front/payment",
                name: "/front/payment",
                component:()=>import("@/components/front/FrontPayment")
            },
            {
                path: "/front/account",
                component : ()=> import("@/components/manager/account/ManagerAccountEdit.vue")
            },
            {
                path: "/front/employee_schedule",
                component : ()=> import("@/components/common/EmployeeSchedule.vue"),
            },
            {
                path: "/front/counsulting",
                component : ()=> import("@/components/consulting_teacher/student/ConsultingStudentListLayout"),
            },
        ]
    }
]

export default routes;