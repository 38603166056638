import { computed, ref, watch } from "vue";
import { defineStore } from "pinia";

import { ApiReq } from "@/components/manager/ManagerUtils";
import EventManager from "@/services/eventmanager.service";
import SpartaWS from "@/services/ws.sparta.service";

const important = {
    key: ["msg/important_call", "msg/important_call_confirmed"],
    src: ref([]),
    fn: (onMsg = true) => {
        new ApiReq("/calls/important/unconfirmed").setResultCallback(data => {
            important.src.value = data
        }).get();
        if (onMsg) {
            EventManager.EmitTo("receive_important_call");
        }
    },
}
const msgMap = new Map([
    ["important", important]
]);

export const useMessageStore = defineStore('message', () => {
    const onMessage = (v) => {
        msgMap.forEach((item, key) => {
            if (item.key.some(e => e == v.command)) item.fn();
        })
    }
    const init = ()=>{
        SpartaWS.socket.add_message_handler("msg/", onMessage);
        msgMap.forEach((item, key) => item.fn(false))
    }
    const deInit = ()=>{
        SpartaWS.socket.remove_message_handler("msg/", onMessage);
    }

    const message = computed(() => {
        return [...msgMap.keys()].reduce((acc, cur) => {
            acc[cur] = msgMap.get(cur).src.value
            return acc;
        }, {})
    });

    watch(message, (v)=>{
        // 
    })
    return { init, deInit,  message, }
});