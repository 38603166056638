const reESC = /[\\^$.*+?()[\]{}|]/g, reChar = /[가-힣]/, reConsonant = /[ㄱ-ㅎ]/, offset = 44032;
const con2syl = Object.fromEntries('ㄱ:가,ㄲ:까,ㄴ:나,ㄷ:다,ㄸ:따,ㄹ:라,ㅁ:마,ㅂ:바,ㅃ:빠,ㅅ:사'.split(",").map(v => {
    const entry = v.split(":");
    entry[1] = entry[1].charCodeAt(0);
    return entry;
}));
const str_get_pattern = ch => {
    let r;
    if (reConsonant.test(ch)) {
        const begin = con2syl[ch] || ((ch.charCodeAt(0) - 12613) * 588 + con2syl['ㅅ']);
        const end = begin + 587;
        r = `[${ch}\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    } else if (reChar.test(ch)) {
        const chCode = ch.charCodeAt(0) - offset;
        if (chCode % 28 > 0) return `(${ch})`;
        const begin = Math.floor(chCode / 28) * 28 + offset;
        const end = begin + 27;
        r = `[\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    } else r = ch.replace(reESC, '\\$&');
    return `(${r})`;
};
const str_matcher = (v, matches, sTag, eTag, tagLen) => {
    let distance = Number.MAX_VALUE, first = -1, last = 0, vLast = 0, vPrev = 0, acc = v;
    for (let i = 1, j = matches.length; i < j; i++) {
        const curr = matches[i];
        vLast = v.indexOf(curr, vLast);
        if (first == -1) first = vLast;
        if (vLast && distance > vLast - vPrev) distance = vLast - vPrev;
        vPrev = vLast;
        last = acc.indexOf(curr, last);
        acc = `${acc.substring(0, last)}${sTag}${curr}${eTag}${acc.substr(last + 1)}`;
        last += tagLen;
    }
    return [acc, distance, v.length, first];
};
const get_consonant_reg = (search) => {
    return new RegExp(search.split('').map(str_get_pattern).join('.*?'), "i");
}

const export_methods = {
    t_filter(e) {
        e.target.value = e.target.value.replace(/[^A-Z|0-9|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/gi, "");
        return e.target.value;
    },


    /**
     * 
     * @param {String} search search keyword
     * @param {String} target find keyword
     * @returns {Boolean} Whether the 'target' contains 'search'
     */
    getFindStr(search, target) {
        if (!search || !target) return true;
        else return get_consonant_reg(search).test(target);
    },


    /**
     * Returns a string or array containing the tags to highlight in the searched text(consonant searchable).
     * 
     * 검색된 텍스트(자음 검색 가능)에서 강조 표시할 태그가 포함된 문자열 또는 배열을 반환합니다.
     * 
     * example - getFindStrHighLight("ㅎㄷ", "한국멋지다", "\<b style='color:red'\>", "\</b\>");
     * 
     * example return - \<b style='color:red'\>한\</b\>국멋지\<b style='color:red'\>다\</b\>
     * 
     * @param {String} search search keyword
     * @param {String | Array} target find keyword (string or array)
     * @param {String} sTag add start tag
     * @param {String} eTag add end tag 
     * @returns {String | Array} target (type string or array)
     */
    getFindStrHighLight(search, target, sTag = "", eTag = "") {
        if (!search || !target) return target;
        const reg = get_consonant_reg(search);
        const tagLen = sTag.length + eTag.length;

        if (target instanceof Array) {
            const sorter = ([, dA, lA, fA], [, dB, lB, fB]) => {
                if (dA > dB) return 1;
                else if (dA < dB) return -1;
                else {
                    if (fA > fB) return 1;
                    else if (fA < fB) return -1;
                    else {
                        if (lA > lB) return 1;
                        else if (lA < lB) return -1;
                        else return 0;
                    }
                }
            };
            return target.reduce((acc, curr) => {
                const matches = reg.exec(curr);
                if (matches) acc.push(str_matcher(curr, matches, sTag, eTag, tagLen));
                return acc;
            }, []).sort(sorter).map(([e]) => e);
        } else {
            const matches = reg.exec(target);
            if (matches) return str_matcher(target, matches, sTag, eTag, tagLen)[0];
            else return target;
        }
    },
}


export default {
    install: (vue) => {
        vue.config.globalProperties.$str_util = {
            text_filter: (event) => export_methods.t_filter(event),
            find_str: (search, target) => export_methods.getFindStr(search, target),
            find_str_highlight: (search, target, sTag = "", eTag = "") => export_methods.getFindStrHighLight(search, target, sTag, eTag),
        };
    }
}


