
<template>
    <div id="app_cancelPop" @keydown.esc="close()">
        <div class="app_cancel_in">
            <div class="titlemsg">
                <h1>
                    학교 추가
                </h1>
            </div>
            <div class="school_name">
                <p> 학교 이름 : </p>
                <input type="text" v-model="name" />
            </div>
            <div class="buttons">
                <button @click="close()" class="cancel"> 취 소 </button>
                <button @click="ok()" class="ok"> 추 가 </button>
            </div>
        </div>
        <div id='keyeventreceiver'>
            <input id="keyeventreceiver" ref="keyeventreceiver" type="text" @keydown.esc="close()"/>
        </div>
    </div>
</template>


<script>
import ApiService from "@/services/api.service"
/*

학교 추가 : [POST] /school
Authorization : Bearer {token}
Content-Type : application/json
Request : 
{
    name : string,
    address : string,
    extra : string
}

*/

export default {
    props : {
        attr : Object,
    },
    data() {
        return {
            name : "",
            address : "", // 추가 안됨.
            no : "",
        }
    },
    methods : {
        submit_request_school_add() {
            if(!this.name) {
                this.$ModalManager.showdialog(
                    {
                        title : "이름이 빈칸 입니다. 확인해주세요.",
                        buttons : {
                            "yes" : "",
                        }
                    }
                )
                return;
            }
            if(this.attr.school_list.some(item => item.description === this.name)) {
                this.$ModalManager.showdialog(
                    {
                        title : "동일한 이름의 학교가 있습니다. 확인해주세요.",
                        buttons : {
                            "yes" : "",
                        }
                    }
                )
                return;
            }
            const request_data = {
                name : this.name,
            }
            ApiService.call(ApiService.POST, "/school", request_data).then(res => {
                if(res && res.data && Number(res.data.result)){
                    this.no = res.data.data
                    this.$ModalManager.close(this, {name : this.name, no : this.no})
                }
                else { 
                    this.response_fail()
                }
            }).catch(err => {
                this.response_fail()
            })
        },
        ok() {
            this.submit_request_school_add()
        },
        close() {
            this.$ModalManager.close(this)
        },
        response_fail() {
            this.close()
            this.$ModalManager.showdialog(
                {
                    title : "학교 추가에 실패하였습니다",
                    buttons : {
                        "yes" : "",
                    }
                }
            )
        }
    }
}
</script>

<style scoped>

#app_cancelPop {
    width: 500px;
    height: fit-content;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 106;
    background-color: #fff;
    border-radius: 15px;
    padding: 55px 68px 30px 68px;
    box-sizing: border-box;
    color: #6c6c6c;
    text-align: center;
}
.app_cancel_in p {
    margin-right: 15px;
}
.school_name {
    justify-content: center;
}
input[type="text"] {
    width: 250px;
    text-align: left;
    line-height: 26px;
    border-radius: 50px;
    border: 1px solid #9c9c9c;
    box-sizing: border-box;
    outline: none;
    -webkit-box-sizing: border-box;
    height: 26px;
    text-align: center;
    font-size: 16px;
    color: #6c6c6c;
    line-height: 26px;
}

h1 {
    font-size: 18px;
    font-weight: bold;
    color: #1E3560;
    margin-bottom: 11px;
}
div.titlemsg > p {
    font-weight : normal;
    font-size: 16px;
    margin-bottom: 30px;
}

.app_cancel_in > div {
    width: 364px;
    height: 40px;
    margin: 0 auto;
    display: flex;
}

.app_cancel_in > div.titlemsg {
    display: inline;
}

.buttons {
    
    justify-content: space-between;
}

button {
    width: 170px;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    border: none;
}

button.no, button.cancel {
    background-color: #9c9c9c;
}
button.yes, button.ok {
    background-color: #1E3560;
}

div#keyeventreceiver {
    position: absolute;
    top : 5000px;
}

input#keyeventreceiver {
    /* visibility: hidden; */
}
</style>