//import FrontUtils from "@/components/front/FrontJs/FrontUtils.js";
import ApiService from "@/services/api.service";
import CommonData from "@/components/common/data/CommonData"
const FrontUtils = {
    util_PostData: function (target_url, request_data) {
        // console.log(target_url);
        // console.log(request_data);
        return new Promise((resolve, reject) => {
            ApiService.call(ApiService.POST, target_url, request_data)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    },
    util_DeleteData: function (target_url, request_data) {
        let call_method;
        if (request_data) {
            call_method = ApiService.call(ApiService.DELETE, target_url, { data: request_data });
        } else {
            call_method = ApiService.call(ApiService.DELETE, target_url);
        }
        return new Promise((resolve, reject) => {
            call_method
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    util_PutData: function (target_url, request_data) {
        return new Promise((resolve, reject) => {
            // console.log(target_url);
            // console.log(request_data);
            ApiService.call(ApiService.PUT, target_url, request_data)
                .then((res) => {
                    // console.log(res);
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    util_GetData: function (target_url, param) {
        let call_method;
        if (param) {
            call_method = ApiService.call(ApiService.GET, target_url, param);
        } else {
            call_method = ApiService.call(ApiService.GET, target_url);
        }
        return new Promise((resolve, reject) => {
            call_method
                .then((res) => {
                    if (res.data != undefined)
                        resolve(res.data);
                    else
                        resolve(null);
                    // console.log(res);
                    // if (FrontUtils.util_IsDataCheck(res.data)) {
                    //     resolve(res.data);
                    // } else {
                    //     reject(res);
                    // }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    // util_QueryMake: function (param) {
    //     let result_str = "";
    //     if (param.query) {
    //         for (const e of param.query) {
    //             result_str += "/" + e;
    //         }
    //     }

    //     if (param.query_param) {
    //         result_str += "?";
    //         for (const key in param.query_param) {
    //             result_str += key + "=" + param.query_param[key] + "&";
    //         }
    //     }

    //     return result_str;
    // },

    util_ReturnSchoolName(target) {
        const result = String(target).substring(0, String(target).search(/[등|학]/g));
        if (result) {
            return result;
        }
        else {
            return target;
        }

    },
    util_ReturnStudentMajor(target_major) {
        return CommonData.major_type?.[target_major] ?? ""
    },
    util_ReturnBusUse(target_shuttle) {
        return CommonData.use_shuttle_bus?.[target_shuttle] ?? "";
    },
    util_ReturnPriority(target) {
        return CommonData.student_status_type?.[target] ?? "";
    },

    util_ReturnPhoneNumber(phone) {
        if (phone === "--" || !phone) return ""
        else return phone;
    },
    util_ReturnPlanerScore: function (target) {
        return CommonData.planer_finish_check_type?.[target] ?? "";
    },

    util_PlanerAverrage: function (param) {
        let sum_data = 0;
        param.forEach(e => sum_data += Number(e) / 3);
        const result_avg = (sum_data / param.length) * 10;
        if (result_avg >= 8) return "상"
        else if (result_avg >= 4) return "중"
        else return "하"
    },

    util_ReturnAdmissionType: function (target) {
        let r = []
        Object.entries(CommonData.admission_type).forEach(item => {
            if (target == item[0]) {
                r.push(item[1])
            }
        })
        return r.join(" / ");
    },

    util_ReturnStatusStudent(target_status) {
        var result = "";
        Object.entries(CommonData.student_type).forEach(list_item => {
            if (list_item[0] == target_status)
                result = list_item[1] + " 학생";
        });
        return result;
    },


    util_ArrayMaxCount(arr_a, arr_b) {
        const count = arr_a?.length > arr_b?.length ? arr_a?.length : arr_a?.length <= arr_b?.length ? arr_b?.length : 0
        return count;
    },

    util_Today() {
        return this.util_ReturnStringDate(new Date(), "-");
    },
    /**
     * 
     * @param {String} target_day  // input date
     * @param {String} target_os // / , /2(년도(두자리)), - , year(년,월,일), mon(월,일), noday(년,월), hour(년,월,시,분)
     * @returns String
     */
    util_ReturnStringDate(target_day, target_os) {
        // console.log(target_day);
        var temp_date = this.util_ReturnValidDateString(target_day);
        // console.log(temp_date);
        temp_date = new Date(temp_date);
        // console.log(temp_date);
        if (temp_date === "Invalid Date" || isNaN(temp_date)) return '';
        var yyyy = temp_date.getFullYear();
        var yy = yyyy % 100;
        var mm = Number(temp_date.getMonth()) + 1;
        var dd = temp_date.getDate();
        var hour = temp_date.getHours();
        var minu = temp_date.getMinutes();
        var sec = temp_date.getSeconds();
        mm = String(mm).length === 1 ? "0" + mm : mm;
        dd = String(dd).length === 1 ? "0" + dd : dd;
        hour = String(hour).length === 1 ? "0" + hour : hour;
        minu = String(minu).length === 1 ? "0" + minu : minu;
        sec = String(sec).length === 1 ? "0" + sec : sec;

        if (target_os === "year") {
            return yyyy + "년 " + mm + "월 " + dd + "일";
        }
        else if (target_os === "mon") {
            return mm + "월 " + dd + "일";
        }
        else if (target_os === "noday") {
            return yyyy + "년 " + mm + "월 ";
        }
        else if (target_os === "hour") {
            return mm + "/" + dd + "/" + hour + ":" + minu;
        }
        else if (target_os === "/2") {
            return yy + "/" + mm + "/" + dd
        }
        else if (target_os === "full") {
            return yyyy + "-" + mm + "-" + dd + " " + hour + ":" + minu + ":" + sec;
        }
        else {
            return yyyy + target_os + mm + target_os + dd;
        }
    },

    /**
     * 
     * @param {String} target_day      //input data
     * @param {String} target_os            // y(year) , m(month) , d(day)
     * @returns 
     */
    util_ReturnYMD(target_day, target_os) {
        var temp_date = this.util_ReturnValidDateString(target_day);
        temp_date = new Date(temp_date);
        if (temp_date === "Invalid Date" || isNaN(temp_date)) return '';
        if (target_os === "y") {
            return String(temp_date.getFullYear());
        } else if (target_os === "m") {
            return String(Number(temp_date.getMonth()) + 1);
        } else if (target_os === "d") {
            return String(temp_date.getDate());
        }
    },


    /**
     * 
     * @param {String} s_target 기준 대상
     * @param {String} c_target 비교 대상
     * @returns bool
     */
    util_ReturnCompareDate(s_target, c_target) {
        if (new Date(s_target) >= new Date(c_target)) {
            return true;
        } else {
            return false;
        }
    },

    /**
     * 
     * @param {String} target_day input date
     * @returns String '2022/04/14 ...'
     */
    util_ReturnValidDateString(target_day) {
        // console.log(target_day);
        var temp_date = String(target_day).replace(/(\.|-)/g, "/");
        return temp_date
    },


    /**
     * object,array,date data check
     * @param {*} target 
     * @returns bool 1 0
     */
    util_IsDataCheck(target) {
        var result = false;
        if (target instanceof Object) { //null, undefined check result : false
            if (target instanceof Array) {    //array
                result = target.length;         //없으면 0 (false)
            } else if (target instanceof Date) { //Date
                result = (new Date(target) !== "Invalid Date") && !isNaN(new Date(target)); // 없으면 false
            } else {   //object
                result = Object.keys(target).length;    // 없으면 0 (false)
            }
        } else if (typeof (target) === 'string') {
            //날짜 한번 더.
            result = (new Date(target) !== "Invalid Date") && !isNaN(new Date(target)); // 없으면 false
        }

        return result;
    },


    util_ReturnElapsedDays(target_day) {
        let result = null;
        let target_date = new Date(this.util_ReturnStringDate(target_day, "/"));
        let today = new Date(this.util_ReturnStringDate(new Date(), "/"));
        if (this.util_IsDataCheck(target_date)) {
            result = Math.ceil(Math.abs(today.getTime() - target_date.getTime()) / (1000 * 60 * 60 * 24));

        }
        return result;
    },




    deepCopyObjArr: function (target) {
        var result;
        if (typeof target === "object" && target !== null) {
            if (target instanceof Array) {
                result = [];
                target.forEach(e => {
                    if (typeof e === "object" && e !== null) {
                        result.push(this.deepCopyObjArr(e));
                    } else {
                        result.push(e);
                    }
                })
            } else {
                result = {};
                for (var i in target) result[i] = this.deepCopyObjArr(target[i]);
            }

        }
        else result = target;
        return result;
    },


    util_ReturnSearchDate: function (src_item, dst_items) {
        var temp_split;
        const ReturnTimeString = function (time_s) {
            temp_split = time_s.split(':');
            return temp_split[0] + ":" + temp_split[1];
        };

        switch (Number(src_item.type_no)) {
            case 1:
                {
                    dst_items[src_item.day].description[0].in = ReturnTimeString(src_item.time1);
                }
                break;
            case 2:
                {
                    dst_items[src_item.day].description[0].out = ReturnTimeString(src_item.time1);
                }
                break;
            case 3:
                {
                    dst_items[src_item.day].description[0].reason = src_item.reason;
                    dst_items[src_item.day].description[0].reason_time_in = ReturnTimeString(src_item.time1);
                    dst_items[src_item.day].description[0].reason_time_out = ReturnTimeString(src_item.time2);
                }
                break;
            case 4:
                {
                    dst_items[src_item.day].description[1].in = ReturnTimeString(src_item.time1);
                }
                break;
            case 5:
                {
                    dst_items[src_item.day].description[1].out = ReturnTimeString(src_item.time1);
                }
                break;
            case 6:
                {
                    dst_items[src_item.day].description[1].reason = src_item.reason;
                    dst_items[src_item.day].description[1].reason_time_in = ReturnTimeString(src_item.time1);
                    dst_items[src_item.day].description[1].reason_time_out = ReturnTimeString(src_item.time2);
                }
                break;
            case 7:
                {
                    dst_items[src_item.day].description[2].in = ReturnTimeString(src_item.time1);
                }
                break;
            case 8:
                {
                    dst_items[src_item.day].description[2].out = ReturnTimeString(src_item.time1);
                }
                break;
            case 9:
                {
                    dst_items[src_item.day].description[2].reason = src_item.reason;
                    dst_items[src_item.day].description[2].reason_time_in = ReturnTimeString(src_item.time1);
                    dst_items[src_item.day].description[2].reason_time_out = ReturnTimeString(src_item.time2);
                }
                break;
            case 10:
                {
                    dst_items[src_item.day].description[3].in = ReturnTimeString(src_item.time1);
                }
                break;
            case 11:
                {
                    dst_items[src_item.day].description[3].out = ReturnTimeString(src_item.time1);
                }
                break;
            default:
                break;
        }
    },


}

export default FrontUtils;