<template>
    <div id="t_consultingPop">
        <div class="t_consulting_in">
            <h3>
                선생님 상담 가능 시간표
            </h3>
            <table>
                <thead>
                    <tr>
                        <th>
                            &nbsp;
                        </th>
                        <th>월</th>
                        <th>화</th>
                        <th>수</th>
                        <th>목</th>
                        <th>금</th>
                        <th>토</th>
                        <th>일</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(subject_data, subject_idx) in attr.table_data" :key="subject_idx">
                        <td>
                            {{subject_data.description}}
                        </td>
                        <td v-for="(week_data, week_idx) in subject_data.data" :key="week_idx">
                            <div v-for="(teacher, teacher_idx) in week_data.data" :key="teacher_idx">
                                <p>
                                    {{teacher.teacher_name}} 선생님
                                </p>
                                <p>
                                    {{format_date_period(teacher)}}
                                </p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <input type="button" value="확 인" class="cancel" @click="close()">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        attr : Object,
    },
    methods : {
        close () {
            this.$ModalManager.close(this)
        },
        format_date_period(data) {
            const start_time = data.time1.split(":")
            const end_time = data.time2.split(":")
            if(start_time.length < 2 || end_time.length < 2) {
                return ""
            }
            return start_time[0] + ":" + start_time[1] + "~" + end_time[0] + ":" + end_time[1]
        }
    },
}
</script>


<style scoped>
#t_consultingPop {
    width: 915px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 106;
    background-color: #fff;
    border-radius: 15px;
    padding: 30px 0 30px 0;
    box-sizing: border-box;
    color: #6c6c6c;
}
.t_consulting_in {
    width: 855px;
    margin: 0 auto;
}
.t_consulting_in h3{
    font-size: 24px;
    font-weight: bold;
    color: #1E3560;
    margin-bottom: 20px;
}
.t_consulting_in table {
    width: 855px;
    border-collapse: collapse;
    border-spacing: none;
}
.t_consulting_in table thead {
    background-color: #f2f2f2;
}
.t_consulting_in table thead tr th {
    height: 39px;
    line-height: 39px;
    border-left: 1px solid #9c9c9c;
    color: #1E3560;
    font-weight: bold;
    border-bottom: 1px solid #9c9c9c;
    border-top: 1px solid #9c9c9c;
    box-sizing: border-box;
}
.t_consulting_in table thead tr th:first-child {
    border-left: none;
}
.t_consulting_in table tbody tr {
    border-bottom: 1px solid #9c9c9c;
    box-sizing: border-box;
}
.t_consulting_in table tbody tr td {
    width: 113px;
    height: 113px;
    text-align: center;
    border-left: 1px solid #9c9c9c;
    box-sizing: border-box;
}
.t_consulting_in table tbody tr td:nth-child(1) {
    width: 50px;
    border-left: none;
    color: #1E3560;
    font-weight: bold;
    background-color: #f2f2f2;
}
.t_consulting_in table tbody tr td > div {
    margin-top: 5px;
}
.t_consulting_in table tbody tr td > div p {
    line-height: 22px;
}
.t_consulting_in table tbody tr td > div p:nth-child(1) {
    font-weight: bold;
}
.t_consulting_in > div {
    width: 100%;
    text-align: center;
    margin-top: 30px;
}
.t_consulting_in > div input {
    width: 170px;
    height: 40px;
    border: none;
    border-radius: 20px;
    color: #fff;
    font-size: 16px; 
    cursor: pointer;
    background-color: #1E3560;
}
</style>