export default {
    myinfo : {
        function : "GET",
        url : "/my/info",
        /* 다음과 같이 myinfo에 의존하는 데이터는 dependence 형태로 불러오기 가능.(example) */

        // dependence : {
        //     class_data : {
        //         function : "GET",
        //         url : "/center/{0}/classrooms",
        //         variable : { 
        //             myinfo : "center_no",
        //         },
        //     },
        // },

    },
    mytoday : {
        function : "GET",
        url : "/my/today",
    }
}