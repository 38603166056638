<template>
    <div id="app_cancelPop" >
        <div class="app_cancel_in">
            <div class="titlemsg">
                <h1 >
                    약관
                </h1>
                <p>
                    약관 내용
                </p>
            </div>
            <div class="buttons">
                <button @click="close()" class="no">
                    취소
                </button>
                <button @click="ok()" class="yes">
                    동의
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        attr : Object,
    },

    create() {

    },

    data() {
        return {
        }
    },
    methods : {
        close() {
            this.$ModalManager.close(this, {result : "no"})
        },
        ok() {
            this.$ModalManager.close(this, {result : "yes"})
        }
    }
}
</script>

<style scoped>

#app_cancelPop {
    width: 500px;
    height: fit-content;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 106;
    background-color: #fff;
    border-radius: 15px;
    padding: 55px 68px 30px 68px;
    box-sizing: border-box;
    color: #6c6c6c;
    text-align: center;
}
.app_cancel_in p {
    font-weight: bold;
    color: #1E3560;
    margin-bottom: 55px;
    font-size: 18px;
}

h1 {
    font-size: 18px;
    font-weight: bold;
    color: #1E3560;
    margin-bottom: 11px;
}
div.titlemsg > p {
    font-weight : normal;
    font-size: 16px;
    margin-bottom: 30px;
}

.app_cancel_in > div {
    width: 364px;
    height: 40px;
    margin: 0 auto;
    display: flex;
}

.app_cancel_in > div.titlemsg {
    display: inline;
}
.buttons {
    justify-content: space-between;
}
button {
    width: 170px;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    border: none;
}

button.no, button.cancel {
    background-color: #9c9c9c;
}
button.yes, button.ok {
    background-color: #1E3560;
}

div#keyeventreceiver {
    position: absolute;
    top : 5000px;
}

</style>