<template>
  <div>
    Base
  </div>
</template>

<script>
import { Utils } from './ManagerUtils';
export default {
    created() {
    },
    props : {
        attr : Object
    },
    emits : [
      "resultOk"
    ],
    methods: {
        onClose() {
            this.$ModalManager.close(this, false);
        },
        onResultOk() {
          this.$emit("resultOk");
          this.$ModalManager.close(this, true);
        }
    }
}
</script>
