<template>
    <div id="main">
        <div class="header">
            <h1>긴급알림</h1>
        </div>
        <div class="tap01_main">
                <div>
                    <div class="list_item">상태</div>
                    <div class="list_item">작성자</div>
                    <div class="list_item content">내용</div>
                    <div class="list_item date">시간</div>
                    <div class="list_item" style="margin-right: 15px">확인자</div>
                </div>
                <div class="content_main webkitscroll">
                    <div class="empty" v-if="!items?.length">
                        <p>알림이 없습니다.</p>
                    </div>
                    <div v-else>
                        <ul>
                            <li v-for="(item) in items" :key="item">                            
                                <div class="list_item">
                                    <img v-if="isConfirmed(item)" id="bell" src="@/assets/images/bell.png">
                                    <img v-else id="bell_red" src="@/assets/images/bell_red.png">
                                </div>
                                <div class="list_item">{{ item.requester_name ?? "-" }}</div>
                                <div class="list_item cursor content" @click="onClickContent(item)">{{ item.content }}</div>
                                <div class="list_item date">{{ toDateTime26(item.created_at) }}</div>
                                <div class="list_item" >
                                    <div v-if="item.confirmed_by_name">
                                        {{item.confirmed_by_name}}
                                    </div>
                                    <await-button v-else class="input_confirm" @click="onClickItem(item)">확인</await-button>
                                    <!-- <input v-else class="input_confirm" type="button" value="확인" @click.prevent="onClickItem(item)"> -->
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
                
            </div>
            <await-button class="input_close" @click="onClose">닫기</await-button>
            
    </div>
</template>

<script>
import jwtService from '@/services/jwt.service'
import ManagerBasePopupVue from '../manager/ManagerBasePopup.vue'
import { ApiReq, Utils } from '../manager/ManagerUtils'

export default {
    mixins : [ManagerBasePopupVue],
    created() {
        this.id = jwtService.getUserInfo()?.userid;

        if(!this.id) {
            Utils.showTextModal("사용자 정보를 불러오는데 실패했습니다.");
            return;
        }

        this.init();        
    },
    mounted() {
        this.$EventManager.AddListener("receive_important_call", this, this.init);
    },
    unmounted() {
        this.$EventManager.RemoveListener("receive_important_call");
    },
    data() {
        return {
            id : null,
            src : null,
        }
    },
    computed : {
        items() {
            if(!this.src)
                return [];
            
            let ret = Array.from(this.src).sort((a, b) => {

                const date1 = new Date(a);
                const date2 = new Date(b);

                const isConfirmed1 = this.isConfirmed(a);
                const isConfirmed2 = this.isConfirmed(b);

                if(isConfirmed1 ^ isConfirmed2) {
                    return isConfirmed1 - isConfirmed2;
                } else {
                    return date2.getTime() - date1.getTime();
                }
            });

            return ret;
        }
    },
    methods : {
        async init() {
            const param = {
                to:this.id,
                date : Utils.dateObjectToDate26(new Date())
            };

            await new ApiReq("/calls/important", param)
                .setResultCallback ( (data, headers) => {
                    this.src = data;
                })
                .setFailMsg("긴급알림 목록을 가져오는데 실패했습니다.")
                .get();
        },
        isConfirmed(item) {
            return !item.confirmed_by_id == false;
        },
        toDateTime26(dateStr) {
            return Utils.dateObjectToDateTime26(new Date(dateStr)) ?? "-";
        },
        async onClickItem(item) {
            await new ApiReq("/call/important/" + item.no + "/confirm")
                .setFailMsg("긴급알림 확인 처리에 실패했습니다.")
                .put();

            await this.init();
        },
        onClickContent(item) {
            this.$ModalManager.show("big_size_text", {
                title : "내용",
                msg : item.content,
                buttons : {
                    "ok" : "",
                },
            });
        }
    }
}
</script>

<style lang="scss" scoped>

#main {
  width: 1000px;
  height:600px;
  background-color: #fff;
  border-radius: 15px;
  color: #6c6c6c;
  padding:20px;
  user-select: none;
}

.tap01_main {
        height: 500px;
        .list_item {
            flex: 1 1 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 5px;
        }
        .content {
            flex-grow: 5;
        }
        .date {
            flex-grow: 2;
        }

        .content_main {
            overflow-y: scroll;
            height: 420px;
        }

        > div:nth-child(1) {
            display: flex;
            height: 40px;
            align-items: center;
            border-top: 1px solid #9c9c9c;
            border-bottom: 1px solid #9c9c9c;
            color: #1e3560;
            font-weight: bold;
            background-color: #f2f2f2;
            div {
                color: #1e3560;
                font-weight: bold;
            }
            .row03 {
                text-align: center;
            }
        }
        > div {
            ul {
                li {
                    display: flex;
                    height: 40px;
                    border-bottom: 1px solid #9c9c9c;
                    align-items: center;

                    .cursor {
                        cursor: pointer;
                    }
                    .content {
                        text-align: left;
                    }
                }
            }
        }
    }
    .tap01_main::-webkit-scrollbar {
        width: 8px;
    }
    .tap01_main::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
    }
    .tap01_main::-webkit-scrollbar-track {
        background-color: #f2f2f2;
    }

.header {
    margin-bottom: 20px;
    h1 {
        font-size: 24px;
        font-weight: bold;
        color: #1E3560;
        margin-right: 40px;
        text-align: left;
    }
}

.input_confirm {
    width: 60px;
    height: 25px;
    text-align: center;
    border: none;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    background-color: #1e3560;
}

.input_close {
    width: 170px;
    height: 40px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    border: none;
    font-size: 17px;
    cursor: pointer;
    background-color: #1e3560;
}

.empty {
    display: flex;
    height: inherit;
    align-items: center;

    > p {
        width: 100%;
    }
}

</style>