import sparta_socket from "@/services/ws.class.service";

const SpartaWS = {
    socket : undefined,
    install : (vue, options) => {
        
        SpartaWS.socket = new sparta_socket();

        vue.config.globalProperties.$WSSocket = {
            Connect : (account_info) =>                 SpartaWS.socket.connect(account_info),
            Disconnect : () =>                          SpartaWS.socket.disconnect(),
            Send : (command, params, target) =>         SpartaWS.socket.send(command, params, target),
            Broadcast : (command, params) =>            SpartaWS.socket.broadcase(command, params),
            AddMessageHandler : (command, handler) =>   SpartaWS.socket.add_message_handler(command, handler),
            RemoveMessageHandler : (command, handler) =>         SpartaWS.socket.remove_message_handler(command, handler),
        };
    },
}

export default SpartaWS;