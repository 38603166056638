<template>
    <div class="search_main">
        <div class="icon">
            <img src="@/assets/images/search@2x.png" alt="돋보기" />
        </div>
        <div>
            <input
                type="text"
                :value="this.search_keyword"
                @input="(e)=>this.search_keyword = this.$str_util.text_filter(e)"
                @keyup.enter="this.enter_event()"
                :placeholder="this.placeholder_text ?? '검색어를 입력해주세요.'"
                :disabled="this.disabled"
            />
        </div>
        <div>
            <button @click="this.search_keyword = ''"><img src="@/assets/images/delete.png" /></button>
        </div>
    </div>
</template>

<script>
//<search-bar :placeholder_text="'학생 이름 검색'" @search="(s) => (this.search_keyword = s)" />

export default {
    name: "SearchBar",
    props: {
        placeholder_text: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        set_keyword: String,
    },
    emits: ["search", "enter"],
    data() {
        return {
            search_keyword: "",
        };
    },
    methods: {
        enter_event() {
            this.$emit("enter", this.search_keyword);
        },
    },
    watch: {
        search_keyword(value) {
            this.$emit("search", value);
        },
        set_keyword(value) {
            if (value != this.search_keyword) this.search_keyword = value;
        },
    },
    computed: {},
};
</script>

<style lang="scss" scoped>
.search_main {
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 30px;
    border: 1px solid var(--color-sparta);
    border-radius: 100px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0;
    > div {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-of-type(1),
        &:nth-of-type(3) {
            max-width: 30px;
            min-width: 30px;
        }
        &:nth-of-type(2) {
            margin: 0 3%;
        }
    }
}
.icon {
    background: var(--color-sparta);
    border-radius: 100px;
    margin-left: -1px;
    img {
        width: 16px;
        height: 16px;
        margin-right: 1px;
        filter: brightness(2);
    }
}
input {
    background: transparent;
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    font-size: 16px;
    cursor: text;
    &::placeholder {
        font-size: 14px;
    }
    &:disabled {
        cursor: not-allowed;
    }
}

button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    img {
        width: 17px;
        height: 17px;
    }
}
</style>