<template>
    <div class="main">
        <div class="header_alarmbar_red" :class="getClass" v-if="newAlert" @click.stop="onClickAlarm"/>
        <TopHeader show_alarmbar id="top_header" />
        <!-- <MenuBar /> -->
        <MenuBar id="menu_bar" v-model:menu_items="menu_items" @menu_item_clicked="menu_item_clicked" />
        <section id="layout_content">
            <div id="layout_left">
                <WidgetNotice />
                <CheckList :checkListData="checkListData" @clickItem="onClickCheckList" />
            </div>
            <div id="manage_main">
                <router-view />
                <Footer />
            </div>
        </section>
    </div>
</template>

<script>
import TopHeader from "@/components/common/TopHeader.vue";
import MenuBar from "@/components/common/MenuBar.vue";
import WidgetNotice from "@/components/common/WidgetNotice.vue";
import CheckList, { CheckListMixin } from "@/components/manager/Common/ManagerCheckList.vue";
import Footer from "@/components/manager/Common/ManagerFooter.vue";
import { ApiReq, Utils } from "@/components/manager/ManagerUtils";
import ManagerReportResult from "@/components/manager/main/ManagerReportResult.vue";
import AlarmHistoryModalVue from '@/components/common/AlarmHistoryModal.vue';
import { useMessageStore } from '@/store/useMessageStore';
export default {
    components: {
        TopHeader,
        MenuBar,
        WidgetNotice,
        CheckList,
        Footer
    },
    mixins: [CheckListMixin],
    setup(){
        const store = useMessageStore();
        return {
            store
        };
    },
    data() {
        return {
            menu_items: [
                {
                    title: "메인화면",
                    id: "main",
                    url: "/manager/main"
                },
                {
                    title: "학생 기록",
                    id: "record",
                    url: "/manager/record"
                },
                {
                    title: "관찰/플래너",
                    id: "planner",
                    url: "/manager/planner"
                },
                {
                    title: "상담현황",
                    id: "counsel",
                    url: "/manager/counsel"
                },
                {
                    title: "문자 발신 현황",
                    id: "/manager/sms",
                    url: "/manager/sms"
                },
                {
                    title: "학습자료실",
                    id: "archive",
                    // disable : true,
                    url: "/manager/archive"
                },
                {
                    title: "센터 회의 공지",
                    id: "center",
                    url: "/manager/center"
                },

                {
                    title: "학사일정",
                    id: "schedule",
                    url: "/manager/schedule"
                },
                {
                    id: "",
                    title: "근무표조회",
                    description: "",
                    url: "/manager/employee_schedule"
                }
            ],
            alarmClick:false
        };
    },
    created() {
        document.title = "Sparta - 관리";

        Utils.setModalManager(this.$ModalManager);

        this.addWSMap(
            "msg/observation",
            (data) => {
                return {
                    title: "관찰 항목",
                    msg: data.student_name + " - " + data.type_name
                };
            },
            null,
            this.onClickObservation
        );

        this.addWSMap(
            "msg/important_report",
            (data) => {
                return {
                    title: "학생 보고 알림",
                    msg: data.student_name + "- 학생 보고 대응 결과"
                };
            },
            null,//this.onReceiveReport,
            this.onClickReport
        ); //중요보고(학생보고) 목록 가져오기 : [GET] /reports/important
    },
    unmounted() {
        Utils.setModalManager(null);
        this.store.deInit();
    },
    mounted() {
        this.store.init();
    },
    methods: {
        menu_item_clicked(item) {},
        async onClickReport(data) {
            let ret = await this.$ModalManager.showEx(ManagerReportResult, {
                bind: {
                    important: data
                }
            });

            return ret == true;
        },
        async onReceiveReport(data) {
            let ret = await this.$ModalManager.showEx(ManagerReportResult, {
                bind: {
                    important: data
                }
            });

            Utils.log("ret = " + ret);

            return ret == true;
        },
        async onClickObservation(data) {
            let ret = await this.$ModalManager.showdialog({
                title: '"' + data.type_name + '" 관찰 항목을 해제 하시겠습니까?',
                buttons: {
                    yes: "해제",
                    no: "취소"
                }
            });

            if (ret?.result == "yes") {
                ret = await new ApiReq("/observation/cancel/" + data.no).setFailMsg("관찰 항목 해제에 실패했습니다.").delete();

                return ret == true;
            }
            return false;
        },
        async onClickAlarm(){
            if(this.alarmClick) return;
            this.alarmClick = true;
            await this.$ModalManager.showEx(AlarmHistoryModalVue);
            this.alarmClick = false;
        }
    },
    computed:{
        newAlert(){
            console.log(this.store.message);
            return (this.store.message.important?.length ?? 0) > 0;
        },  
        getClass(){
            return !this.alarmClick ? 'animation' : 'animation_stop';
        }
    }
};
</script>
<style scoped>
#top_header {
    /*
    position: sticky;
    transform: none;
    */
}

/* position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    margin: 0 auto;
    background-color: #F2F2F2;
    height: 100px;
    display: flex;
    align-items: center;
    z-index: 90; */

#menu_bar {
    /*
    position: sticky;
    transform: none;
    width: 100%;*/
}

/* nav {
    position: fixed;
    left: 50%;
    top: 100px;
    transform: translateX(-50%);
    width: 100% ;
    margin: 0 auto;
    height: 50px;
    background-color: #1E3560;
    display: flex;
    align-items: center;
    z-index: 90;
    font-size: 18px;
    font-weight: bold;
} */

#layout_content {
    padding-top: 150px;
    background-color: #f2f2f2;
    width: 1600px;
    margin: 0 auto;
    display: flex;
}

#manage_main {
    width: 1257px;
    /* margin-left: 343px; */
    padding-top: 30px;
    padding-left: 30px;
    position: relative;
    color: #6c6c6c;
}

#manage_main::before {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #d9d9d9;
    position: absolute;
    left: 0px;
}

#layout_left {
    width: 308px;
    /* position: fixed; */
    top: 150px;
    padding-top: 30px;
}
</style>

<style lang="scss" scoped>
.main{
    width:100%;
    height:100%;
    position:relative;
}
.header_alarmbar_red {
    width: 100%;
    height: 100%;
    background: rgba(203, 50, 8, 0.333);
    box-sizing: border-box;
    position:absolute;
    cursor:pointer;
    z-index:998;    // basemodal z-index 999
}

.animation{
    animation-name: newAlert;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
}
.animation_stop{
    opacity: 0.3;
}
@keyframes newAlert {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

</style>