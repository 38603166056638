<template>
    <div id="container">
        <main>
            <div class="student_login_wrap" @keypress.enter="request_login">
                <div class="student_login_logo">
                    <img
                        src="@/assets/images/sparta_logo_login.png"
                        alt="스파르타로그인화면로고"
                    />
                </div>
                <div class="student_login_input">
                    <div class="logininput">
                        <img
                            src="@/assets/images/barcode.png"
                            alt="바코드아이콘"
                        />
                        <input
                            type="text"
                            placeholder="코드를 입력하세요"
                            @focus="clear_output"
                            :value="userid"
                            @input="(e)=>userid = this.$str_util.text_filter(e)"
                        />
                    </div>
                    <div class="logininput">
                        <img
                            src="@/assets/images/pw.png"
                            alt="비밀번호 아이콘"
                        />
                        <input
                            type="password"
                            v-model="pw"
                            style="-webkit-text-security: circle"
                            placeholder="패스워드를 입력하세요"
                            @focus="clear_output"
                        />
                    </div>
                </div>
                <div class="student_login_submit" @click="request_login">
                    로그인
                </div>
                <div v-if="finger_status != 'connecting'" id="fingerprint_guide">
                    지문인식기가 설치되어 있습니다.<br>
                    지문인식기에 등록된 손가락을 올려주세요
                    <div id="fingerprint_output">
                        {{finger_message}}
                    </div>
                </div>
                <div v-text="error_msg" class="output_error"></div>
                <div v-if="view_temporal_login">
                    <p>Temporal login button</p>
                    <button @click="temp_login('ST22050001', '1234')">경영(센터7)</button>
                    <button @click="temp_login('MBP2303007')">시스템관리자</button>
                </div>
            </div>
        </main>
    </div>
    <!--container-->
</template>

<script>
import { CommonUtils } from "@/js/util/common";
import FingerPrint from '@/js/fingerprint';

const error_msg_loginfailed = "코드 또는 비밀번호가 잘못되었습니다.";

//websocket for fingerprint test

export default {
    name: "PageLogin",
    components: {},
    data() {
        return {
            userid: "",
            pw: "",
            error_msg: "",
            finger_status : "connecting",
            finger_message : "지문 인식 대기중",
            finger_message_disapear_timeout : 5,
            view_temporal_login : false,
            is_login : false,
        };
    },
    created() {
        document.title = "Sparta";
        this.view_temporal_login = process.env.VUE_APP_ISTEST;
    },
    mounted() {
        
        this.$store.dispatch("logout");
        this.$WSSocket.Disconnect();
        FingerPrint.msg_handler = this.finger_msg_handler;
        FingerPrint.fingerlogin_handler = this.finger_login_handler;
        FingerPrint.onopen_handler = function()
        {
            FingerPrint.StartService();
        };


        const _this = this;
        FingerPrint.onerror_handler = function()
        {
            _this.finger_status = "connecting";
        }

        FingerPrint.Connect();
    },
    unmounted() {
        FingerPrint.StopService();
        FingerPrint.Close();
    },

    computed: {},

    methods: {
        async finger_login_handler(data)
        {
            const _this = this;
            console.log(data);
            if(data.command == "req_login")
            {
                if(data.result == "error")
                {
                    this.finger_message = "지문인식 실패";
                    setTimeout(function(){_this.finger_message = "지문 인식 대기중";}, 1 * 1000);
                } else {
                    if(!this.is_login) {
                        this.is_login = true;
                        await this.$store.dispatch("fingerprint_login", data.params);
                        this.is_login = false;
                        CommonUtils.redirect_by_role(this);
                        this.$globalStorage.request_all_data(); // 로그인시 전체 정보 재 요청.
                    }
                }
            }
        },

        finger_msg_handler(msg)
        {
            if(msg.command == "res_fingerprint_status")
            {
                this.finger_status = msg.status;
            }
                
        },
        temp_login(id, pw) {
            this.userid = id;
            this.pw = (pw == undefined?"mobi1234":pw);

            this.request_login();
        },
        clear_output() {
            this.error_msg = "";
        },

        request_login() {
            const req = {
                id: this.userid,
                password: this.pw,
            };

            this.$store
                .dispatch("login", req)
                .then((result) => {
                    console.log(result);
                    CommonUtils.redirect_by_role(this);
                    this.$globalStorage.request_all_data(); // 로그인시 전체 정보 재 요청.
                })
                .catch((err) => {
                    console.log(err);
                    this.error_msg = error_msg_loginfailed;
                });
        },
    },
};
</script>

<style scoped>
* {
    padding: 0;
    margin: 0;
}

button {
    width: 100px;
    height: 30px;
    margin: 10px 10px;
}
li {
    list-style: none;
}
a {
    text-decoration: none;
    display: block;
    color: #333;
}
#container {
    background-color: #f2f2f2;
    font-weight: normal;
    height: 100vh;
}

main {
    width: 100%;
}
.student_login_wrap {
    width: 370px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.student_login_logo {
    margin-bottom: 50px;
}
.student_login_input > div.logininput {
    width: 370px;
    height: 50px;
    background-color: #fff;
    border-radius: 50px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    box-sizing: border-box;
}

div#fingerprint_guide {
    display: block;
    margin-top: 20px;
}

div#fingerprint_guide > div#fingerprint_output {
    display : block;
    height : 30px;
    line-height : 30px;
    font-weight : bold;
    color :#800;
}

.student_login_input input {
    height: 50px;
    border: none;
    background-color: #fff;
    margin-left: 30px;
    font-size: 18px;
    outline: none;
    color: #6c6c6c;
    line-height: 50px;
    /* border : 1px solid #000; */
    font-family: "NanumBarunGothic";
    width: 70%;
}
.student_login_input input::placeholder {
    color: #d9d9d9;
}
.student_login_input > div:nth-child(1) img {
    width: 24px;
    height: 26px;
}
.student_login_input > div:nth-child(2) img {
    width: 26px;
    height: 26px;
}
.student_login_submit {
    width: 370px;
    height: 50px;
    border-radius: 50px;
    background-color: #1e3560;
    position: relative;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    line-height: 50px;
    user-select: none;
}

.output_error {
    font-weight: bold;
    padding-top: 20px;
    color: #800;
    height: 50px;
    /* border : 1px solid #000; */
}
</style>
