import FrontCheckList from "@/components/front/FrontJs/FrontCheckListData";

const EventManager = {
    debug : true,
    listener : [
        //type, class, handler
    ],
    EmitToParent : (from, event_name, event_param) =>
    {
        let vm = from;
        let attr_event_name = "on" + event_name.charAt(0).toUpperCase() + event_name.slice(1);
        if(EventManager.debug) console.log(attr_event_name);
        while(vm)
        {
            if(vm.$attrs[attr_event_name])
            {
                vm.$emit(event_name, event_param);
                break;
            }
            vm = vm.$parent;
        }                
    }
    ,
    install : (vue, options) =>
    {
        EventManager.listener = [];
        vue.config.globalProperties.$EventManager = {
            EmitToParent : EventManager.EmitToParent,
            AddListener : EventManager.AddListener,
            RemoveListener : EventManager.RemoveListener,
            EmitTo : EventManager.EmitTo,
        };
    },
    AddListener(event_type, event_class, handler)
    {
        // 중복된 이벤트일경우 무시
        for(var i = 0;i<EventManager.listener.length;i++)
        {
            var event_item = EventManager.listener[i];
            if(event_item.type == event_type && event_item.handler == handler)
                return false;
        }

        EventManager.listener.push({
            type : event_type,
            class : event_class,
            handler : handler,
        });

        // console.log(EventManager.listener);
    },
    RemoveListener(event_type, handler)
    {
        for(var i = EventManager.listener.length -1 ; i >=0 ;i--)
        {
            var event_item = EventManager.listener[i];
            if(event_item.type == event_type && (handler == undefined || event_item.handler == handler))
                EventManager.listener.splice(i, 1);
        }
    },

    EmitTo(event_type, event_class, event_param)
    {
        EventManager.listener.forEach( (event_item) => {
            if(event_type == event_item.type)
            {
                if(event_item.handler)
                event_item.handler(event_class, event_param);
            }
        });
    }
}

export default EventManager;