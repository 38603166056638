<template>
    <div id="enter_waiting">
        <div>
            입실 허가 대기 중입니다.
        </div>
        <input type="button" value="입실 허가 재요청" @click="request_re_late_check_in()" class="rerequest">
        <input type="button" value="로그 아웃" @click="close()" class="logout">
    </div>
</template>

<script>
import {ApiReq} from "@/components/manager/ManagerUtils"

export default {
    props : {
        attr : Object,
    },
    methods : {
        close() {
            this.$ModalManager.close(this);    
            this.$store.dispatch("logout");
            this.$router.push("/login")
        },
        request_re_late_check_in() {
            new ApiReq("/checkin/late", this.attr)
                .setFailCallback(this.request_fail_late_check_in)
                .setResultCallback((data => {
                    this.response_re_late_check_in(data)
                return true;
                }))
                .post();
        },
        request_fail_re_late_check_in() {
            this.$ModalManager.showdialog(
                {
                    title : "요청에 실패하였습니다.",
                    buttons : {
                        "yes" : "",
                    }
                }
            )
        },
        response_re_late_check_in() {
            this.$ModalManager.showdialog({
                title : "재요청 하였습니다",
                buttons : {
                    "yes" : "",
                }
            })
        }
    },
}
</script>

<style scoped>

.rerequest {
    border: 0;
    border-radius: 50px;
    height: 35px;
    background-color: #1E3560;
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-size: 18px;
    padding: 0 15px;
    margin-right: 20px;
}
.logout {
    border-radius: 50px;
    height: 35px;
    width: 100px;
    border: none;
    border-radius: 20px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    background-color: #9c9c9c;
    margin-left: 20px;
}
#enter_waiting {
    padding: 20px 40px;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #fff;
    text-align: center;
    line-height: 60px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#enter_waiting > div {
    font-size: 22px;
    font-weight: bold;
    color: #1e3560;
}
</style>