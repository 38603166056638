<template>
    <div id="leavePop">
        <div class="leavepop_in">
            <h1>
                <span>잠깐!</span> 놓친 사항이 없는지 확인해주세요.
            </h1>
            <div class="leave_check_list">
                <div v-if="check_data.point_content.length > 1">
                    <h2>
                        오늘 부과된 벌점입니다.
                    </h2>
                    <dl v-for="(points, point_idx) in check_data.point_content" :key="point_idx">
                        <dt>
                            {{points.title}}
                        </dt>
                        <dd>
                            {{points.point}}점 {{points.content}}
                        </dd>
                    </dl>
                </div>
                <div>
                    <h2>
                        오늘 플래너 검사는 받으셨나요?
                    </h2>
                    <ul>
                        <li>
                            검사 예정 시간 : {{check_data.planner_inspection}}
                        </li>
                    </ul>
                </div>
                <div v-if="check_data.consulting_content.length > 1">
                    <h2>
                        오늘 상담은 잘 받으셨나요?
                    </h2>
                    <ul v-for="(con, con_idx) in check_data.consulting_content" :key="con_idx">
                        <li>
                            {{con.title}} ({{con.teacher}}) {{con.time}}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="check_time">
                <div>
                    오늘 퇴실 예정 시간은 <span>{{exit_time}}</span>입니다.
                </div>
                <div id="front-now-result">
                </div>
                <div>
                    오늘 총 입실시간 <span>{{stay_time}}</span>입니다.
                </div>
            </div>
            <div class="bottom_title">
                <h3>
                    퇴실 하시겠습니까?
                </h3>
                <p>
                    오늘 하루도 수고하셨습니다.
                </p>
            </div>
            <div class="buttons">
                <input type="button" value="취 소" class="cancle" @click="close()">
                <input type="submit" value="퇴 실" @click="ok()" >
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "@/services/api.service"
import LoginData from "@/components/student/data/StudentLoginData"
import { SCHEDULE_API } from "@/services/exapi.service.js"
import { WEEKDAYS } from '@/components/common/student_week_schedule/js/constant'
import {date_format, time_format_for_string} from '@/js/util/math'
import SPARTA_TIME from '@/components/common/student_week_schedule/js/sparta_time'
import { ApiReq } from '@/components/manager/ManagerUtils'

export default {
    props : {
        attr : Object,
    },
    data () {
        return {
            check_data : {
                point_content : [],
                consulting_content : [],
            },
            exit_time : "-",
            myinfo : {},
            stay_time : "-",
        }
    },
    methods : {
        async set_actual_stay_time() {
            let res_minstamp = 0
            let mytoday = null
            if(!this.$globalStorage.getters.mytoday) {
                await new ApiReq("/my/today")
                    .setResultCallback(data => {
                        mytoday = data;
                    })
                    .get()
            }
            else {
                mytoday = JSON.parse(JSON.stringify(this.$globalStorage.getters.mytoday))
            }
            let actual_enter_time = mytoday.actual_enter_time
            let actual_exit_time = mytoday.actual_exit_time
            actual_exit_time.push(date_format(new Date(), "HH:mm:ss"))

            actual_enter_time.forEach((enter_time, index) => {
                if(actual_exit_time.length > index) {
                    const exit_time = actual_exit_time[index]
                    const enter_time_array = enter_time.split(":")
                    const exit_time_array = exit_time.split(":")
                    let enter_minstamp = SPARTA_TIME.GetSpartaMinStampValue(null, enter_time_array[0], enter_time_array[1])
                    let exit_minstamp = SPARTA_TIME.GetSpartaMinStampValue(null, exit_time_array[0], exit_time_array[1])

                    if(enter_minstamp < (SPARTA_TIME.END_HOUR + 1) * 60) {
                        enter_minstamp += (24 * 60)
                    }
                    if(exit_minstamp < (SPARTA_TIME.END_HOUR + 1) * 60) {
                        exit_minstamp += (24 * 60)
                    }

                    res_minstamp += (exit_minstamp - enter_minstamp)
                }
            })
            if(res_minstamp < 0) {
                this.stay_time = "-"
                return;
            }
            let res_time = SPARTA_TIME.MinstampTo24(res_minstamp)
            const time_obj = time_format_for_string(res_time)
            // 공부 시간 확인.
            if(time_obj.hour && time_obj.minute) {
                this.stay_time = time_obj.hour + " 시 " + time_obj.minute + " 분"
            }
            else {
                this.stay_time = "-"
            }
        },
        response_before_logout_data(res) {
            this.check_data = LoginData.convert_notice_data_to_logout_modal_data(res)
        },
        request_before_logout_data() {
            ApiService.call(ApiService.GET, "/my/check/before-logout").then(res => {
                if(res && res.data) {
                    this.response_before_logout_data(res.data)
                }
            }).catch(err => {
                console.log(err)
            })
        },
        async request_schedule_data(no) {
            const res = await SCHEDULE_API.load_schedule(no)
            let schedule_data = []

            if(res && res.json) {
                schedule_data  = (JSON.parse(res.json)).map(item=>item.sections);
                this.get_exit_time(schedule_data)
            }
        },
        get_exit_time(schedule_data) {
            const week_index = WEEKDAYS.GetTodayIndex()
            if(Array.isArray(schedule_data) && schedule_data.length > week_index)
            {
                const today_schedule_data = schedule_data[week_index]

                if(Array.isArray(today_schedule_data) && today_schedule_data.length > 0) {
                    const last_section = today_schedule_data[today_schedule_data.length - 1]
                    const exit_time = last_section.stay?.end?.str
                    if(typeof exit_time == "string") {
                        const time_obj = time_format_for_string(exit_time)
                        // 퇴실 시간 확인.
                        this.exit_time = time_obj.hour + " 시 " + time_obj.minute + " 분"
                    }
                }
            }
        },
        close() {
            this.$ModalManager.close(this, {result : "no"})
        },
        ok() {
            this.$ModalManager.close(this, {result : "yes"})
        },
        init_data() {
            this.myinfo = this.$globalStorage.getters.myinfo
            this.request_before_logout_data()
            if(this.myinfo) {
                this.request_schedule_data(this.myinfo.member_no)
            }
            this.set_actual_stay_time();
        }
    },
    created() {
        this.init_data()
    },
}
</script>

<style scoped>
#leavePop {
    width: 442px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 106;
    background-color: #fff;
    border-radius: 15px;
    padding: 30px 0;
    box-sizing: border-box;
    color: #6c6c6c;
}
.leavepop_in {
    width: 382px;
    margin: 0 auto;
}
.leavepop_in h1 {
   color:  #1E3560;
   font-size: 23px;
   margin-bottom: 14px;
}
.leavepop_in span {
    color: #61C1BE;
}
.leave_check_list {
    width: 382px;
    background-color: #F2F2F2;
    padding: 15px;
    box-sizing: border-box;
    position: relative;
    /* overflow-y: scroll; */
}
.leave_check_list > div {
    margin-bottom: 20px;
}
.leave_check_list > div:last-child {
    margin-bottom: 0;
}
.leave_check_list > div h2 {
    font-size: 16px;
    color: #1E3560;
    font-weight: bold;
    margin-bottom: 5px;
}
.leave_check_list > div dl dt,
.leave_check_list > div dl dd {
    margin-bottom:5px;
}
.leave_check_list > div dl dt {
    font-weight: bold;
}
.leave_check_list > div ul li {
    margin-bottom: 5px;
}
.leave_check_list > div ul li:last-child {
    margin-bottom: 0;
}
.leavepop_in > .check_time {
    width: 300px;
    margin: 0 auto;
    padding: 20px 0;
    position: relative;
    margin-bottom: 20px;
    text-align: center;
}
.leavepop_in > .check_time::before {
    position: absolute;
    content: '';
    width: 382px;
    height: 1px;
    background-color: #9c9c9c;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
#front-now-result {
    color: #6c6c6c;
    margin-top: 5px;
    margin-bottom: 15px;
}
.leavepop_in > .bottom_title {
    text-align: center;
    margin-bottom: 20px;
}
.leavepop_in > .bottom_title h3 {
    font-size: 24px;
    font-weight: bold;
    color: #1E3560;
    margin-bottom: 10px;
}
.leavepop_in > .buttons {
    width: 364px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.leavepop_in > .buttons input {
    width: 170px;
    height: 40px;
    border-radius: 20px;
    background-color: #9C9C9C;
    color: #fff;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    border: none;
    cursor: pointer;
}
.leavepop_in > .buttons input[type="submit"] {
    background-color: #1E3560;
}
</style>