import jwtService from "@/services/jwt.service";
import ApiService from "@/services/api.service";
import router from "@/router"

const SPARTA_SOCKET_CLOSE = 4000;
class sparta_socket {
    constructor(account_info)
    {
        this.debug = true;
        this.socket = null;
        this.account_info = account_info;
        this.handlers = [];
        // this.address = "ws://sparta.exadynetech.com:8085";
        this.address = "wss://www.spartaacademy.net/wss";
        if(process.env.VUE_APP_ISTEST)
            this.address = "wss://spartatest.exadynetech.com/wss";
    }

    connect(account_info, where = "init")
    {
        console.warn("wssocket connect from : " , where);
        const _this = this;
        _this.is_reconnect = false;
        // _this.refresh_account_info = undefined;
        if(account_info) _this.account_info = account_info;
        else account_info = _this.account_info;

        // 재 연결시 동작.
        if(_this.socket != null && _this.socket.readyState == _this.socket.OPEN) {
            _this.reconnect("connect in open socket, reconnect ");
            return;
        }
        
        _this.socket = new WebSocket(_this.address);

        _this.socket.onopen = function(){
            const socket = _this.socket;
            if(!socket) return;
            console.log("connected");
            // socket.onopen = (ev)=>{if(_this)_this.onopen(ev)};
            socket.onmessage = (ev)=>{if(_this)_this.onmessage(ev)};
            console.log("start heartbeat");
            _this.heartbeat();

            const req = {
                command : "req/validate/client",
                message : account_info,
            };

            _this.socket.send(JSON.stringify(req));

            
            if(_this.reconnect_msg){
                _this.reconnect_msg = false;
                // # reconnect 동작 이후, list update msg 발송.
                const req = {
                    command : "msg/check_list_update",
                    message : { refresh : true },
                    target : "self",
                }
                _this.socket.send(JSON.stringify(req));
            }
        };

        _this.socket.onerror = function(error) {
            console.error("sparta_socket", "falied to connect, will try to reconnect");
            _this.reconnect("error in reconnect");
        }

        _this.socket.onclose = (msg)=>{
            console.log(msg);
            _this.socket = null;

            if(_this.is_close_reconnect){
                _this.is_close_reconnect = false;
                _this.reconnect("socket close in is_close_reconnect");
            }

            //# 비정상 종료 code
            //# https://datatracker.ietf.org/doc/html/rfc6455#section-7.4.1:~:text=2011%0A%0A%0A7.2.-,Abnormal%20Closures,-7.2.1.%20%20Client%2DInitiated

            //# 정상 종료가 아닐 경우 무조건 재연결.
            if(msg.code != SPARTA_SOCKET_CLOSE && !_this.not_valid){
                console.error("sparta_socket", "close to websocket, will try to reconnect");
                setTimeout(() => {
                    _this.reconnect("server socket close in reconnect");
                }, 3000); 
            }
        }
    }

    heartbeat() {
        const _this = this; 

        const socket = _this?.socket;

        if(!socket) return;
        
        if (socket.readyState !== socket.OPEN) {
            _this.reconnect("heartbeat socket not open");
            return;
        }

        const d = new Date();
        // console.log("heartbeat", d.getHours().toString().padStart(2,"0")+" 시", d.getMinutes().toString().padStart(2,"0")+" 분", d.getSeconds().toString().padStart(2,"0")+" 초");
        socket.send("heartbeat");
        _this.heartbeat_no = setTimeout(()=> {
            _this.heartbeat()
        }
        , 1000 * 20);
    }
    
    reconnect(where) {
        // console.warn("reconnect where : ", where);
        const _this = this;
        if (_this.socket != null && _this.socket.readyState == _this.socket.OPEN) _this.is_close_reconnect = true;

        _this.disconnect();

        if (_this.is_close_reconnect) return;

        if (document.hidden) {
            console.error("reconnect but document hidden, so reconnect after document open...")
            _this.reconnectHandler = _this.reconnect.bind(_this, "hidden in reconnectHandler");
            document.addEventListener("visibilitychange", _this.reconnectHandler);
            return;
        }

        document.removeEventListener("visibilitychange", _this.reconnectHandler);


        _this.account_info.key = jwtService.getAccessToken();
        _this.reconnect_msg = true;
        _this.connect(_this.account_info);
    }

    disconnect() {
        const _this = this;
        
        clearTimeout(_this.heartbeat_no);
        _this.heartbeat_no = null;

        if(_this.socket != null) _this.socket.close(SPARTA_SOCKET_CLOSE, "disconnect");
    }

    broadcast(command, params) {
        return this.socket.send(command, params, "*");
    }

    send(command, params, target)
    {
        const _this = this;
        return new Promise((resolve, reject) => {
            const socket = _this.socket;
            if(socket != null && socket.readyState == socket.OPEN)
            {
                if(target == undefined)
                    reject("userid is not defined");
                
                if(target == "self") 
                {
                    target = "";
                    var message = {command : command, params : params, target : target};
                    var loopback_msg = {data : JSON.stringify(message)};
                    socket.onmeesage(loopback_msg);
                    resolve();
                    return;
                }

                const msg = { command : command, params : params, target : target };
                socket.onerror = function(error) {
                    reject(error);
                };

                console.log(msg);

                try {
                    socket.send(JSON.stringify(msg));
                    resolve();
                } catch (e) {
                    reject(e);
                }
            } else {
                reject("not connected");
            }
        });
    }

    add_message_handler(command, handler)
    {
        const _this = this;
        // console.log("add_message_handler");
        
        // 중복 체크
        if(this.handlers.some(e=>e.command == command && e.handler == handler)) return;
        
        this.handlers.push(
            {
                command : command,
                handler : handler,
            }
        );

        // console.log("add_message_handler", this.handlers.length, command, handler);

    }

    remove_message_handler(command, removehandler)
    {
        const _this = this;
        for(var i = _this.handlers.length - 1; i>=0;i--)
        {
            const handler = _this.handlers[i];

            if(removehandler) {
                if(handler.command == command && handler.handler == removehandler)
                    _this.handlers.splice(i, 1);
            } else {
                if(handler.command == command)
                    _this.handlers.splice(i, 1);
            }
        }
    }

    onmessage(ev)
    {
        const _this = this;
        const msg = ev.data;
        const message = JSON.parse(msg);
        // console.info("onmessage", message);

        if(message.command == undefined) return;

        if(message.command == "__info")
        {
            console.info(message.message);
            return;
        }

        // console.log(_this.handlers);

        if(_this.handlers)
            for(var i=_this.handlers.length - 1;i>=0;i--)
            {
                const handler = _this.handlers[i];
                // console.log(message.command);
                if(message.command.startsWith(handler.command))
                {
                    try {
                        handler.handler(message);
                    } catch(e) {
                        _this.handlers.splice(i, 1);
                        console.error("call message handler is failed");
                    }
                }

                if(message.command.startsWith("error")){
                    console.error("socket error", message);
                    if(message.message.includes("not valid client")) {
                        _this.not_valid = true;
                        _this.disconnect();
                        // 45 line - backend token check 
                        // # 오랜시간 연결이 안되고 있을경우, token 사라짐. refresh
                        // # token 갱신 실패시 api.service.js line 159 동일한 로직 
                        jwtService.deleteAllToken();
                        jwtService.deleteUserInfo();
                        jwtService.deleteCompanyAdminCenterInfo();
                        router.push("/login");
                    }
                }
                    
            }
    }
}

export default sparta_socket;