<template style="z-index:95;">
    <DatePicker v-model="date_raw" :is-required="is_required" :masks="{title:'YYYY MMMM'}" :model-config="modelConfig" @dayclick="cal_input()" :locale="locale"
        :min-date="min_date">
        <template v-slot="{inputValue, togglePopover }">
            <div ref="date_content" class="date_content">
                <input :value="inputValue ? inputValue : '없음'" @keypress.enter="date_input($event)"/>
                <img src="@/assets/images/calendar.png" @click="togglePopover">
            </div>
        </template>
    </DatePicker>
</template>

<script>
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';

export default {
    components : {
        DatePicker, 
    },

    props : {
        date : Object,
        min_date : {
            type : String,
            default : null,
        },
        is_required : {
            type : Boolean,
            default : true,
        }
    },

    created() {
        this.r_date = this.date

        if(!this.is_required && this.r_date.datestring == "") return;

        if(this.r_date.datestring == undefined || this.r_date.datestring == "")
        {
            this.date_raw = new Date();
        } else 
        {
            this.date_raw = new Date(this.date.datestring);
        }

        this.cal_input();

    },

    watch : {
        "date" : {
            handler : function(value) {
                this.r_date = this.date
                this.date_raw = new Date(this.date.datestring);
            }, deep : true
        }
    },
    data() {
        return {
            r_date : this.date,
            date_raw : "",
            modelConfig: {
                type: 'string',
                masks: {
                    title : "YYYY MMMM",
                }
            },
            locale : {
                    id : 'ko', 
                    firstDayOfWeek : 1, 
                    masks : {
                        L : "YYYY-MM-DD",
                    }
                }
        }
    },
    methods : {
        num_format(val)
        {
            var vs = String(val);
            if(vs.length == 1)
                vs = "0" + vs;
            return vs;
        },

        date_input(e)
        {
            this.date_raw = new Date(e.target.value);
            if(!this.is_required && !e.target.value) this.r_date.datestring = '';
            else this.cal_input();
        },

        cal_input(e)
        {
            var datevalue = new Date(this.date_raw);
            var datestring = datevalue.getFullYear() + "-" + this.num_format(datevalue.getMonth() + 1) + "-" + this.num_format(datevalue.getDate());
            if(datestring === "1970-01-01" || datestring === "NaN-NaN-NaN")
            {
                this.date_raw = new Date(this.r_date.datestring);
                this.cal_input();
                return;
            }
            this.r_date.datestring = datestring;
        }
    }
}
</script>

<style scoped>
.calendar_popup {
    position: absolute;
    z-index: 1000;
    width : fit-content;
    height : fit-content;
}
.date_content {
    width: 150px;
    height: 26px;
    border: 1px solid #9c9c9c;
    border-radius: 50px;
    padding-left: 10px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
}
.date_content input {
    position: relative;
    width: 100px;
    height: 19px;
    border: none;
    color: #6c6c6c;
    font-size: 16px;
    outline: none;
    background : rgba(0, 0, 0, 0);
    
}
.date_content img {
    width: 16px;
    height: 16px;
    right: 13px;
    position: absolute;
    user-select: none;
    cursor:pointer;
}

</style>
<style>

</style>