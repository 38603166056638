import FrontVipInput from "@/components/front/Modal/FrontVipInput.vue";
import FrontStudentInfoModal from "@/components/front/Modal/FrontStudentInfoModal.vue";
import BadScoreModal from "@/components/front/Modal/BadScoreModal.vue";
import BadScoreAddModal from "@/components/front/Modal/BadScoreAddModal.vue";
import SearchResultModal from "@/components/front/Modal/SearchResultModal.vue";
import NoticeModal from "@/components/common/CenterNotify/Modal/NoticeModal.vue";
export default {
    vip_input_modal : FrontVipInput,
    student_info_modal : FrontStudentInfoModal,
    bad_score_modal : BadScoreModal,
    bad_score_add_modal : BadScoreAddModal,
    search_result_modal : SearchResultModal,
    notice_modal : NoticeModal,
}