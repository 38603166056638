import LateEnterModal from "@/components/student/modal/StudentLateEnterModal.vue"
import EnterWatingModal from "@/components/student/modal/StudentEnterWaitingModal.vue"
import EnterCheckNoticeModal from "@/components/student/modal/StudentEnterCheckNoticeModal.vue"
import TeacherTimeTableModal from "@/components/student/modal/StudentTeacherTimeTableModal.vue"
import StudyDataApplyModal from "@/components/student/modal/StudentStudyDataApplyModal.vue"
import ClassLeaveModal from "@/components/student/modal/StudentClassLeaveCheck.vue"

export default {
    late_enter : LateEnterModal,
    enter_waiting : EnterWatingModal,
    enter_check_notice : EnterCheckNoticeModal,
    teacher_time_table : TeacherTimeTableModal,
    study_data_apply: StudyDataApplyModal,
    class_leave : ClassLeaveModal,
}