import StudentModal from "@/components/student/modal/__StudentModalContainer"
import ConsultingModal from "@/components/consulting_teacher/modal/ConsutlingModalContainer"
import FrontModal from "@/components/front/Modal/__FrontModalContainer"
import CommonMessageBox from "./CommonMessageBox"

export default  {
    ...StudentModal,
    ...FrontModal,
    CommonMessageBox,
    ...ConsultingModal,
}