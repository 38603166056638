<template>
    <section class="modal_main">
        <div class="title_bar">
            <h2 v-if="this.isCoupon">쿠폰 학생 정보</h2>
            <h2 v-else>재원 학생 정보</h2>
            <button @click="this.move()">학생 종합 정보</button>
        </div>
        <div class="info_bar">
            <FrontCommonStudentInfoBar :pInfoData="this.student" />
        </div>
        <div class="phone">
            <div>
                <h3>학생 연락처</h3>
                <span>{{ this.phoneStr(this.student?.phone_number) }}</span>
            </div>
            <div>
                <h3>학부모 연락처</h3>
                <span>{{ this.phoneStr(this.student?.parent_phone_number) }}</span>
            </div>
        </div>
        <div class="day">
            <div>
                <h3>결제일자</h3>
                <span>{{ this.student?.pay_start_day ?? "-" }}</span>
            </div>
            <div>
                <h3>만기일자</h3>
                <span>{{ this.student?.pay_end_day ?? "-" }}</span>
            </div>
            <div>
                <h3>셔틀버스</h3>
                <span>{{ this.shuttleStr(this.student) }}</span>
            </div>
        </div>
        <div class="list_box">
            <!-- <div>
                <h3>결제 내역</h3>
            </div>
            <div class="list">
                <div style="background-color: rgba(0, 0, 0, 0.5); z-index: 80; position: absolute; width: 650px; height: 200px"></div>
                <table>
                    <tr class="t_header">
                        <th class="middle">결제일</th>
                        <th>결제금액</th>
                        <th class="middle">결제방식</th>
                        <th class="middle">결제상태</th>
                        <th>등록구분</th>
                    </tr>
                    <tr v-for="item in this.student?.pay_list" :key="item">
                        <td>{{ item.pay_day ?? "-" }}</td>
                        <td>{{ item.pay_money ?? "-" }}</td>
                        <td>{{ item.pay_method ?? "-" }}</td>
                        <td>{{ item.pay_status ?? "-" }}</td>
                        <td>{{ item.category ?? "-" }}</td>
                    </tr>
                </table>
            </div> -->
        </div>
        <div class="list_box" v-if="this.isCoupon">
            <div>
                <h3>쿠폰 사용 내역</h3>
                <div class="day_box">
                    <day-picker v-model:date="this.selectedCouponDay.begin" />
                    <span>~</span>
                    <day-picker v-model:date="this.selectedCouponDay.end" />
                    <await-button @click="this.lookup(false)">조회</await-button>
                </div>
            </div>
            <div class="list">
                <!-- <div style="background-color: rgba(0, 0, 0, 0.5); z-index: 80; position: absolute; width: 650px; height: 200px"></div> -->
                <table>
                    <tr class="t_header">
                        <th>사용일</th>
                        <th>입실 시간</th>
                        <th>퇴실 시간</th>
                    </tr>
                    <tr v-for="item in this.student?.coupon_list" :key="item">
                        <td>{{ this.dayStr(item.created_at) }}</td>
                        <td>{{ this.timeStr(item.in) }}</td>
                        <td>{{ this.timeStr(item.out) }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="button_bar">
            <button @click="this.close()">닫 기</button>
        </div>
    </section>
</template>

<script>
import FrontCommonStudentInfoBar from "@/components/front/Common/FrontCommonStudentInfoBar.vue";
import { ApiReq } from "@/components/manager/ManagerUtils";
import CommonData from "@/components/common/data/CommonData";
import FrontUtils from "@/components/front/FrontJs/FrontUtils";
import { date_format } from '@/js/util/math';
export default {
    name: "FrontStudentInfoModal",
    components: {
        FrontCommonStudentInfoBar,
    },
    props: {
        id: String,
        isCoupon: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            student: {},
            selectedCouponDay: {
                begin: { datestring: "" },
                end: { datestring: "" },
            },
        };
    },
    methods: {
        close: function () {
            this.$ModalManager.close(this);
        },
        move: function () {
            this.$ModalManager.close(this);
            this.$router.push({
                name: "/front/record",
                params: { id: this.id },
            });
        },
        shuttleStr(student) {
            return CommonData.use_shuttle_bus[student?.use_shuttle_bus] ?? "-";
        },
        phoneStr(number) {
            return number === "--" || !number ? "-" : number;
        },
        dayStr(day) {
            const s_day = FrontUtils.util_ReturnStringDate(day, "/2");
            if (s_day) return s_day;
            return "-";
        },
        timeStr(time) {
            if (time) {
                let _t = time.split(":");
                if (_t[0]) return _t[0] + ":" + _t[1];
            }
            return "-";
        },
        async lookup(init = true) {
            let p = {
                type: 2, //사용한 쿠폰
                begin: this.selectedCouponDay.begin.datestring,
                end: this.selectedCouponDay.end.datestring,
            };

            let ret = await new ApiReq("/student/" + this.id + "/coupon/records", p)
                .setResultCallback((res) => {
                    this.student.coupon_list = res;
                    return res instanceof Array;
                })
                .get();

            if (!ret) this.$ModalManager.showdialog("쿠폰정보를 불러오는데 실패하였습니다.");
            if(!this.student.coupon_list.length && !init) this.$ModalManager.showdialog("조회된 쿠폰 정보가 없습니다.");
        },
        async init() {
            let ret = await new ApiReq("/student/" + this.id + "/info")
                .setResultCallback((res) => {
                    console.log(res);
                    this.student = res;
                    this.student.pay_start_day = date_format(res.payment_datetime, "YY-MM-DD") || "-";
                    this.student.pay_end_day = date_format(res.expiration_datetime, "YY-MM-DD") || "-";
                    this.student.pay_list = [
                        {
                            pay_day: null,
                            pay_money: null,
                            pay_method: null,
                            pay_status: null,
                            category: null,
                        },
                        {
                            pay_day: "21/09/02",
                            pay_money: "390,000원",
                            pay_method: "카드",
                            pay_status: "결제완료",
                            category: "1개월 등록(방학+학기)",
                        },
                        {
                            pay_day: "21/06/02",
                            pay_money: "1,100,000원",
                            pay_method: "카드",
                            pay_status: "결제완료",
                            category: "3개월 등록(학기)",
                        },
                        {
                            pay_day: "21/05/02",
                            pay_money: "390,000원",
                            pay_method: "카드",
                            pay_status: "결제완료",
                            category: "1개월 등록(방학+학기)",
                        },
                        {
                            pay_day: "21/04/02",
                            pay_money: "1,100,000원",
                            pay_method: "카드",
                            pay_status: "결제완료",
                            category: "3개월 등록(학기)",
                        },
                    ];
                })
                .get();

            if (ret) {
                if (this.isCoupon) {
                    this.lookup();
                }
            } else {
                this.$ModalManager.showdialog("학생정보를 불러오는데 실패하였습니다.");
            }
        },
    },
    created: function () {
        this.selectedCouponDay.begin.datestring = FrontUtils.util_ReturnStringDate(new Date(new Date().setDate(new Date().getDate() - 7)), "-");
        this.selectedCouponDay.end.datestring = FrontUtils.util_Today();
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.modal_main {
    background-color: #fff;
    width: 650px;
    height: fit-content;
    border-radius: 30px;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    > div {
        display: flex;
        flex: 1;
        height: 0;
        margin-bottom: 20px;
        &:nth-last-of-type(1) {
            margin: 0;
        }
    }
    .title_bar {
        justify-content: space-between;
        align-items: center;
        button {
            width: 124px;
            height: 27px;
            background-color: #1e3560;
        }
    }
    .phone {
        flex-direction: column;
        justify-content: space-between;
        border-top: 1px solid #9c9c9c;
        border-bottom: 1px solid #9c9c9c;
        padding-top: 20px;
        padding-bottom: 20px;
        > div {
            display: flex;
            justify-content: flex-start;
            &:nth-of-type(1) {
                margin-bottom: 10px;
            }
        }
    }
    .day {
        justify-content: flex-start;
        border-bottom: 1px solid #9c9c9c;
        padding-bottom: 20px;
        > div {
            display: flex;
            flex: 1;
        }
    }
    .list_box {
        flex-direction: column;
        .list {
            overflow-x: none;
            overflow-y: scroll;
            height: 180px;
            margin-top: 10px;
            border-bottom: 1px solid #9c9c9c;
            padding-bottom: 20px;
            &:nth-of-type(2) {
                border: none;
            }
            > table {
                margin-right: 10px;
                width: calc(100% - 10px);
                border-collapse: separate;
                border-spacing: 0;
                table-layout: fixed;
                color: #6c6c6c;
                .t_header {
                    position: sticky;
                    top: 0;
                    background-color: #f2f2f2;
                }
                th {
                    font-size: 16px;
                    font-weight: bold;
                    border-top: 1px solid #9c9c9c;
                    color: #1e3560;
                }
                td,
                th {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    height: 25px;
                    padding: 5px;
                    border-bottom: 1px solid #9c9c9c;
                }
            }
            &::-webkit-scrollbar {
                height: 16px;
                width: 16px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #d9d9d9;
            }
            &::-webkit-scrollbar-track {
                background-color: #f2f2f2;
            }
            .middle {
                width: 75px;
            }
        }
        > div:nth-of-type(1) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .button_bar {
        justify-content: center;
    }
    button {
        width: 170px;
        height: 40px;
        border-radius: 20px;
        border: none;
        color: #fff;
        font-size: 16px;
        background-color: #9c9c9c;
    }
    h2 {
        font-weight: bold;
        font-size: 24px;
    }
    h3 {
        font-weight: bold;
        font-size: 16px;
        text-align: left;
        width: 120px;
    }
    .day_box {
        display: flex;
        align-items: center;
        > span {
            margin: 0 5px;
            color:#6c6c6c;
        }
        button {
            background-color: #1e3560;
            width: 58px;
            height: 26px;
            margin-left: 10px;
        }
    }
}
</style>