<template>
    <section class="student_info_bar" :style="{ 'font-size': this.fontSize+'px' }">
        <div>{{ this.pInfoData?.name ?? "-" }}</div>
        <div>{{ this.pInfoData?.id ?? "-" }}</div>
        <!-- <div>{{ this.phone_str(this.pInfoData) ?? "-" }}</div> -->
        <div>{{ this.grade_str(this.pInfoData) ?? "-" }}</div>
    </section>
</template>

<script>
import CommonGrade from "@/components/common/CommonGrade";
import FrontUtils from "../FrontJs/FrontUtils";
export default {
    name: "FrontCommonStudentInfoBar",
    mixins: [CommonGrade],
    props: {
        pInfoData: Object,
        fontSize: {
            type: Number,
            default: 16,
        },
    },
    methods: {
        grade_str(student) {
            const grade = this.gradeValue(student?.grade);
            const school = FrontUtils.util_ReturnSchoolName(student?.school_name);
            if (grade) {
                return school + " " + grade;
            } else {
                return null;
            }
        },
        phone_str(student) {
            const str = FrontUtils.util_ReturnPhoneNumber(student?.phone_number);
            return str !== "" ? str : null;
        },
    },
    created() {},
};
</script>

<style lang="scss" scoped>
.student_info_bar {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    color: #6c6c6c;
    text-align: center;
    div {
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        border-right: 1px solid #9c9c9c;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    div:nth-last-child(1) {
        border: none;
    }
}
</style>