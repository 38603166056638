<template>
    <div id="bad_scorePop">
        <div class="header">
            <h1>벌점 기록</h1>
        </div>
        <div class="main">
            <div class="main_left">
                <StudentList :students="this.students" @select="(student) => this.setStudent(student)" :setId="this.attr?.id" />
            </div>
            <div class="main_right">
                <div class="info_bar">
                    <div>
                        <div v-if="this.selected_student?.id">
                            <b>{{ selected_student.name }}</b> | {{ selected_student.id }} | {{ this.grade_str(this.selected_student) ?? "-" }}
                        </div>
                    </div>
                    <div class="date_bar">
                        <p>조회 기간</p>
                        <day-picker v-model:date="this.search_date.start" />
                        <p>~</p>
                        <day-picker v-model:date="this.search_date.end" />
                    </div>
                </div>

                <div class="point_list">
                    <div>
                        <div>
                            <input type="checkbox" v-model="this.checkAll" :disabled="this.getNotCacelledPoints.length == 0" />
                        </div>
                        <div>날짜</div>
                        <div>벌점 사유</div>
                        <div>학생 입력 사유</div>
                        <div>벌점</div>
                        <div>선생님</div>
                        <div>상태</div>
                        <div>취소 사유</div>
                        <div>취소 선생님</div>
                    </div>
                    <template v-if="this.points.length">
                        <div v-for="point in this.points" :key="point.no">
                            <div>
                                <input type="checkbox" v-model="selected_cancelled_penalties" :value="point" :disabled="point.status == this.cancelled_point_status" />
                            </div>
                            <div>{{ point.point_date ?? "-" }}</div>
                            <div>{{ point.content ?? "-" }}</div>
                            <div>{{ point.reason ?? "-" }}</div>
                            <div>{{ point.point ?? "-" }}</div>
                            <div>{{ point.teacher_name ?? "-" }}</div>
                            <div v-if="point.status != cancelled_point_status"><button class="cancel" @click="this.cancel_panelty(point)">취소</button></div>
                            <div v-else>취소됨</div>
                            <div>{{ point.cancel_reason ?? "-" }}</div>
                            <div>{{ point.cancel_teacher_name ?? "-" }}</div>
                        </div>
                    </template>
                    <template v-else>
                        <div v-if="!this.selected_student?.id" class="empty_page">선택된 학생이 없습니다.</div>
                        <div v-else class="empty_page">벌점 목록이 없습니다.</div>
                    </template>
                </div>
                <div class="total_bar">
                    <div>
                        <p>선택 취소 사유</p>
                        <input type="text" @input="this.text_input" :value="this.all_cancelled_reason" placeholder="취소 사유를 입력해주세요." />
                        <await-button class="cancel" @click="this.onAllCancelPanelty()">선택 취소</await-button>
                    </div>
                    <div>
                        <p>총 벌점</p>
                        <div>
                            <b>{{ this.point_sum ? this.point_sum : 0 }}점</b> ({{ this.search_date.start.datestring }} ~ {{ this.search_date.end.datestring }})
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <input type="button" value="닫 기" class="cancle" v-on:click="this.close()" />
        </div>
    </div>
</template>

<script>
import ModalPaneltyCancel from "@/components/common/ModalPaneltyCancel.vue";

import CommonGrade from "@/components/common/CommonGrade";
import { ApiReq, defaultPageParam } from "@/components/manager/ManagerUtils";
import jwtService from "@/services/jwt.service";
import FrontUtils from "../FrontJs/FrontUtils";
import DayPicker from "@/components/common/DayPicker.vue";
import CommonData from "@/components/common/data/CommonData";
import { EXAPI } from "@/services/exapi.service";
import LoadingModal from "@/components/front/Modal/LoadingModal.vue";

import StudentList from "@/components/common/StudentList.vue";

import StudentData from "@/components/consulting_teacher/data/ConsultingStudentData";
import PANELTY_DATA from "@/components/common/student_week_schedule/js/panelty_data";
export default {
    components: { DayPicker, StudentList },
    name: "BadScoreModal",
    props: {
        attr: Object,
    },
    mixins: [CommonGrade],
    data() {
        return {
            students: [],
            points: [],

            selected_student: {},
            search_keyword: "",
            search_date: {
                start: {
                    datestring: "",
                },
                end: {
                    datestring: "",
                },
            },
            cancelled_point_status: CommonData.cancelled_point_status,

            selected_cancelled_penalties: [],
            all_cancelled_reason: null,
        };
    },
    watch: {
        search_date: {
            handler: "get_points",
            deep: true,
        },
        selected_student: {
            handler: "get_points",
            deep: true,
        },
    },
    methods: {
        async onAllCancelPanelty() {
            if (!this.selected_cancelled_penalties.length) {
                this.$ModalManager.showdialog("취소할 벌점을 선택해주세요.");
                return;
            }
            if (!this.all_cancelled_reason) {
                this.$ModalManager.showdialog("선택하신 벌점의 취소 사유를 입력해주세요.");
                return;
            }

            await this.$ModalManager.showdialog("선택하신 벌점을 정말 취소하시겠습니까?", { yes: "예", no: "아니오" }).then(async (res) => {
                if (res.result == "yes") {
                    this.$ModalManager.showEx(LoadingModal, { bind: { title: "벌점을 취소 중 입니다.", msg: "잠시만 기다려주세요." } });
                    let user_info = jwtService.getUserInfo();

                    for await (const penalty of this.selected_cancelled_penalties) {
                        const req = {
                            panelty_no: penalty.no,
                            cancel_reason: this.all_cancelled_reason.trim(),
                            canceler_id: user_info.userid,
                        };
                        await EXAPI.Call("cancel_panelty", req);
                    }

                    this.$ModalManager.close(LoadingModal);
                    this.$ModalManager.showdialog("선택하신 벌점이 취소되었습니다.");
                    this.get_points();
                }
            });
        },
        text_input(e) {
            if (e.target.value.trim()) {
                this.all_cancelled_reason = e.target.value;
            } else {
                this.all_cancelled_reason = null;
                e.target.value = null;
            }
        },
        close: function () {
            this.$ModalManager.close(this);
        },
        setStudent(student) {
            if (this.selected_student?.id !== student?.id) {
                this.selected_student = student;
            }
        },
        grade_str(student) {
            const grade = this.gradeValue(student?.grade);
            if (grade) {
                return student?.school_name + " " + grade;
            } else {
                return null;
            }
        },
        phone_str(student) {
            const str = FrontUtils.util_ReturnPhoneNumber(student?.phone_number);
            return str !== "" ? str : null;
        },
        cancel_panelty(panelty) {
            const _this = this;
            this.$ModalManager.showEx(ModalPaneltyCancel, {
                bind: {
                    panelty: panelty,
                },
                on: {
                    ok() {
                        _this.get_points();
                    },
                },
            });
        },
        get_points() {
            if (!this.selected_student.id) return;

            this.all_cancelled_reason = null;
            this.selected_cancelled_penalties = [];

            let p = {
                stud: this.selected_student.id,
                begin: this.search_date.start.datestring,
                end: this.search_date.end.datestring,
            };
            new ApiReq("/points", p)
                .setResultCallback((res) => {
                    if (res instanceof Array) {
                        this.points = res.map((e) => {
                            if (e.teacher_id == PANELTY_DATA.TEACHER_ID) {
                                let slice_i = e.content.indexOf("(자동)");
                                if (slice_i != -1) e.content = e.content.slice(0, slice_i);
                                e.content = e.content + " (자동부여) ";
                            }
                            e.point_date = FrontUtils.util_ReturnStringDate(e.created_at, "full");
                            return e;
                        });
                    } else {
                        return false;
                    }
                })
                .setFailMsg("데이터를 불러오는데 실패하였습니다.")
                .get();
        },
    },
    computed: {
        point_sum: function () {
            let sum = this.points.reduce((acc_sum, cur) => {
                if (cur.status != this.cancelled_point_status) acc_sum += Number(cur.point);
                return acc_sum;
            }, 0);

            return sum;
        },
        checkAll: {
            get() {
                return this.selected_cancelled_penalties ? this.selected_cancelled_penalties.length == this.getNotCacelledPoints.length && this.selected_cancelled_penalties.length != 0 : false;
            },
            set(value) {
                this.selected_cancelled_penalties = [];

                if (value) {
                    this.selected_cancelled_penalties = [...this.getNotCacelledPoints];
                }
            },
        },
        getNotCacelledPoints() {
            return this.points.filter((e) => e.status != this.cancelled_point_status);
        },
    },
    created: function () {
        const center_no = jwtService.getUserInfo().center_no;
        if (center_no) {
            let status = StudentData.STUDENT_TYPE.ENROL_STUDENT + "," + StudentData.STUDENT_TYPE.COUPON_STUDENT;
            new ApiReq("/students", defaultPageParam({ ctr: center_no, status: status }))
                .setResultCallback((res) => {
                    if (res instanceof Array) {
                        this.students = res;
                        if (this.attr?.id) {
                            this.selected_student = res.find((e) => e.id === this.attr?.id);
                        }
                    } else {
                        return false;
                    }
                })
                .setFailMsg("학생 정보를 불러오는데 실패하였습니다.")
                .get();
        } else {
            this.$ModalManager.showdialog("등록된 센터 정보가 없습니다.");
        }
        let day = this.attr?.day ? new Date(this.attr?.day) : new Date();
        this.search_date.start.datestring = new Date(day.getFullYear(), day.getMonth(), 1);
        day.setMonth(day.getMonth() + 1);
        this.search_date.end.datestring = new Date(day.getFullYear(), day.getMonth(), 0);
    },
};
</script>

<style lang="scss" scoped>
* {
    user-select: none;
}
/* custom style */

#bad_scorePop {
    background-color: #fff;
    width: 1491px;
    padding: 30px;

    border-radius: 20px;
    color: #6c6c6c;
    box-sizing: border-box;
    font-size: 17px;

    .header {
        padding-bottom: 20px;
        border-bottom: 1px solid #6c6c6c;
        text-align: left;
        h1 {
            font-size: 24px;
            color: #1e3560;
            font-weight: bold;
        }
    }
    .main {
        display: flex;
        justify-content: space-between;
        > div {
            margin-top: 20px;
            height: 570px;
        }
        .main_left {
            width: 490px;
        }
        .main_right {
            flex-direction: column;
            width: 905px;
            .info_bar {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 30px;
                > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    b {
                        font-weight: bold;
                        color: #1e3560;
                    }
                }
                .date_bar {
                    button {
                        height: 28px;
                        width: 50px;
                        text-align: center;
                        color: #fff;
                        border-radius: 50px;
                        font-size: 16px;
                        background-color: #1e3560;
                        position: relative;
                        font-size: 16px;
                    }
                    p {
                        margin: 0 5px;
                    }
                }
            }

            .point_list {
                overflow-y: scroll;
                margin-top: 10px;
                height: 481px;
                > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    font-size: 12px;
                    border-bottom: 1px solid #9c9c9c;
                    box-sizing: border-box;
                    &:nth-last-of-type(1) {
                        border: none;
                    }
                    > div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex: 1;
                        padding: 0 10px;
                        word-break: break-all;
                        &:nth-of-type(1) {
                            flex: 0 0 10px;
                        }
                        &:nth-of-type(2) {
                            flex: 0 0 70px;
                        }
                        &:nth-of-type(5),
                        &:nth-of-type(7) {
                            flex: 0 0 40px;
                        }
                        &:nth-of-type(6),
                        &:nth-last-of-type(1) {
                            flex: 0 0 60px;
                        }
                    }
                    &:nth-of-type(1) {
                        // width:calc(100% - 1px);
                        position: sticky;
                        top: 0;
                        border-style: solid;
                        border-width: 1px 0;
                        border-color: #9c9c9c;
                        background-color: #f7fcff;
                        > div {
                            color: #1e3560;
                            font-weight: bold;
                        }
                    }
                    button.cancel {
                        width: 100%;
                        white-space: nowrap;
                        color: #fff;
                        border-radius: 20px;
                        border: none;
                        font-size: 12px;
                        background: rgb(203, 50, 8);
                        padding: 5px 0px;
                    }
                }

                &::-webkit-scrollbar {
                    width: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #9c9c9c;
                }
                &::-webkit-scrollbar-track {
                    background-color: #d9d9d9;
                }
                .empty_page {
                    width: 100%;
                    height: 400px;
                    justify-content: center;
                    font-size: 17px;
                    color: #1e3560;
                    font-weight: bold;
                }
            }
            .total_bar {
                // margin-left: 10px;
                border-style: solid;
                border-width: 1px 0;
                border-color: #9c9c9c;
                padding: 10px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                > div {
                    display: flex;
                    align-items: center;

                    p {
                        margin-right: 10px;
                        color: #1e3560;
                        font-weight: bold;
                    }
                    b {
                        font-weight: bold;
                    }
                }

                input[type="text"] {
                    margin-right: 10px;
                    width: 300px;
                    height: 26px;
                    text-align: center;
                    border: 1px solid #9c9c9c;
                    border-radius: 20px;
                    font-family: "nanum";
                    font-size: 16px;
                    cursor: text;
                }
            }
        }
    }
    .footer {
        margin-top: 20px;

        input {
            width: 170px;
            height: 40px;
            margin: 0 auto;
            text-align: center;
            border-radius: 20px;
            background-color: #1e3560;
            color: #fff;
            font-size: 18px;
            line-height: 40px;
            cursor: pointer;
            border: none;
        }
    }
}
.cursor {
    cursor: pointer;
}

button.cancel {
    white-space: nowrap;
    color: #fff;
    border-radius: 20px;
    border: none;
    font-size: 13px;
    background: rgb(203, 50, 8);
    padding: 5px 15px;
}
input[type="checkbox"]:disabled {
    cursor: not-allowed;
}
</style>