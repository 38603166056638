<template>
    <div class="modal_main">
        <div class="header">
            <h1>검색 결과</h1>
        </div>
        <div class="main_content">
            <div class="list_title content">
                <div v-if="this.show_center">센 터</div>
                <div>성 명</div>
                <div>학 생 번 호</div>
                <div>학 년</div>
                <div>학 생 유 형</div>
            </div>
            <div v-for="student in this.search_data" :key="student.id" v-bind:class="this.selected_id === student.id ? 'same_name_background' : ''">
                <a v-on:click="this.selected_id = student.id">
                    <div class="content">
                        <div v-if="this.show_center">{{ student.center_name ?? "-" }}</div>
                        <div>{{ student.name ?? "-" }}</div>
                        <div>{{ student.id ?? "-" }}</div>
                        <!-- <div>{{ this.phone_str(student) ?? "-" }}</div> -->
                        <div>{{ this.grade_str(student) ?? "-" }}</div>
                        <div>{{ this.type_str(student) ?? "-" }}</div>
                    </div>
                </a>
            </div>
        </div>
        <div class="footer">
            <button @click="this.onClose()">닫 기</button>
            <button @click="this.func_OkButton()">선 택</button>
        </div>
    </div>
</template>

<script>
import CommonGrade from "@/components/common/CommonGrade";
import FrontUtils from "../FrontJs/FrontUtils";
import CommonData from "@/components/common/data/CommonData";
export default {
    name: "SearchResultModal",
    mixins: [CommonGrade],
    props: {
        search_data: Array,
        show_center: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selected_id: "",
        };
    },
    emits: ["select"],
    methods: {
        func_OkButton: function () {
            this.$emit("select", this.selected_id);
        },
        grade_str(student) {
            const grade = this.gradeValue(student?.grade);
            const school = FrontUtils.util_ReturnSchoolName(student?.school_name);
            if (grade) {
                return school + " " + grade;
            } else {
                return null;
            }
        },
        phone_str(student) {
            const str = FrontUtils.util_ReturnPhoneNumber(student?.phone_number);
            return str !== "" ? str : null;
        },
        type_str(student) {
            const type_name = CommonData.student_type[student.status];
            if (type_name) return type_name + " 학생";
            else return "학생";
        },
        onClose(){
            this.$ModalManager.close(this);
        }
    },
    created: function () {
        this.selected_id = this.search_data[0]?.id;
    },
};
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}
.modal_main {
    background-color: #fff;
    border-radius: 20px;
    color: #6c6c6c;
    padding: 30px;
    > div {
        margin-top: 20px;
        &:nth-of-type(1) {
            margin: 0;
        }
    }
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #1e3560;
    font-weight: bold;
    text-align: left;
}
button {
    width: 170px;
    height: 40px;
    line-height: 40px;
    border-radius: 50px;
    border: none;
    background-color: #1e3560;
    color: #fff;
    /* font-family: "square"; */
    font-size: 17px;
    cursor: pointer;
    margin-left:10px;
    &:nth-of-type(1){
        margin:0;
        background:#9c9c9c;
    }
}
.main_content {
    width: 900px;
    height: 300px;
    overflow-y: auto;
    background-color: #f2f2f2;
    div {
        &.same_name_background {
            background-color: #f7fcff;
        }
        &.list_title {
            width: 100%;
            background-color: #1e3560;
            font-weight: bold;
            font-size: 17px;
            color: #fff;
            pointer-events: none;
            position: sticky;
            top: 0;
        }
    }
    &::-webkit-scrollbar {
        width: 15px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
    }
    &::-webkit-scrollbar-track {
        background-color: #f2f2f2;
    }
}

.content {
    display: flex;
    justify-content: center;
    align-items: stretch;
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        flex: 1;
        border-right: 1px solid #9c9c9c;
        word-break: break-all;
        &:nth-last-of-type(1) {
            border: none;
        }
    }
}
</style>