<template>
    <footer class="consulting_footer">
        <p>
            Copyright ⓒ <span>S</span><span>PARTA</span>. All Rights Reserved
        </p>
    </footer>
</template>

<script>
export default {

}
</script>

<style scoped>

* {
    padding: 0;
    margin: 0;
}

footer {    
    font-weight: normal;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 10px;
    color: #6C6C6C;
    padding: 25px 0;
}

footer p span:nth-child(1) {
    color: #9d1d22;
    font-weight: bold;
}


footer p span:nth-child(2) {
    color: #1e3560;
    font-weight: bold;
}

</style>