<template>
    <div id="study_libPop">
        <div class="study_lib_in">
            <div class="study_header">
                <h1>
                    학습 자료 신청
                </h1>
                <select-list class="view_type" v-model:pSelectedData="subject.select" :pListItemArray="subject.array" :pIsScroll="true"/>
            </div>
            <div class="empty_div1 empty_div">
                &nbsp;
            </div>
            <AllTable v-model:study_data="study_data" :subject="subject" />
            <div class="empty_div2 empty_div">
                &nbsp;
            </div>
            <SelectedTable :study_data="study_data" />
            <div class="empty_div3 empty_div">
                &nbsp;
            </div>
            <div class="button_div">
                <input type="button" value="취 소" class="cancel" @click="close()">
                <input type="button" value="신 청" class="cancel" @click="ok()">
            </div>
        </div>
    </div>
</template>

<script>
import AllTable from "@/components/student/studylib/StudentStudyLibAllTable.vue"
import SelectedTable from "@/components/student/studylib/StudentStudyLibSelectedTable.vue"

export default {
    props : {
        attr : Object,
    },
    components : {
        AllTable,
        SelectedTable,
    },
    data () {
        return {
            subject : {
                array : [],
                select : {value: 'all' , description : '전체 보기'},
                default_value : '전체 보기',
            },
            study_data : {
                data : [],
                check_number : 0,
            }
        }
    },
    methods : {
        close() {
            this.$ModalManager.close(this)
        },
        ok() {
            this.$ModalManager.close(this,{selected_table : this.study_data})  
        },
    },
    created() {
        this.subject.array = this.attr.subject_array
        this.study_data.data = this.attr.study_lib_data
    }
}
</script>

<style scoped>
.view_type {
    width: 140px;
}
</style>

<style scoped>

#study_libPop {
    width: 936px;
    height: 705px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 106;
    background-color: #fff;
    border-radius: 15px;
    padding: 30px 0 30px 0;
    box-sizing: border-box;
    color: #6c6c6c;
}
.study_lib_in {
    width: 876px;
    margin: 0 auto;
}
.study_lib_in h1 {
    font-weight: bold;
    color: #1E3560;
    font-size: 24px;
}
.study_header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.empty_div {
    width: 850px;
    height: 30px;
    padding-right: 26px;
    box-sizing: border-box;
}
.empty_div1 {
    border-bottom: 1px solid #9c9c9c;
}
.empty_div2 {
    border-bottom: 1px solid #9c9c9c;
    border-top: 1px solid #9c9c9c;
}
.empty_div3 {
    border-top: 1px solid #9c9c9c;
}
.button_div {
    width: 370px;
    text-align: center;
    margin-top: 30px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.button_div input {
    width: 170px;
    height: 40px;
    border: none;
    border-radius: 20px;
    color: #fff;
    font-size: 16px; 
    cursor: pointer;
    background-color: #1E3560;
}
.button_div input:first-of-type {
    background-color: #9c9c9c;
}
</style>