import { createWebHistory, createRouter } from "vue-router";
import PageLogin from "@/pages/PageLogin";
import { ROLE } from "@/js/util/common.js";

import jwtService from "@/services/jwt.service.js";
import student from "./student.js";
import front from "./front.js";
import consulting from "./consulting.js";
import manager from "./manager.js";
import admin from "./admin.js";
import test from "./test.js";

import AppMain from "@/apps/AppMain";
import { SCHEDULE_API } from "@/services/exapi.service.js";

const routes = [
    {
        path: "/",
        name: "AppMain",
        component: AppMain,
    },
    {
        path: "/login",
        name: "PageLogin",
        component: PageLogin,
    },
    {
        path : "/selectcenter",
        name : "/selectcenter",
        component : ()=> import("@/components/admin/SelectCenter")
    },
    ...admin,
    ...test,
    ...front,
    ...student,
    ...consulting,
    ...manager,
    {
        path: "/:pathMatch(.*)*",
        component: () => import("@/pages/NotFound.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// router push 인터셉터
router.beforeEach((to, from, next) => {
	if(to.fullPath.startsWith("/test")) 
	{
		next();
		return;
	}
	
    if (jwtService.getAccessToken() && jwtService.getRefreshToken()) {
        const user_info = jwtService.getUserInfo();
        var user_role = user_info.role;

        if(user_info?.is_admin)
        // if(user_role == ROLE.COMPANYADMIN)
        {
            user_role = ROLE.ADMIN;
            if(jwtService.getCompanyAdminCenterInfo()?.no == -1)
            {
                if(to.fullPath.startsWith("/selectcenter"))
                {
                    next();
                    return;
                } else {
                    router.push("/selectcenter");
                    return;
                }
    
            }
        }
        if (user_info && (user_role || user_role === 0)) {
            // 다른 ROLE 추가시 해당 값 추가 필요.
            const check_route_data = [
                { route_data: student[0], role: ROLE.STUDENT },
                { route_data: consulting[0], role: ROLE.CONSULT_TEACHER },
                { route_data: front[0], role: ROLE.FRONT },
                { route_data: manager[0], role: ROLE.MANAGER },
                { route_data: admin[0], role: ROLE.ADMIN, 
                    // 경영T는 학생 제외 ROLE 화면 진입 가능.
                    except : [
                        ROLE.CONSULT_TEACHER,
                        ROLE.FRONT,
                        ROLE.MANAGER,
                    ]
                },
                // { route_data: admin[0], role: ROLE.COMPANYADMIN, 
                //     // company admin :  학생 제외 ROLE 화면 진입 가능.
                //     except : [
                //         ROLE.CONSULT_TEACHER,
                //         ROLE.FRONT,
                //         ROLE.MANAGER,
                //     ]
                // },
                // (이미 토큰이 있는 경우 login 화면 진입 금지.)
                {
                    route_data: {
                        children: [{ path: "/login" }, { path: "/" }],
                    },
                    role: 999,
                },
            ];
            const redirect_page = check_route_data.map((item) => 
                user_role === item.role ? item.route_data.redirect : "").join("");
            
            if(redirect_page == "")
            {
                next();
                return;
            }

            // 제외 ROLE 설정.
            let except_role = []
            check_route_data.forEach(item => {
                if(user_role === item.role && item.except) {
                    except_role = item.except
                }
            })
            
            // ROLE 체크
            check_route_data.forEach((item) => {
                const route_data = item.route_data;
                // 다른 ROLE 화면 진입
                if (user_role !== item.role) {
                    let role_verify_check = route_data.children.some(
                        (route_item) => to.path === route_item.path
                    );
                    // 제외 ROLE은 통과
                    if(except_role && except_role.some(except_item => item.role === except_item)) {
                        role_verify_check = false
                    }
                    if (role_verify_check) {
                        // ROLE 위반
                        router.push(redirect_page);
                        return;
                    }
                }
                // 같은 ROLE 화면 진입
                else {
                    switch (user_role) {
                        // 학생 입실 예외처리
                        case ROLE.STUDENT:
                            {
                                // 입실 되지 않을 경우 메인 화면 이외 진입 금지.
                                let enter_check = true;
                                const last_status = user_info?.login_result?.login_info?.last_status
                                const stay = (last_status == SCHEDULE_API.ACTION_TYPE.CHECKIN || last_status == SCHEDULE_API.ACTION_TYPE.COMEBACK)
                                if (last_status !== undefined && !stay) {
                                    route_data.children.some((route_item) => {
                                        if (
                                            route_item.path === to.path &&
                                            route_data.redirect !== to.path
                                        ) {
                                            enter_check = false;
                                        }
                                    });
                                }
                                if (!enter_check) {
                                    // 메인 화면으로 진입.
                                    router.push(redirect_page);
                                    return;
                                }
                            }
                            break;
                    }
                }
            });
        }
        // 다음 화면 이동 시 필요.
        next();
        return;
    } else if (to.path === "/login") {
        next();
        return;
    } else {
        // 토큰 체크
        router.push("/login");
        return;
    }
});

export default router;
