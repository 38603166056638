<template>
  <article class="notify_min">      
        <!-- <div style="background-color: rgba(0, 0, 0, 0.5); z-index:89; left:0;top:0; position:absolute; width:100%;height:100%;"></div> -->
        <div class="notify_min_header">
                <p>
                    실시간 체크사항
                </p>
                <await-button class="refresh" @click="this.onInit()"><i class="material-icons">sync</i></await-button>
        </div>
        <div class="notify_min_list02">
            <ul v-if="checkListData?.items?.length">
                <li v-for="item in checkListData?.items ?? []" :key="item">
                    <a @click="onClickItem(item)">
                        <dl>
                            <dt>
                                <div class="notice_title">
                                    {{ item.notify_title }}
                                </div>
                                <div class="notice_delete" >
                                    확인
                                </div>
                            </dt>
                            <dd class="lib_notice_btn">
                                {{ item.notify_message }}
                            </dd>
                        </dl>
                    </a>
                </li>
            </ul>
            <div v-else class="empty">
                실시간 체크사항이 없습니다.
            </div>
        </div><!--notify_min_list-->
        <button class="btn" v-if="this.setFront" @click="this.onAllTardy()">입실 일괄 허가</button>
    </article>
</template>

<script>
import jwtService from '@/services/jwt.service';
import { ApiReq } from '../ManagerUtils';

function setListener(createCallback, receiveCallback, clickCallback) {
    return {
        getMenu : createCallback,
        onReceive : receiveCallback,
        onClick : clickCallback
    }
}

export const CheckListMixin = {
    data() {
        return {
            src : null,
            wsMap : new Map(),
            checkListData : new CheckListData()
        };
    },
    created() {
    },
    mounted() {
        this.init();
        this.$WSSocket.AddMessageHandler("msg/", this.onMessageHandler);
        this.$EventManager.AddListener("check_list_init", this, this.init);
    },
    unmounted() {
        this.$WSSocket.RemoveMessageHandler("msg/", this.onMessageHandler);
        this.$EventManager.RemoveListener("check_list_init", this.init);
    },
    methods : {
        onMessageHandler(msg) {
            const cmd = msg.command;

            if(this.wsMap.has(cmd) || cmd == "msg/check_list_update") {
                this.init();
            }
        },
        async init() {
            if (!this.init_loading) this.init_loading = true;
            else return;

            const request_data = {
                cmd : Array.from(this.wsMap.keys())
            }
            await new ApiReq("/my/message/ws/latest", request_data)
                    .setResultCallback(data => {
                        if(!(data instanceof Array))
                            return false;
                        this.src = data;
                        this.checkListData.clear();
                        data.forEach(e => {
                            e.message = JSON.parse(e.message);
                            this.onReceive(e, false);
                        });
                    })
                    .get();

            this.$EventManager.EmitTo("get_temporal_list");
            this.init_loading = false;
        },
        addWSMap(key, onCreate, onReceive, onClick) {
            if(!key)
                return;

            this.wsMap?.set(
                key,
                setListener(onCreate, onReceive, onClick)
            );
            
        },
        async onClickCheckList(item) {
            let data = item.userData;
            let msg = data.message;

            let onClick = this.wsMap?.get(data.command)?.onClick;

            let ret = false;
            if(onClick) {
                ret = await onClick(msg?.data) ?? false;
            }

            if(ret) {
                this.checkListData.removeItem(item);
                this.$WSSocket.Send(
                        "msg/check_list_update", 
                        {refresh : true}, 
                        "__teacher/" + jwtService.getUserInfo().center_no + "/*"
                    )
            }
        },
        async onReceive(msg, processReceive = true) {
            const command = msg.command;
            const data = msg.message.data;

            let listener = this.wsMap?.get(command);

            if(listener) {
                const menu = listener.getMenu ? listener.getMenu(data) : null;

                if(menu) {
                    const addedItem = this.checkListData.addItem(menu.title, menu.msg, msg);

                    if(processReceive) {

                        let ret = false;
                        if(listener.onReceive) {
                            ret = await listener.onReceive(data) ?? false;
                        }

                        if(ret == true) {
                            this.checkListData.removeItem(addedItem);
                        }
                    }
                }
            }
        },
        
    }
}


export class CheckListItem {

    get key() {
        return this._key;
    }

    constructor(key, title, msg, data) {
        this._key = key;
        this.title = title;
        this.msg = msg;
        this.data = data;
    }
}

export class CheckListData {
    constructor() {
        this.items = new Array(0);
    }

    addItem(title, msg, userData) {
        let item = {
            notify_title : title,
            notify_message : msg,
            userData : userData
        };

        this.items.push(item);

        return item;
    }

    removeItemFromIndex(index) {
        this.items.splice(index, 1);
    }

    removeData(item) {        
        let index = this.items.findIndex(e => e.userData.no == item.userData.no);
        if(index != -1) {
            this.items.splice(index, 1);
        }
    }

    removeItem(item) {
        let index = this.items.indexOf(item);
        if(index != -1) {
            this.items.splice(index, 1);
        }
    }

    clear() {
        this.items.splice(0, this.items.length);
    }

}

export default {
    data() {
        return {
        };
    },
    props : {
        checkListData : Object,
        setFront : {
            type : Boolean,
            default : false,
        },
    },
    emits : [
        "clickItem"
    ],
    methods : {
        onClickItem(item) {
            this.$emit("clickItem", item);
        },
        onInit(){
            this.$EventManager.EmitTo("check_list_init");
        },
        onAllTardy(){
            this.$EventManager.EmitTo("all_permission");
        }
    }
}
</script>

<style scoped>

* {
    padding: 0;
    margin: 0;
    text-align: initial;
}


.notify_min {
    width: 273px;
    /* height: 320px; */
    background-color: #fff;
    position: relative;
    border-radius: 15px;
    padding: 10px 20px;
    box-sizing: border-box;
    margin-top: 10px;
}

.btn {
    padding:5px 10px;
    border-radius: 20px;
    border: none;
    color: #fff;
    font-size: 14px;
    background-color: #1e3560;
    text-align: center;
}

.notify_min_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notify_min_header button{
    margin-right:0;
    text-align: center;
}

.notify_min_header p{
    font-size: 18px;
    font-weight: bold;
    color: #1E3560; 
    padding: 0;
    margin: 0;
    padding-left: 0px;
    /* padding-bottom: 15px; */
    text-align: flex-start;
}

.notify_min_list02 {    
    width: 233px;
    height: 255px;
    overflow-Y: scroll;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 10;
    margin:5px 0;
}

.notify_min_list02 ul {
    margin-right: 9px;
    font-size: 16px;
}

.notify_min_list02 > ul > li dl {
    border-bottom: 1px solid #707070;
}

.notify_min_list02 > ul > li dl dt {
    font-size: 14px;
    margin-bottom: 10px;
    padding-top: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.notice_title {
    font-size: 14px;
    font-weight: bold;
    color: #6C6C6C;
}

.notice_delete {
    font-size: 11px;
    width: 34px;
    height: 16px;
    border-radius: 50px;
    background-color: #1E3560;
    color: #fff;
    line-height: 16px;
    text-align: center;
    padding: 2px;
}

.lib_notice_btn {
    font-size: 16px;
    margin-bottom: 15px;
    color: #6C6C6C;
    text-align: flex-start;
}

.empty {
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 16px;
    color: #6c6c6c;
}

</style>