<template>
    <div class="vip_studentPop">
        <h2>VIP 학생 등록 사유 입력</h2>
        <div class="main_table" v-bind:class="this.res_data.length > 7 ? 'set_scroll' : ''">
            <table>
                <tr class="t_header">
                    <th colspan="2">이름</th>
                    <th colspan="2">학생코드</th>
                    <th colspan="2">학교</th>
                    <th>학년</th>
                    <th>계열</th>
                    <th colspan="5">VIP 학생 등록 사유</th>
                </tr>
                <tr v-for="student in this.res_data" :key="student.id">
                    <td colspan="2" class="name_bold">{{ student.name ?? "-" }}</td>
                    <td colspan="2">{{ student.id ?? "-" }}</td>
                    <td colspan="2">{{ student.school_name ?? "-" }}</td>
                    <td>{{ gradeValue(student.grade) }}</td>
                    <td>{{ this.major_str(student) }}</td>
                    <td colspan="5" class="main_td">
                        <input type="text" placeholder="내용을 입력해주세요" v-model="student.description" />
                    </td>
                </tr>
            </table>
        </div>
        <div>
            <input type="button" value="취 소" class="cancle" v-on:click="cancel()" />
            <input type="button" value="입 력" v-on:click="submit()" />
        </div>
    </div>
</template>

<script>
import CommonGrade from "@/components/common/CommonGrade";
import CommonData from "@/components/common/data/CommonData";
export default {
    name: "FrontVipInput",
    emits: ["save"],
    mixins: [CommonGrade],
    props: {
        selected_student: Array,
    },
    data() {
        return {
            res_data: [],
        };
    },
    methods: {
        cancel: function () {
            this.$ModalManager.close(this);
        },
        submit: function () {
            this.$emit("save", this.res_data);
        },
        major_str(student) {
            return CommonData.major_type[student.major] ?? "-";
        },
    },
    created: function () {
        this.res_data = this.selected_student.map((e) => {
            return {
                id: e.id ?? e.student_id,
                name: e.name,
                school_name: e.school_name,
                grade: e.grade,
                major: e.major,
                description: "",
            };
        });
    },
};
</script>

<style lang="scss" scoped>
$border-color: #9c9c9c;
$back-color: #f2f2f2;

@mixin title_text($font-size) {
    font-size: $font-size;
    font-weight: bold;
    color: var(--color-sparta);
}
@mixin font_hide() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@mixin font_visible() {
    overflow: visible;
    text-overflow: clip;
    white-space: pre-wrap;
    word-wrap: break-word;
}
.vip_studentPop {
    background-color: #fff;
    width: 1200px;
    border-radius: 20px;
    color: #6c6c6c;
    padding: 20px;
    box-sizing: border-box;
    h2 {
        font-size: 24px;
        color: #1e3560;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .set_scroll {
        height: 350px;
        overflow-x: none;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            height: 16px;
            width: 16px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #d9d9d9;
        }
        &::-webkit-scrollbar-track {
            background-color: #f2f2f2;
        }
    }
    > div:nth-of-type(2) {
        margin-top: 20px;
        input {
            width: 170px;
            height: 40px;
            color: #fff;
            border-radius: 20px;
            border: none;
            font-size: 16px;
            font-family: "nanum";
            cursor: pointer;
            &:nth-child(1) {
                background-color: #9c9c9c;
                margin-right: 20px;
            }
            &:nth-child(2) {
                background-color: #1e3560;
            }
        }
    }
}

.main_table {
    text-align: center;
    > table {
        margin-right: 10px;
        width: calc(100% - 10px);
        border-collapse: separate;
        border-spacing: 0;
        table-layout: fixed;
        .t_header {
            position: sticky;
            top: 0;
            background-color: $back-color;
        }
        th {
            @include title_text(17px);
            border-top: 1px solid $border-color;
        }
        td,
        th {
            @include font_hide();
            height: 30px;
            padding: 5px;
            border-bottom: 1px solid $border-color;
        }
        .main_td {
            text-align: left;
            @include font_visible();
        }
    }
    input {
        width: 100%;
        height: 26px;
        border-radius: 50px;
        border: 1px solid #9c9c9c;
        outline: none;
        text-align: left;
        font-size: 16px;
        color: #6c6c6c;
        padding-left: 15px;
        box-sizing: border-box;
    }
}
.name_bold {
    @include title_text(16px);
}
.search_matched .name_bold {
    color: #fff;
}
.cursor {
    cursor: pointer;
}
</style>