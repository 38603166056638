import ToastMsg from "@/components/front/ToastMsg.vue"

// msg = {
//     title: String,       // = null : "제목없음"
//     content: String,     // = null : "내용없음"
//     call: {              // = null : 버튼없음
//         btnStr: String,  // = null : "상세"
//         callBack: () => { alert("토스트 메시지 버튼") },
//     },
// };

//  this.$ToastManager.push(msg);

const ToastManager = {
    messages: [],
    TIMER: null,
    base: null,
    no: 0,
    visible_count: 7,
    visible_time: 10,
    async push(msg) {
        msg.no = ToastManager.no;
        ToastManager.no += 1;
        ToastManager.messages.push(msg);
        ToastManager.msg_flush();
    },
    async msg_flush() {
        if (ToastManager.messages.length) {
            if (ToastManager.base.get_msg_length() < ToastManager.visible_count) {
                let msg = ToastManager.messages.shift();
                ToastManager.base.add(msg);
                ToastManager.TIMER = setTimeout(() => { ToastManager.base.del(msg); }, 1000 * ToastManager.visible_time);
            } else {
                ToastManager.TIMER = setTimeout(ToastManager.msg_flush, 1000 * ToastManager.visible_time / 2);
            }
        }
    },
    clear() {
        for (let i = 0; i <= ToastManager.TIMER; i++) clearTimeout(i);
        ToastManager.messages = [];
        ToastManager.base.clear();
        ToastManager.no = 0;
        ToastManager.TIMER = null;
    },
    registerBase(base) {
        if (!ToastManager.base) ToastManager.base = base;

    },
    install(vue, options = {}) {
        this.messages = [];
        vue.component('ToastMsg', ToastMsg);
        vue.config.globalProperties.$ToastManager = {
            push: ToastManager.push,
            clear: ToastManager.clear,
            registerBase: ToastManager.registerBase,
        }
    }
}
export default ToastManager;