<template>
    <div class="header_alarmbar" style="position : relative;">
        <div class="header_alarmbar_logo" @click.stop="onClickAlarm">
            <div class="bell_logo">
                <div id="bell_root">
                    <img id="bell" src="@/assets/images/bell.png">
                    <img v-if="isNewAlert" id="bell_red" class="new_alert" src="@/assets/images/bell_red.png">
                </div>
            </div>
            <div>
                <p>
                    긴급알림
                </p>
            </div>
        </div>
        <div class="header_alarmbar_input">            
            <input type="text" placeholder="내용을 입력해주세요" v-model="message" @keyup.enter="onClickRequest">
        </div>
        <div id="but_container">
            <div class="roundbutton_small" >
                <a @click="onClickRequest">
                    요청
                </a>
            </div>
            <div v-if="0" class="roundbutton_small">
                <a @click="onClickCall">
                    호출
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { useMessageStore } from "@/store/useMessageStore";
import { ApiReq, Utils } from "../manager/ManagerUtils";
import AlarmHistoryModalVue from "./AlarmHistoryModal.vue";
import TopHeaderAlarmRequestModalVue from "./TopHeaderAlarmRequestModal.vue";

export default ({
    name : "TopHearAlarmBar",
    setup() {
        const store = useMessageStore();
        return { store }
    },
    data() {
        return {
            src : null,
            message : "",
        };
    },
    computed : {
        isNewAlert() {
            return (this.store.message.important?.length ?? 0) > 0;
        }
    },
    methods : {
        async onClickRequest() {
            if(this.message.length <= 0) {
                Utils.showErrorModal("내용을 입력해주세요.");
                return;
            }

            let ret = await this.$ModalManager.showEx(TopHeaderAlarmRequestModalVue, {
                bind : {
                    message : this.message
                }
            });

            if(ret == true) {
                this.message = "";
            }
        },
        onClickAlarm() {
            this.$ModalManager.showEx(AlarmHistoryModalVue);
        }
    }
})
</script>

<style lang="scss" scoped>

.header_alarmbar_red {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    left: 0;
    background: rgba(203, 50, 8, 0.333);
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    box-sizing: border-box;
    position : absolute;
    cursor: pointer;
}
.header_alarmbar {
    width: 700px;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    box-sizing: border-box;
}
.header_alarmbar_logo {
    display: flex;
    align-items: center;
    width: 78px;
    height: 19px;
    font-size: 16px;
    color: #6c6c6c;
    cursor: pointer;
    user-select: none;
}
.bell_logo {
    width: 15px;
    height: 19px;
    margin-right: 3px;

}

.header_alarmbar_input {
    margin-right: 11px;
    margin-left: 20px;
}

.header_alarmbar_input input {
    width: 430px;
    height: 19px;
    border: none;
    color: #6c6c6c;
    font-size: 16px;

}
.header_alarmbar_input input:focus {
    outline: none;
}
.header_alarmbar_input input::placeholder {
    font-size: 16px;
    color: #9c9c9c;
}




div#but_container {
    margin-left : auto;
    display: flex;
    text-align: center;
}

div#but_container > div:nth-child(1) {
    background-color: #1E3560;
    margin-right: 10px;
}
div#but_container > div:nth-child(2) {
    background-color: #9D1D22;
}


#bell_root {
    width: fit-content;
    height: fit-content;

    #bell{
        position: absolute;
    }
    #bell_red {
        position: absolute;
    }
}


@keyframes newAlert {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.new_alert {
    animation-name: newAlert;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
}

</style>
