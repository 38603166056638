<template>
    <div id="noticePopup">
        <div class="notice_header">
            <dl>
                <dd v-if="this.work_flag">업무 전달 - {{ this.notice_data?.type_name ?? "-" }}</dd>
                <dd v-else>공지 및 전달사항 - {{ this.notice_data?.type_name ?? "-" }}</dd>
                <dt :title="this.notice_data?.title ?? '-'">{{ this.notice_data?.title ?? "-" }}</dt>
            </dl>
        </div>
        <!--notice_header-->
        <div class="notice_main">
            <div class="notice_from">
                <div>
                    <div class="notice_sender">보낸 사람</div>
                    <div class="notice_sender_name">
                        <ul>
                            <li>{{ this.notice_data?.creator_name ?? "-" }}</li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div class="notice_to">받는 사람</div>
                    <div class="notice_to_name">
                        <div v-for="(value, key) in this.recievers" :key="key">
                            <div class="text_overflow">{{ value.name }}</div>
                            <div>
                                <div class="text_overflow" v-for="recipient in value.recipients" :key="recipient.teacher_id">
                                    <template v-if="this.work_flag"> {{ this.getString(recipient) }} </template>
                                    <template v-else> {{ this.getString(recipient) }}</template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--notice_to_name-->
                </div>
            </div>
            <!--notice_from-->
            <div class="notice_borad">
                <p>
                    {{ this.notice_data.content }}
                </p>
            </div>
        </div>
        <!--notice_main-->
        <div class="notice_filedown">
            <div>
                <p>첨부 파일</p>
            </div>
            <div v-html="this.createDownload(this.notice_data)"></div>
        </div>
        <div class="notice_cheak" @click="this.close">확인</div>
    </div>
</template>

<script>
import { ApiReq } from "@/components/manager/ManagerUtils";
import CommonData from "@/components/common/data/CommonData";
export default {
    name: "NoticeModal",
    props: {
        notice_data: Object,
        work_flag: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            centers: [],
        };
    },
    methods: {
        createDownload(noti) {
            if (noti.file) {
                let url = "";
                if (process.env.VUE_APP_ISTEST) url = "http://spartatest.exadynetech.com";
                return "<a href='" + url + noti.file + "' download='" + noti.title + "' target='_blank'>다운로드</a>";
            } else {
                return "<div>-</div>";
            }
        },
        close() {
            this.$ModalManager.close(this);
        },
        getString(recipient) {
            let progress = null;
            if (this.work_flag) progress = recipient.progress_status_name ?? "진행여부 확인 필요";
            const status = recipient.status == CommonData.notice_status.none ? "미수신" : "수신";

            let str = (recipient.teacher_name ?? "") + " " + (recipient.position ?? "") + " (" + status;
            if (progress) str += "/" + progress + ")";
            else str += ")";

            return str;
        },
    },
    computed: {
        recievers() {
            let ret = this.notice_data.recipients.reduce((acc_obj, cur) => {
                const center = this.centers.find((e) => e.no === cur.center_no);
                if (center) {
                    if (!acc_obj?.[center.no]) acc_obj[center.no] = { name: center.name, recipients: [] };
                    acc_obj[center.no].recipients.push(cur);
                }
                return acc_obj;
            }, {});
            return ret;
        },
    },
    created() {
        new ApiReq("/centers")
            .setResultCallback((res) => {
                this.centers = res;
                return res instanceof Array;
            })
            .get();
    },
};
</script>

<style lang="scss" scoped>
#noticePopup {
    background-color: rgb(255, 255, 255);
    border-radius: 25px;
    box-sizing: border-box;
    width: 700px;
    padding: 30px;
    user-select: none;
    .notice_header {
        text-align: left;
        height: 61px;
        padding-bottom: 20px;
        border-bottom: 1px solid #9c9c9c;
        dl {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 100%;
            color: #1e3560;
            dd {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 15px;
            }
            dt {
                font-size: 24px;
                font-weight: bold;

                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .notice_main {
        margin-top: 20px;
        .notice_from {
            font-size: 16px;

            > div {
                display: flex;
            }
            > div:first-child {
                margin-bottom: 15px;
            }
            .notice_sender,
            .notice_to {
                font-weight: bold;
                color: #1e3560;
                width: 100px;
                text-align: left;
            }
            .notice_sender_name,
            .notice_to_name {
                color: #6c6c6c;
            }

            .notice_to_name {
                width: calc(100% - 100px);
                max-height: 200px;
                overflow-y: scroll;
                overflow-x: none;
                padding-right: 5px;
                > div {
                    display: flex;
                    justify-content: space-between;
                    text-align: left;
                    border-bottom: 1px solid #9c9c9c;
                    padding: 5px 0 5px 0;
                    > div {
                        &:nth-of-type(1) {
                            width: 24%;
                            border-right: 1px solid #9c9c9c;
                        }
                        &:nth-of-type(2) {
                            padding-left: 5px;
                            width: 75%;
                            > div {
                                margin-bottom: 10px;
                                &:nth-last-of-type(1) {
                                    margin: 0;
                                }
                            }
                        }
                    }
                    &:nth-of-type(1) {
                        padding-top: 0px;
                    }
                    &:nth-last-of-type(1) {
                        border-bottom: 0;
                    }
                }
            }
        }

        .text_overflow {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .notice_borad {
            width: 100%;
            height: 300px;
            border: 1px solid #9c9c9c;
            margin-top: 20px;
            box-sizing: border-box;
            text-align: left;
            overflow-y: auto;

            p {
                margin: 15px;
                color: #6c6c6c;
                white-space: pre-line;
                line-height: 24px;
                font-size: 18px;
            }
        }

        div::-webkit-scrollbar {
            width: 8px;
        }
        div::-webkit-scrollbar-thumb {
            background-color: #d9d9d9;
        }
        div::-webkit-scrollbar-track {
            background-color: #f2f2f2;
        }
    }
    .notice_filedown {
        display: flex;
        margin: 20px 0;
        color: #6c6c6c;
        > div:nth-child(1) p {
            width: 100px;
            margin-right: 20px;
            color: #1e3560;
            font-weight: bold;
            text-align: left;
        }
        > div:nth-child(2) {
            width: calc(100% - 110px);
            text-align: left;
        }
    }
    .notice_cheak {
        width: 170px;
        height: 40px;
        margin: 0 auto;
        text-align: center;
        border-radius: 20px;
        background-color: #1e3560;
        color: #fff;
        font-size: 16px;
        line-height: 40px;
        cursor: pointer;
    }
}
</style>