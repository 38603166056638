<template>
<div id="root">
    <div id="write_contents">
        <div class="write_main">
            <div class="main_left">
                <div class="left_header">
                    <div class="title">받는 사람</div>
                </div>
                <div class="header">
                    <search-bar style="width: 200px" :placeholder_text="'이름 검색'" @search="(s) => (this.search_keyword = s)" />
                    <div class="header_btn">
                        <input type="button" value="선택해제" @click="check_all(false)" />
                        <input type="button" value="전체선택" @click="check_all(true)" />
                    </div> 
                </div>
                

                <div class="left_content" >
                    <div>
                        <label class="item" v-for="(teacher, idx) in searchList" :key="idx" :class="{ search_matched: teacher.checked }">
                            <input type="checkbox" v-model="teacher.checked" />
                            <div>{{ teacher?.name }} {{ teacher?.position }}</div>
                        </label>
                    </div>
                </div>
            </div>
            <div id="buttons">
                <input type="button" value="취 소" @click="onClose" />
                <input type="submit" value="요 청" @click="submit" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { ApiReq, defaultPageParam, Utils } from "@/components/manager/ManagerUtils";
import jwtService from '@/services/jwt.service';
import ManagerBasePopupVue from '../manager/ManagerBasePopup.vue';

export default {
    mixins : [ManagerBasePopupVue],
    components: {
    },
    props : {
        message : String
    },
    data() {
        return {
            src : null,
            teachers : null,
            search_keyword : ""
        };
    },
    methods: {
        check_all(b) {
            this.searchList?.forEach(element => {
                element.checked = b;
            });
        },
        selected(teacher) {
            if (this.selected_receviers.find((e) => e?.id === teacher.id)) {
                return true;
            }
        },
        async submit() {
            let receivers = this.teachers?.reduce((prev, element) => {
                if(element.checked == true) {
                    prev += "," + element.id;
                }
                return prev;
            }, "");

            receivers = receivers.slice(1, receivers.length);

            if (!receivers.length) {
                this.$ModalManager.showdialog("받는 사람을 선택해주세요.");
                return;
            }    

            const data = {
                recipients : receivers,
                content : this.message
            };
            let ret = await new ApiReq("/call/important", data)
                .setCompleteMsg("긴급알림을 전달했습니다.")
                .setFailMsg("긴급알림 전달에 실패했습니다.")
                .post();

            if(ret == true) {
                this.onResultOk();
            }
            
        },
        async init() {
            const center_no = jwtService.getUserInfo()?.center_no;
            const id = jwtService.getUserInfo()?.userid;

            if(center_no) {
                new ApiReq("/teachers", defaultPageParam({ctr:center_no, status:0}))
                    .setResultCallback(data =>{                        
                        this.teachers = data?.filter(e => e.id != id);
                    })
                    .setFailMsg("선생님 목록을 가져오는데 실패했습니다.")
                    .get();
            } else {
                Utils.showErrorModal('센터 정보가 없습니다.');
            }
        },
    },
    computed: {
        searchList() {
            return this.teachers?.filter(e => {
                return e.name.includes(this.search_keyword);
            }) ?? [];
        }
    },
    activated() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>

#root {

    width: 440px;
    height: 696px;
    background: white;
    border-radius: 20px;
    padding: 20px;
}

#write_contents {
    width: 100%;
    height: 100%;
    .title {
        font-weight: bold;
        color: #1e3650;
        font-size: 24px;
        text-align: left;
    }
    .write_main {
        justify-content: space-between;
        padding-top: 10px;
        width: 100%;
        height: calc(100% - 48px);
        .title {
            font-size: 24px;
            font-weight: bold;
            color: #1e3560;
            text-align: left;
        }

        .main_left {
            width: 100%;
            flex-direction: column;

            .left_header {
                display: flex;
                > div {
                    flex: 1;
                }
                
            }
            .left_content {
                display: flex;
                height: 500px;
                margin-top: 10px;
                > div {
                    overflow-y: scroll;
                    overflow-x: hidden;
                    flex: 1;
                    background: #f2f2f2;
                    // .highlight{
                    //     background-color:
                    // }
                    .item {
                        display: flex;
                        align-items: center;
                        height: 20px;
                        padding: 10px;

                        cursor: pointer;
                        > div {
                            font-size: 16px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        input {
                            flex: none;
                            cursor: pointer;
                            outline: none;
                        }
                        input[type="radio"] {
                            width: 16px;
                            height: 16px;
                            appearance: none;
                            outline: none;
                            border-radius: 100%;
                            border: 1px solid #9c9c9c;
                            box-sizing: border-box;
                            margin-right: 5px;
                        }
                        input[type="radio"]:checked {
                            position: relative;
                            border: 1px solid #fff;
                        }
                        input[type="radio"]:checked::before {
                            position: absolute;
                            content: "";
                            width: 10px;
                            height: 10px;
                            background-color: #fff;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            border-radius: 100%;
                            box-sizing: border-box;
                        }
                        input[type="checkbox"] {
                            width: 15px;
                            height: 15px;
                            margin-right: 5px;
                        }
                    }
                }
                > div::-webkit-scrollbar {
                    width: 10px;
                }
                > div::-webkit-scrollbar-thumb {
                    background-color: #d9d9d9;
                }
                > div::-webkit-scrollbar-track {
                    background-color: #9c9c9c;
                }
            }
        }
    }
}

.header_btn {
    // justify-content: center;
    // align-items: center;
    text-align: end;
    margin-left: auto;
    padding-right: 10px;

    input:nth-child(2) {
        margin-left: 10px;
    }
    input {
        width: 75px;
        height: 26px;
        border-radius: 50px;
        border: 1px solid #9c9c9c;
        color: #6c6c6c;
        background: white;
        text-align: center;
        font-size: 15px;
        cursor: pointer;
    }
    // right:0;
}

#buttons {
    margin-top: 35px;

    input {
        width: 170px;
        height: 40px;
        border-radius: 20px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        border: none;
        font-size: 17px;
        cursor: pointer;
        font-weight: bold;
    }

    input[type="button"] {
        background-color: #9c9c9c;
        margin-right: 20px;
    }

    input[type="submit"] {
        background-color: #1e3560;
    }
}

.header {
    display: flex;
    align-items: center;
    padding-top: 20px;
}

#searcharea {
    margin-left: 0;
}

</style>