<template>
    <div class="study_data_table">
        <div>
            <div class="" v-for="(title, idx) in titles" :key="idx" :value="idx" :class="'same_row0'+(idx+1)">{{title}}</div>
        </div>
        <ul>
            <li v-for="(data, idx) in p_study_data.data" :key="idx" :class="{checked : data.check}" 
            v-show="subject.select.value ==='all' || subject.select.description === data.category">
                <div class="same_row01">{{data.category}}</div>
                <div class="same_row02">{{data.name}}</div>
                <div class="same_row03">{{pay_to_string(data.price)}}</div>
                <div class="same_row04">{{String.fromCharCode((data.security - 1) + "A".charCodeAt(0))}}</div>
                <div class="same_row05">
                    <input type="checkbox" v-model="data.check">
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import {pay_to_string} from "@/js/util/math"

export default {
    props : {
        study_data : Object,
        subject : Object,
    },
    data() {
        return {
            titles : ['과목','자료명','금액','보안','선택'],
            p_study_data : {},
        }
    },
    methods : {
        check_study_data(value) {
            // click 이벤트 선 동작후 v-model 로 데이터 변경됨. 
            // => 체크시 false , 체크 해제시 true.
            if(value) {
                this.p_study_data.check_number--
            }
            else {
                this.p_study_data.check_number++
            }
        },
        pay_to_string(value) {
            return pay_to_string(value)
        },
    },
    watch : {
        study_data(newv, oldv) {
            this.p_study_data = this.study_data
        },
        "p_study_data.data" : {
            handler : function(value) {
                this.p_study_data.check_number = this.p_study_data.data.map(item => item.check ? "1" : "").join("").length
            }, deep : true
        }
    },
    created () {
        this.p_study_data = this.study_data
    }
}
</script>

<style scoped>
.study_data_table {
    width: 876px;
    height:300px;
    overflow-y: scroll;
    padding-right: 10px;
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;
}
.study_data_table::-webkit-scrollbar {
    width: 16px;
}
.study_data_table::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
}
.study_data_table::-webkit-scrollbar-track {
    background-color: #f2f2f2;
}
.study_data_table > div {
    display: flex;
    align-items: center;
    text-align: center;
    height: 41px;
    background-color: #f2f2f2;
    border-bottom: 1px solid #9c9c9c;
    position: sticky;
    transform:translateY(-1px);
    top: 0px;
    font-weight: bold;
    color: #1E3560;
}
.study_data_table > div .same_row02 {
    text-align: center;
}
.study_data_table > ul > li{
    display: flex;
    align-items: center;
    text-align: center;
    height: 39px;
    border-bottom: 1px solid #9c9c9c;
}
.study_data_table > ul > li input {
    cursor: pointer;
}
.study_data_table > ul li:last-child {
    border: none;
}
.same_row01,
.same_row03 {
    width: 100px;
}
.same_row02 {
    width: 500px;
    text-align: left;
}
.same_row04 {
    width: 60px;
}
.same_row05 {
    width: 90px;
}
.same_row06 {
    width: 150px;
}
.study_data_table .checked {
    background-color: #F7FCFF
}
</style>