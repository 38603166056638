import axios from "axios";
import router from "@/router"
import JwtService from "./jwt.service";
import { CommonUtils, ROLE } from "@/js/util/common";

const ApiService = {
    GET : 0, POST : 1, POSTWITHOUTAUTH : 2, PUT : 3, DELETE : 4, POSTFILE : 5,
    useProxy : false,
    ApiBaseUrl : "http://localhost:8080/api",
    call_structure : {
        is_refresh : false,
        queue : [],
    },
    VueApp : null,

    init() {
        if(!this.useProxy)
            this.ApiBaseUrl = "/api";

        if(!window.sparta_api_call_structure)
            this.call_structure;
        else
            this.call_structure = window.sparta_api_call_structure;
    },

    set_vue_app(app) {
        if(app)
            this.VueApp = app;
    },

    build_axio_config()
    {
        var super_role = -1
        const user_info = JwtService.getUserInfo()
        if(user_info?.is_admin) {
        // if(user_info?.role == ROLE.ADMIN || user_info?.role == ROLE.COMPANYADMIN) {
            super_role = CommonUtils.get_current_page_role(this.VueApp, user_info.role)
        }
        return {
            header : {
                "Content-Type": 'application/json',
                "Accept" : 'application/json',
                "Authorization": 'Bearer ' + JwtService.getAccessToken(),
                "super-center-no" : JwtService.getCompanyAdminCenterInfo().no,
                "super-role" : super_role,
            }
        }
    },

    getaxios(headers)  //for proxy
    {
        if(!this.useProxy)
            this.ApiBaseUrl = "/api";

        if(headers == undefined)
            headers = this.build_axio_config().header;
        
        const ax = axios.create(
            {
                baseURL : this.ApiBaseUrl,
                headers : headers,
            }
        );

        return ax;
    },

    flush_calls()
    {
        while(this.call_structure.queue.length > 0)
        {
            const i = this.call_structure.queue[0];
            this.call(i.method, i.path, i.params, i.config, i.resolve, i.reject, true);
            this.call_structure.queue.splice(0, 1);
       }
    },

    call(method, path, params, config, qresolve, qreject, is_flush = false)
    {
        // console.log(path);
        const _this = this;
        if(this.call_structure.is_refresh)
            return new Promise((resolve, reject)=>{
                if(qresolve != undefined) resolve = qresolve;
                if(qreject != undefined) reject = qreject;
                
                if(method == this.GET && !is_flush && params) {
                    params = {params : params};
                }
                _this.call_structure.queue.push({
                    method : method,
                    path : path,
                    params : params,
                    config : config,
                    resolve : resolve, 
                    reject : reject
                });

            });
        else
        {
            var method_func = null;
            if(config != undefined && method != this.POSTFILE) config = _this.build_axio_config();
            switch(method)
            {
                case this.POST : method_func =              _this.getaxios().post;break;
                case this.POSTWITHOUTAUTH : method_func =   _this.getaxios().post; config = undefined; break;
                case this.GET : 
                    method_func = _this.getaxios().get;
                    if(!is_flush && params) {
                        params = {params : params};
                    }
                    break;
                case this.PUT : method_func =               _this.getaxios().put;break;
                case this.DELETE : method_func =            _this.getaxios().delete;break;
                case this.POSTFILE : method_func =          _this.getaxios({
                        "Authorization" : "Bearer " + JwtService.getAccessToken(),
                        "Content-Type": 'multipart/form-data',
                        "Accept" : 'multipart/form-data',
                    }).post; break;
            }

            if(method_func == null) return Promise.reject("method is not defined");

            return new Promise((resolve, reject)=>{
                if(qresolve != undefined) resolve = qresolve;
                if(qreject != undefined) reject = qreject;
                method_func(path, params, config)
                .then((res)=>
                {
                    resolve(res);
                })
                .catch((error)=>
                {
                    if(error.response.status == 401){

                        _this.call_structure.queue.push({
                            method : method,
                            path : path,
                            params : params,
                            config : config,
                            resolve : resolve, 
                            reject : reject
                        });

                        if(_this.call_structure.is_refresh == false)
                        {
                            _this.call_structure.is_refresh = true;
                            _this.refresh_token()
                            .then((res)=>{
                                _this.call_structure.is_refresh = false;
                                _this.flush_calls();
    
                            })
                            .catch((err)=>{
                                _this.call_structure.queue = [];
                                _this.call_structure.is_refresh = false;
                                JwtService.deleteAllToken();
                                router.push("/login");
                                // reject(error);
                            });
                        }

                    } else {
                        reject(error);
                    }
                })
            });
        }
    },
      
    refresh_token()
    {
        return new Promise((resolve, reject) => {
            const req = {
                access_token : JwtService.getAccessToken(),
                refresh_token : JwtService.getRefreshToken(),
            };

            // console.log(req)
            this.getaxios().post("/refresh", req)
            .then((res) => {
                if(res.data.result == false)
                {
                    console.error("accesstoken_expired", "reject");
                    return reject();
                }
                else
                {
                    // console.log(res);
                    console.log("accesstoken_expired", "resolve");
                    // console.log(res.data)
                    JwtService.setAccessToken(res.data.access_token);
                    JwtService.setRefreshToken(res.data.refresh_token);
                    return resolve(res);
                }

            })
            .catch((error) => {
                return reject();
            });
        });
    }

}

export default ApiService;

