<template>
    <div id="app_cancelPop" @keydown.esc="on_key_esc">
        <div class="app_cancel_in">
            <div v-if="attr.title" class="titlemsg">
                <h1 v-html="attr.title">
                </h1>
                <p id="msg" v-html="attr.msg">
                </p>
            </div>
            <p v-else v-html="attr.msg">
            </p>
            <div :class="[dbuttons.length > 1 ? 'multi_buttons' : 'single_buttons']">
                <button v-for="(button, idx) in dbuttons" :key="idx" :class="button.id" @click="on_button_click(button.id)">
                    {{button.buttext}}
                </button>
            </div>
        </div>
        <div id='keyeventreceiver'>
            <input id="keyeventreceiver" ref="keyeventreceiver" type="text" @keydown.esc="on_key_esc"/>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        attr : Object,
    },

    create() {

    },

    mounted()
    {
        var pbuttons = this.attr.buttons;
        var dbuttons = [];
        this.dmsg = this.attr.msg;

        ["no", "cancel", "yes", "ok"].forEach((key)=>{
            if(pbuttons[key] != undefined)
            {
                var but = pbuttons[key];
                if(but == "")
                    switch(key)
                    {
                        case "yes" : but = "예"; break;
                        case "no" : but = "아니오"; break;
                        case "cancel" : but = "취소"; break;
                        case "ok" : but = "확인"; break;
                    }
                dbuttons.push({id : key, buttext : but});
            }
            else 
            {
                //console.log(key, pbuttons[key]);
            }
        });
        
        this.dbuttons = dbuttons;
        this.$refs.keyeventreceiver.focus();

    },

    data() {
        return {
            dbuttons : [],
            dmsg : '',
        }
    },
    methods : {
        on_button_click(key)
        {
            this.$ModalManager.close(this, {result : key})
        },
        on_key_esc()
        {
            this.$ModalManager.close(this, "nothing");
        }
    }
}
</script>

<style scoped>

#app_cancelPop {
    min-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 500px; */
    /* height: fit-content; */
    /* position: fixed; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    z-index: 106;
    background-color: #fff;
    border-radius: 15px;
    padding: 55px 68px 30px 68px;
    box-sizing: border-box;
    color: #6c6c6c;
    text-align: center;
}
.app_cancel_in p {
    font-weight: bold;
    color: #1E3560;
    margin-bottom: 55px;
    font-size: 18px;
}

h1 {
    font-size: 18px;
    font-weight: bold;
    color: #1E3560;
    margin-bottom: 11px;
}
div.titlemsg > p {
    font-weight : normal;
    font-size: 16px;
    margin-bottom: 30px;
}

.app_cancel_in > div {
    /* width: 364px; */
    height: 40px;
    margin: 0 auto;
    display: flex;
}

.app_cancel_in > div.titlemsg {
    display: inline;
}

.single_buttons {
    justify-content: center;
}
.multi_buttons {
    justify-content: space-between;
}
.multi_buttons > button{
    margin-right:10px;
}
.multi_buttons > button:nth-last-of-type(1){
    margin:0;
}
button {
    width: 170px;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    border: none;
}

button.no, button.cancel {
    background-color: #9c9c9c;
}
button.yes, button.ok {
    background-color: #1E3560;
}

div#keyeventreceiver {
    position: absolute;
    top : 5000px;
}

input#keyeventreceiver {
    /* visibility: hidden; */
}
</style>