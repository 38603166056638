
import CommonData from "@/components/common/data/CommonData";
import CommonGrade from "@/components/common/CommonGrade"
import { date_format } from "@/js/util/math";
import SPARTA_TIME from "@/components/common/student_week_schedule/js/sparta_time";

export default {
    //status : 0 - 상담, 1 - 등원대기, 2 - 재원, 3 - 쿠폰, 4 - 홀드, 9 - 퇴원
    STUDENT_TYPE : {
        CONSULTING_STUDENT : 0,                                 // 서버에서 사용중인 상담 학생 타입
        WAITING_STUDENT : 1,                                    // 서버에서 사용중인 등원대기학생 타입
        ENROL_STUDENT : 2,                                      // 서버에서 사용중인 재원 학생 타입
        COUPON_STUDENT : 3,                                     // 서버에서 사용중인 쿠폰 학생 타입
        HOLD_STUDENT : 4,                                       // 서버에서 사용중인 홀드 학생 타입
        EXIT_STUDENT : 9,                                       // 서버에서 사용중인 퇴원 학생 타입
        VIP_STUDENT : 1001,                                     // 서버에서 사용중인 VIP 학생 타입
    },
    // priority_value(관리도) A = 1 , B = 2 , C = 3
    convert_new_student_data_to_table_data(student_data) {
        let processing_data = []

        student_data.forEach(item => {
            let value = CommonData.student_status_type[item.priority]
            const expiry_date = date_format(item.expiration_datetime, "YYYY-MM-DD") || "-";
            const exit_date = date_format(item.leaving_datetime, "YYYY-MM-DD")
            const admission_date = date_format(item.admission_datetime, "YYYY-MM-DD")
            const major_name = CommonData.major_type[item.major] ? CommonData.major_type[item.major] : "-"
            const grade_type = CommonGrade.methods.gradeValue(item.grade) ? CommonGrade.methods.gradeValue(item.grade) : ""
            const use_shuttle_text = CommonData.use_shuttle_bus[item.use_shuttle_bus] ?  CommonData.use_shuttle_bus[item.use_shuttle_bus] : ""

            const consulting_date_count = this.get_diff_date(item.counseling_datetime)
            const last_counseling = date_format(item.last_counseling, "YYYY-MM-DD");
            const last_counseling_date_count = this.get_diff_date(item.last_counseling)
            const coupon_expiration_date = item.coupon != 0 ? item.coupon_expiration_date : null;
            const hold_date = date_format(item.hold_datetime, "YYYY-MM-DD");
            const hold_date_count = this.get_diff_date(item.hold_datetime);            
            const hold_date_remaining_count = this.get_diff_date(item.expiration_datetime, item.hold_datetime, { include:true, bigDay:true });
            processing_data.push({
                priority_value : value !== undefined ? value : "" ,
                exit_date : exit_date ? exit_date : "-" ,
                admission_date : admission_date ? admission_date : "미정",
                consulting_date : date_format(item.counseling_datetime, "YYYY-MM-DD"),
                major_name,
                use_shuttle_text,
                consulting_date_count,
                last_counseling_date_count,
                ...item,
                coupon_expiration_date,
                grade_type,
                check : false,
                expiry_date,
                last_counseling,
                hold_date,
                hold_date_count,
                hold_date_remaining_count,
            })
        });
        return processing_data
    },
    get_diff_date(day, start_date = new Date(), option = {}) {
        let { bigDay, include } = option;
        let str = "-";
        const date = date_format(day);
        start_date = date_format(start_date);
        if (date && start_date && !(bigDay && date < start_date)) {
            start_date = new Date(start_date).setHours(0, 0, 0);
            str = Math.floor(Math.abs((start_date - new Date(date)) / (1000 * 60 * 60 * 24)));
            str +=  include ? 1 : 0;
            str = str >= 0 ? "+" + str : str + "";
        }
        return str;
    },
    // vip student
    convert_vip_student_data_to_table_data(student_data){
        let processing_data = []
        const now_date = new Date(date_format(new Date(), "YYYY-MM-DD"))

        student_data.forEach(item => {
            const major_name = CommonData.major_type[item.major] ? CommonData.major_type[item.major] : "-"
            const created_period = Math.floor(Math.abs((now_date - new Date(date_format(item.vip_created_at,"YYYY-MM-DD"))) / (1000 * 3600 * 24)))
            const created_date = date_format(item.vip_created_at, "YYYY-MM-DD")
            const grade_type = CommonGrade.methods.gradeValue(item.grade) ? CommonGrade.methods.gradeValue(item.grade) : ""
            processing_data.push({
                major_name : major_name,
                created_period : "+" + created_period,
                created_date : created_date ? created_date : "-",
                ...item,
                grade_type : grade_type,
                check : false,
            })
        });
        return processing_data
    },
    get_student_status_change_apply_data(status, option){
        let empty_datetime = "0000-00-00 00:00:00";

        const { src, dst } = status;
        const {
            leaving_reason,         // 퇴원 사유
            leaving_datetime,       // 퇴원 일자
            priority,               // 관리도
            locker_no,              // 사물함
            shoe_rack_no,           // 신발장
            hold_datetime,          // 홀드 날짜
            expiration_datetime,    // 재원학생 만기일
            coupon_expiration_date, // 쿠폰학생 만기일
        } = option;

        // dst != 재원, 쿠폰 학생 > 신발장, 사물함 초기화
        // dst != 퇴원 > 퇴원사유와 퇴원일 초기화
        // dst == 재원, 쿠폰, 홀드 > hold_datetime 초기화
        let temp = {
            leaving_reason: null,
            leaving_datetime: empty_datetime,
            locker_no: null,
            shoe_rack_no: null,
            // hold_datetime: empty_datetime,
            // expiration_datetime: empty_datetime,
            // coupon_expiration_date: null,
            status: dst,
        };

        // hold_datetime 이 있을 경우엔, 홀드 학생이였었고, 
        switch (Number(dst)) {
            // case this.STUDENT_TYPE.CONSULTING_STUDENT:
            // case this.STUDENT_TYPE.WAITING_STUDENT:
            // case this.STUDENT_TYPE.HOLD_STUDENT:
            //     // 작업 x
            //     break;
            case this.STUDENT_TYPE.ENROL_STUDENT:
            case this.STUDENT_TYPE.COUPON_STUDENT:
                {
                    // 대기, 쿠폰, 홀드, 퇴원 > ... ENROL_STUDENT
                    // 대기, 재원, 홀드, 퇴원 > ... COUPON_STUDENT
                    const dstEnrol = dst == this.STUDENT_TYPE.ENROL_STUDENT;
                    // hold_datetime 초기화를 통해 만기일 더이상 증가 x
                    temp = { ...temp, locker_no, shoe_rack_no, hold_datetime: empty_datetime };
                    let base = dstEnrol ? expiration_datetime : coupon_expiration_date;
                    base = this.get_base_add_hold(base, hold_datetime);
                    if (base) dstEnrol ? temp.expiration_datetime = base : temp.coupon_expiration_date = base;
                }
                break;
            case this.STUDENT_TYPE.EXIT_STUDENT:
                {
                    // all > ...
                    temp = { ...temp, leaving_reason, leaving_datetime, priority, hold_datetime: empty_datetime }
                }
                break;
        }
        return temp;
    },

    get_base_add_hold(base, hold){
        let base_time = date_format(base, "YYYY-MM-DD");
        let hold_time = date_format(hold, "YYYY-MM-DD");
        let ret = null;
        if(base_time && hold_time && base_time > hold_time){
            let daytime = 1000 * 60 * 60 * 24;
            base_time = Math.floor(new Date(base_time).getTime() / daytime);
            hold_time = Math.floor(new Date(hold_time).getTime() / daytime);
            let now_time = Math.floor(SPARTA_TIME.GetNowDate().getTime() / daytime);
            ret = date_format(new Date((base_time + (now_time - hold_time)) * daytime), "YYYY-MM-DD");
        }
        return ret;
    }
}