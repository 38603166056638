import gsClass from "@/globalstorage/GlobalStorageClass"
const StoreData = {
    install : (vue, options) => {
        const gs = new gsClass()
        gs.set_vue(vue)
        vue.config.globalProperties.$globalStorage.refresh = (key) => {
            gs.refresh_data(key)
        }
        vue.config.globalProperties.$globalStorage.request_all_data = () => {
            gs.request_data()
        }
        vue.config.globalProperties.$globalStorage.request_all_data()
    },
}

export default StoreData;