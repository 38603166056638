import { date_format, time_format_for_string } from "@/js/util/math"
import SmsApiService from "@/services/smsapi.service"
import { SCHEDULE_API } from "@/services/exapi.service.js"
import ConsultingData from "@/components/common/data/CommonConsultingData"

/* modal에서 사용하는 임시 데이터 */
export default {
    outing_logout_type : '1',                   // 서버에서 외출 로그 아웃시 필요한 type
    leave_logout_type : '0',                    // 서버에서 퇴실 로그 아웃시 필요한 type
    point_completed : "부과 완료",
    point_imposed : "부과 예정",
    reason_submit_completed : "사유 제출 완료",
    reason_submit_required : "사유 제출 필요",
    modal_data : {
        late30 : {
            title :  "무단 지각 벌점 확인",
            description : "쉬는 시간까지 인재니움룸 으로 이동해서 대기 공부해주세요.",
            button : "확인"
        },
        over30 : {
            title : "무단 지각 벌점 확인 및 입실 대기",
            description : "180분 이상 무단 지각을 한 학생은 선생님의 허가 없이는 학습실로 입실이 불가능합니다." + 
                        "<br>쉬는 시간까지 인재니움룸 으로 이동해서 대기 공부해주세요.",
            button : "입실 요청"
        },
    },
    convert_notice_data_to_points_data(raw_data) {
        let point_content = []
        if(raw_data && raw_data.points) {
            raw_data.points.forEach(item => {
                const content = "(" + (item.is_imposed ? this.point_imposed : this.point_completed) + ")"
                point_content.push({
                    title : item.type_name ?? item.content,
                    point : item.point,
                    content : content,
                })
            })
        }
        return point_content
    },
    convert_notice_data_to_consulting_data(raw_data) {
        let consulting_content = []
        if(raw_data && raw_data.counselings) {
            raw_data.counselings.forEach(item => {
                let datetime = ConsultingData.get_consulting_date(item, false, true , true)
                datetime = date_format(datetime, "HH시 mm분")
                consulting_content.push({
                    title : item.type_name,
                    teacher : item.teacher_name,
                    time : datetime ? datetime : "--시 --분"
                })
            })
        }
        return consulting_content
    },
    // 로그인후 사용될 모달내 데이터
    convert_notice_data_to_login_modal_data(raw_data) {
        const notice_content = {
            point_content : this.convert_notice_data_to_points_data(raw_data),
            consulting_content : this.convert_notice_data_to_consulting_data(raw_data),
        }
        return notice_content 
    },
    // 로그아웃전 사용될 모달내 데이터
    convert_notice_data_to_logout_modal_data(raw_data) {
        if(raw_data) {
            const planner_time = this.time_format_for_string(raw_data.planner_inspection?.time)
            const exit_time = this.time_format_for_string(raw_data.exit_time)
            const stay_time = this.time_format_for_string(raw_data.stay_time, " 시간 ", " 분")
            const notice_content = {
                point_content : this.convert_notice_data_to_points_data(raw_data),
                consulting_content : this.convert_notice_data_to_consulting_data(raw_data),
                planner_inspection : planner_time,
                exit_time : exit_time,
                stay_time : stay_time,
            }
            return notice_content 
        }
        return false
    },
    // 학생 위젯에서 주요사항으로 계속 보여질 데이터
    convert_notice_data_to_my_today_widget_data(raw_data) {
        if(raw_data) {
            const planner_time = this.time_format_for_string(raw_data.planner_inspection?.time)
            const notice_content = {
                point_content : this.convert_notice_data_to_points_data(raw_data),
                consulting_content : this.convert_notice_data_to_consulting_data(raw_data),
                planner_inspection : planner_time,
                scheduled_enter_time : raw_data.scheduled_enter_time.map(item => {return this.time_format_for_string(item, ":", "")}).join(" / "),
                scheduled_exit_time : raw_data.scheduled_exit_time.map(item => {return this.time_format_for_string(item, ":", "")}).join(" / "),
                actual_enter_time : raw_data.actual_enter_time.map(item => {return this.time_format_for_string(item, ":", "")}).join(" / "),
                actual_exit_time : raw_data.actual_exit_time.map(item => {return this.time_format_for_string(item, ":", "")}).join(" / "),
            }
            return notice_content
        }
        return false
    },
    // 30분 이전 이후 데이터 체크
    get_student_late_modal_data(data) {
        if(data == SCHEDULE_API.ACTION_TYPE.PANELTY_UNDER30) {
            return this.modal_data.late30
        }
        else { 
            return this.modal_data.over30
        }
    },
    time_format_for_string(time, hour_format = " 시 ", minute_format = " 분") {
        let res_time = "-"
        if(time) {
            const obj_time = time_format_for_string(time)
            if(obj_time.hour && obj_time.minute) {
                res_time = obj_time.hour + hour_format + obj_time.minute + minute_format
            }
        }
        return res_time;
    },
    async submit_message(student_data, enter_check = true) {
        // student_data : myinfo 데이터
        // enter_check == true (첫 입실)
        // enter_check == false (퇴실)

        let message_text = ""
        if(enter_check) {
            message_text = (student_data.name ? student_data.name :  "") + " 학생이 입실 하였습니다."
        }
        else {
            message_text = (student_data.name ? student_data.name :  "" ) + " 학생이 퇴실 하였습니다."
        }
        let messages = [];
        let message = {
            to : student_data.parent_phone_number.replace(/-/g, ''),
            from : "01058283551", // 고정된 학원 전화
            text : message_text,
        }
        messages.push(message);
        let res = await SmsApiService.send_messages(messages);
        return res
    }
}