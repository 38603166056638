<template>
    <div id="over30">
        <div class="over30_in">
            <h1>
                {{attr.title}}
            </h1>
            <div>
                <p>
                    <span>{{myinfo.name}}</span><span>|</span>{{myinfo.id}}<span>|</span>{{myinfo.school_name}} {{gradeValue(myinfo.grade)}}
                </p>
            </div>
            <div>
                <div>
                    <div class="row01">
                        벌점 내용
                    </div>
                    <div class="row02">
                        벌점
                    </div>
                    <div class="row03">
                        상세설명
                    </div>
                </div>
                <ul>
                    <li>
                        <div class="row01">
                            {{attr.point.name}}
                        </div>
                        <div class="row02">
                            {{attr.point.point}}
                        </div>
                        <div class="row03">
                            {{attr.point.description}}
                        </div>
                    </li>
                </ul>
            </div>
            <div>
                <h2>지각 사유 입력</h2>
                <textarea v-model="reason.content"></textarea>
                <p v-html="attr.description" />
            </div>
            <div class="buttons">
                <input type="button" class="enter_waiting" :value="attr.button ? attr.button : '입실 요청'" @click="send_enter_apply()">
                <input type="button" class="logout" value="로그 아웃" @click="logout()">
            </div>
        </div>
    </div>
</template>

<script>
import CommonGrade from "@/components/common/CommonGrade"

export default {
    mixins : [CommonGrade],
    props : {
        attr : Object,
    },
    data () {
        return {
            reason : {
                content : "",
            },
            myinfo : {},
        }
    },
    methods : {
        send_enter_apply() {
            if(!this.reason.content) {
                this.$ModalManager.showdialog(
                    {
                        title : "지각 사유가 없습니다.",
                        buttons : {
                            "yes" : "",
                        }
                    }
                )
            }
            else {
                this.$ModalManager.close(this, this.reason)
            }
        },
        logout(){
            this.$ModalManager.close(this);
            this.$store.dispatch("logout");
            this.$router.push("/login")
        }
    },
    created() {
        if(this.$globalStorage.getters.myinfo) {
            this.myinfo = this.$globalStorage.getters.myinfo
        }
    },
    activated() {
        if(this.$globalStorage.getters.myinfo) {
            this.myinfo = this.$globalStorage.getters.myinfo
        }
    },
    watch : {
        "$globalStorage.getters.myinfo" : {
            handler : function(value) {
                if(this.$globalStorage.getters.myinfo) {
                    this.myinfo = this.$globalStorage.getters.myinfo
                }
            }, deep : true
        }
    }
}
</script>

<style scoped>

#over30 {
    width: 870px;
    border-radius: 15px;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 106;
    padding: 30px 0;
    color: #6c6c6c;
}
.over30_backon {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(51, 51, 51, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 102;
}
.over30_in {
    width: 710px;
    margin: 0 auto;
}
.over30_in h1 {
    font-size: 28px;
    color: #1e3560;
    font-weight: bold;
    margin-bottom: 20px;
}
.over30_in > div:nth-of-type(1) {
    margin-bottom: 20px;
    font-size : 18px;
}
.over30_in > div:nth-of-type(1) span {
    margin: 0 10px;
}
.over30_in > div:nth-of-type(1) p span:nth-of-type(1) {
    color: #1E3560;
    font-weight: bold;
    margin: 0;
}
.over30_in > div:nth-of-type(2) {
    text-align: center;
    margin-bottom: 20px;
    font-size : 18px;
}
.over30_in > div:nth-of-type(2) > div {
    display: flex;
    padding : 5px 0;
    align-items: center;
    background-color: #f2f2f2;
    border-top: 1px solid #9c9c9c;
    border-bottom: 1px solid #9c9c9c;
    font-weight: bold;
    color: #1e3560;
}
.over30_in > div:nth-of-type(2) > ul li {
    display: flex;
    padding : 5px 0;
    align-items: center;
    border-bottom: 1px solid #9c9c9c
}
.over30_in > div:nth-of-type(2) > ul li  .row02 {
    font-weight: bold;
    color: #1e3560;
}
.over30_in .row01 {
    width: 150px;
}
.over30_in .row02 {
    width: 90px;
}
.over30_in .row03 {
    width: 470px;
}
.over30_in > div:nth-of-type(3) h2 {
    font-size: 18px;
    font-weight: bold;
    color: #1e3560;
    margin-bottom: 10px;
}
.over30_in > div:nth-of-type(3) textarea {
    resize: none;
    width: 100%;
    height: 133px;
    outline: none;
    color: #6c6c6c;
    font-size: 18px;
    border: 1px solid #9c9c9c;
    padding: 15px;
    box-sizing: border-box;
}
.over30_in > div:nth-of-type(3) p {
    margin-top: 20px;
    text-align: center;
    color: #9D1D22; 
    font-size : 18px;
}
.buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.buttons input{
    width: 170px;
    height: 40px;
    border: none;
    color: #fff;
    font-size: 18px;
    text-align: center;
    line-height: 40px;
    border-radius: 20px;
    cursor: pointer;
}
.enter_waiting {
    background-color: #1e3560;
    margin-right: 30px;
}
.logout {
    background-color: #9c9c9c;
}
</style>