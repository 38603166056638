<template>
    <BaseModal />
    <ToastMsg />
    <div>
        <!-- HelloWorld msg="Welcome to Your Vue.js App"/-->
        <router-view />
    </div>
</template>

<script>
import ApiService from './services/api.service';
export default {
    name: "App",
    watch: {
        "$route.path": function (value) {
            if(value == "/login") this.$ToastManager.clear();
            this.$ModalManager.all_close();
        }
    },
    beforeCreate() {
        ApiService.set_vue_app(this);
    }
};
</script>

<style lang="scss">
@import "@/assets/css/main.css";
@import "@/assets/css/consulting/input.reset.css";

div#searcharea {
    height: 30px;
    display: flex;
    background : #f2f2f2;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border-radius: 20px;
	width : fit-content;
	padding-left : 20px;
    margin-left : auto;
    margin-right : 0;
    > div.searchbox {
        display: flex;

        margin-left: 10px;
		> input {
			width : 100px;
            background : inherit;
            &::placeholder{
                font-size : 14px;
                
            }
		}

        > button {
			margin-left : 10px;
			background : var(--color-sparta);
			color : white;
			border-radius: 20px;
			font-size : 18px;
			height:20px;
			width : 20px;
			line-height: 15px;
			> i {
				font-size : 15px;
			}
        }
		> input {
			border : none;
			outline: none;
		}
    }
}
</style>
