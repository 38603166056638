<template>
  <div class="common_search_input">
    <input
      type="text"
      v-bind:placeholder="this.pIsDisable ? '' : this.pPlaceText"
      v-bind:class="this.pIsDisable ? 'disable_click' : ''"
      v-model="data_InputText"
      @keydown.enter="func_OnSearchClicked()"
    />
    <div>
      <img
        src="@/assets/images/search@2x.png"
        alt="돋보기"
        v-on:click="func_OnSearchClicked()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "FrontCommonSearchInput",
  props: {
    pPlaceText: String,
    pSearchInputData: String,
    pIsDisable: Boolean,
  },
  data() {
    return {
      data_InputText: "",
    };
  },
  methods: {
    func_OnSearchClicked: function () {
      this.$EventManager.EmitToParent(
        this,
        "update:pSearchInputData",
        this.data_InputText
      );
    },
  },
  watch: {
    pSearchInputData: function (newValue) {
      this.data_InputText = this.pSearchInputData;
    },
  },
  created: function () {
    this.data_InputText = this.pSearchInputData;
  },
};
</script>

<style scoped>
.common_search_input {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  background-color: #f2f2f2; /* #f2f2f2; */
  position: relative;
  text-align: left;
}
.common_search_input input {
  margin-left: 20px;
  border: none;
  background-color: #f2f2f2; /* #f2f2f2; */
  height: 40px;
  color: #1e3560;
  outline: none;
  font-size: 16px;
  width: 70%;
  cursor: text;
}
.common_search_input input::placeholder {
  color: #d9d9d9;
}
.common_search_input div {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 1px solid #f2f2f2;
  background-color: #fff;
  box-sizing: border-box;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border-radius: 50%;
}
.common_search_input div img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.disable_click {
  background-color: #d9d9d9;
  pointer-events: none;
}
</style>