
<template>
    <div id="app_cancelPop" @keydown.esc="on_key_esc">
        <div class="app_cancel_in">
            <div v-if="attr.title" class="titlemsg">
                <h1 v-html="attr.title">
                </h1>
                <p id="msg" v-html="attr.msg" v-if="!attr.write_mode" class="webkitscroll">
                </p>
                <textarea v-model="dmsg" v-else class="webkitscroll"></textarea>
            </div>
            <div :class="[attr.write_mode ? 'multi_buttons' : 'single_buttons']" class="buttons">
                <button @click="on_button_click('yes')" class="yes">
                    확인
                </button>
                <button @click="on_key_esc()" class="no" v-if="attr.write_mode">
                    취소
                </button>
            </div>
        </div>
        <div id='keyeventreceiver'>
            <input id="keyeventreceiver" ref="keyeventreceiver" type="text" @keydown.esc="on_key_esc"/>
        </div>
    </div>
</template>


<script>
export default {
    props : {
        attr : Object,
    },

    created() {
        this.dmsg = this.attr.msg
    },

    mounted()
    {
        this.$refs.keyeventreceiver.focus();
    },

    data() {
        return {
            dmsg : '',
        }
    },
    methods : {
        on_button_click(key)
        {
            let res = {result : key}
            if(this.dmsg && this.attr.write_mode) {
                res.content = this.dmsg
            }
            this.$ModalManager.close(this, res)
        },
        on_key_esc()
        {
            this.$ModalManager.close(this, "nothing");
        }
    }
}
</script>

<style scoped>

#app_cancelPop {
    width: 950px;
    height: fit-content;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 106;
    background-color: #fff;
    border-radius: 15px;
    padding: 55px 68px 30px 68px;
    box-sizing: border-box;
    color: #6c6c6c;
    text-align: center;
}
.app_cancel_in p {
    font-weight: bold;
    color: #1E3560;
    margin-bottom: 55px;
    font-size: 18px;
}

h1 {
    font-size: 20px;
    font-weight: bold;
    color: #1E3560;
    margin-bottom: 11px;
}
div.titlemsg > p {
    font-weight : normal;
    font-size: 18px;
    margin-bottom: 30px;
}

.app_cancel_in > div {
    width: 364px;
    height: 40px;
    margin: 0 auto;
    display: flex;
    line-height: 24px;
}

.app_cancel_in > div.titlemsg {
    display: inline;
}

.single_buttons {
    justify-content: center;
}
.multi_buttons {
    justify-content: space-between;
}

button {
    width: 170px;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    border: none;
}
.buttons {
    width: 50%;
}

button.yes {
    background-color: #1E3560;
}
button.no {
    background-color: #6c6c6c;
}
textarea {
    font-size: 18px;
    width: 100%;
    height: 500px;
    overflow-y : scroll;
}

div#keyeventreceiver {
    position: absolute;
    top : 5000px;
}

input#keyeventreceiver {
    /* visibility: hidden; */
}
#msg {
    max-height: 900px;
    overflow-y : auto;
}
</style>    