const routes = [
    {
        path: "/test",
        component: () => import("@/test/test.vue"),
        children: [
            {
                path: "/test/login",
                component: () => import("@/test/test_login.vue"),
            },
            {
                path: "/test/websocket",
                component: () => import("@/test/test_websocket_multi.vue"),
            },
            {
                path: "/test/storage",
                component: () => import("@/test/test_storage.vue"),
            },
            {
                path: "/test/promise",
                component: () => import("@/test/test_promise.vue"),
            },
            {
                path: "/test/apicall",
                component: () => import("@/test/test_apicall.vue"),
            },
            {
                path: "/test/calendar",
                component: () => import("@/test/test_calendar.vue"),
            },
            {
                path: "/test/finger",
                component: () => import("@/test/test_fingerprint.vue"),
            },
            {
                path: "/test/sms",
                component: () => import("@/test/test_sms.vue"),
            },
            {
                path: "/test/student_schedule",
                component: () => import("@/test/test_student_schedule.vue"),
            },
            {
                path: "/test/inout",
                component: () => import("@/test/test_inout.vue"),
            },
            {
                path : "/test/pin",
                component: () => import("@/test/test_pin.vue"),
            },
            {
                path : "/test/teacher",
                component: () => import("@/test/test_teacher_time.vue"),
            },
            {
                path : "/test/seat",
                component: () => import("@/test/test_seat.vue"),
            },
            {
                path : "/test/seatview",
                component: () => import("@/test/test_seatview.vue"),
            },
            {
                path : "/test/graph",
                component: () => import("@/test/test_graph.vue"),
            },
            {
                path : "/test/input_wrapper",
                component: () => import("@/test/test_inputwrapper.vue"),
            }

        ],
    },
];

export default routes;
