import CommonData from "./data/CommonData";

export default {    
    methods : {
        gradeValue(value) {
            if(!value)
                return "";

            let text = CommonData.school_grade_type[value];
            return (text ?? value) +  "학년";
        }
    }
};