<template>
    <div id="badscore_addPop">
        <div class="header">
            <h1>벌점 부과</h1>
        </div>
        <div class="main">
            <div>
                <div class="search_box">
                    <div>학생 리스트</div>
                    <div style="width: 200px; height: 30px">
                        <search-bar :placeholder_text="'학생 이름 검색'" @search="(s) => (this.search_keyword = s)" />
                    </div>
                </div>
                <div class="stu_list">
                    <li class="list_title">
                        <div>학 생 번 호</div>
                        <div>성 명</div>
                        <div>전 화 번 호</div>
                        <div>학 년</div>
                    </li>
                    <li
                        v-for="item in this.students"
                        :key="item"
                        :class="{ search_matched: this.selected_student?.id === item.id }"
                        @click="this.setStudent(item)"
                        v-show="item.name.indexOf(this.search_keyword) !== -1"
                    >
                        <div>{{ item.id ?? "-" }}</div>
                        <div>{{ item.name ?? "-" }}</div>
                        <div>{{ this.phone_str(item) ?? "-" }}</div>
                        <div>{{ this.grade_str(item) ?? "-" }}</div>
                    </li>
                </div>
            </div>
            <div>
                <div class="point_title">
                    <div>
                        <h1>벌점 부과</h1>
                        <p v-if="this.selected_student">
                            {{ this.selected_student.name }} | {{ this.selected_student.id }} | {{ this.phone_str(this.selected_student) }} | {{ this.grade_str(this.selected_student) }}
                        </p>
                    </div>
                    <button @click="this.onTypeAdd()">벌점 종류 추가</button>
                </div>
                <div class="point_box">
                    <li class="list_title">
                        <div class="point_name">벌 점 종 류</div>
                        <div>벌 점</div>
                        <div class="checkbox">선 택</div>
                        <div>설 정</div>
                    </li>
                    <div v-for="item in this.types" :key="item.no" v-bind:class="{ search_matched: this.is_point_selected(item.no) }" @click="this.setPoints(item.no)">
                        <div class="point_name" :title="item.description">
                            <div>
                                <await-button @click.stop="this.onTypeFavorite(item)" v-if="item.favorite" :selected="this.is_point_selected(item.no)">&#9733;</await-button>
                                <await-button @click.stop="this.onTypeFavorite(item)" v-else :selected="this.is_point_selected(item.no)">&#9734;</await-button>
                            </div>
                            <p>{{ item.name }}</p>
                        </div>
                        <div>{{ item.point }}</div>
                        <div class="checkbox"><input type="checkbox" v-model="this.selected_points" v-bind:value="item.no" /></div>
                        <div>
                            <button @click.stop="this.onTypeFix(item)">수정</button>
                            <await-button class="cancel" @click.stop="this.onTypeDel(item)">삭제</await-button>
                        </div>
                    </div>
                </div>
                <div class="content_box">
                    <div>
                        <div>
                            <p>벌점 입력</p>
                        </div>
                        <div>
                            사유 : <input type="text" @input="this.text_input" :value="this.reason" placeholder="입력 시 체크 해제" /> 벌점 :
                            <input type="number" @keypress="this.only_number" @input="this.number_input" :value="this.reason_point" />
                        </div>
                    </div>
                    <div>
                        <div>
                            <p>총 벌점</p>
                        </div>
                        <div>{{ this.point_sum ? this.point_sum : 0 }}점</div>
                    </div>
                </div>

                <div class="submit_box">
                    <input type="button" value="취 소" class="cancle" v-on:click="this.close_btn()" />
                    <await-button @click="this.submit_btn()">벌점 부과</await-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BadScoreTypeAddModal from "@/components/front/Modal/BadScoreTypeAddModal.vue";

import { ApiReq, defaultPageParam } from "@/components/manager/ManagerUtils.js";
import CommonGrade from "@/components/common/CommonGrade";
import FrontUtils from "../FrontJs/FrontUtils";
import jquery from "jquery";
import StudentData from "@/components/consulting_teacher/data/ConsultingStudentData";
import PANELTY_DATA from "@/components/common/student_week_schedule/js/panelty_data";
export default {
    name: "BadScoreAddPopup",
    props: {
        center_no: String,
        initStudent: Object,
    },
    mixins: [CommonGrade],
    emits: ["close"],
    data() {
        return {
            types: [],
            students: [],

            selected_student: null,
            selected_points: [],
            search_keyword: "",

            reason: null,
            reason_point: 0,
            myInfo: null,
        };
    },
    methods: {
        async onTypeAdd() {
            let ret = await this.$ModalManager.showEx(BadScoreTypeAddModal, {
                bind: { centerNo: this.center_no },
            });
            if (ret) this.init(false);
        },
        async onTypeFix(type) {
            let ret = await this.$ModalManager.showEx(BadScoreTypeAddModal, {
                bind: { isFix: true, typeData: type, centerNo: this.center_no },
            });
            if (ret) this.init(false);
        },
        async onTypeDel(type) {
            let ret = await this.$ModalManager.showdialog({
                title: "벌점종류를 삭제하시겠습니까?",
                msg: type.name + "<br/><br/>" + type.description,
                buttons: { ok: "삭 제", no: "취 소" },
            });

            if (ret?.result == "ok") {
                await new ApiReq("/point/type/" + type.no)
                    .setResultCallback((res) => {
                        if (res.result) this.init(false);
                        else return false;
                    })
                    .setCompleteMsg("벌점이 삭제 되었습니다.")
                    .setFailMsg("벌점 삭제에 실패하였습니다.")
                    .delete();
            }
        },
        async onTypeFavorite(type) {
            const favorite_str = type.favorite ? "해제" : "추가";
            let ret = await this.$ModalManager.showdialog({
                title: "즐겨찾기를 " + favorite_str + "하시겠습니까?",
                msg: type.name + "<br/><br/>" + type.description,
                buttons: { ok: favorite_str, no: "취소" },
            });
            if (ret?.result == "ok") {
                let p = {
                    center_no: this.center_no,
                    favorite: type.favorite ? 0 : 1,
                };
                await new ApiReq("/point/type/" + type.no, p)
                    .setResultCallback((res) => {
                        if (res.result) this.init(false);
                        else return false;
                    })
                    .setCompleteMsg("즐겨찾기가 " + favorite_str + "되었습니다.")
                    .setFailMsg("즐겨찾기 " + favorite_str + "에 실패하였습니다.")
                    .put();
            }
        },
        setPoints(item_no) {
            const item_idx = this.selected_points.findIndex((e) => e == item_no);

            if (item_idx == -1) this.selected_points.push(item_no);
            else this.selected_points.splice(item_idx, 1);
        },
        init(getstudents = true) {
            new ApiReq("/point/types", { ctr: this.center_no })
                .setFailMsg("벌점 정보를 가져오는데 실패했습니다.")
                .setResultCallback((res) => {
                    if (res instanceof Array) {
                        this.types = res.reduce((acc, e) => {
                            if(!e.panelty_code){
                                e.favorite = e.favorite == 1 ? true : false;
                                acc.push(e);
                            }
                            return acc;
                        },[]);
                    } else {
                        return false;
                    }
                })
                .get();
            if (getstudents) this.getStudent();
            else this.init_data();
        },
        init_data() {
            this.selected_points = [];
            this.reason = null;
            this.reason_point = 0;
        },

        getStudent(name) {
            let status = StudentData.STUDENT_TYPE.ENROL_STUDENT + "," + StudentData.STUDENT_TYPE.COUPON_STUDENT;
            let param = defaultPageParam({
                ctr: this.center_no,
                name: name,
                status: status,
            });
            new ApiReq("/students", param)
                .setFailMsg("학생정보를 불러오는데 실패했습니다.")
                .setResultCallback((res) => {
                    if (res instanceof Array) {
                        this.students = res;
                        this.selected_student = this.students?.find((student) => {
                            return student.id === this.initStudent?.student_id;
                        });

                        if (!this.selected_student) this.selected_student = res[0];
                        else {
                            this.search_student_scroll();
                        }
                    } else {
                        return false;
                    }
                })
                .get();
        },
        search_event(search) {
            this.init_data();
            this.getStudent(search);
        },
        setStudent(student) {
            this.selected_student = student;
            this.init_data();
        },
        is_point_selected: function (value) {
            var result = false;
            for (const e of this.selected_points) {
                if (e === value) {
                    result = true;
                    break;
                }
            }
            return result;
        },
        grade_str(student) {
            const grade = this.gradeValue(student?.grade);
            if (grade) {
                return student?.school_name + " " + grade;
            } else {
                return null;
            }
        },
        phone_str(student) {
            const str = FrontUtils.util_ReturnPhoneNumber(student?.phone_number);
            return str !== "" ? str : null;
        },
        close_btn: function () {
            this.$emit("close");
            this.$ModalManager.close(this);
        },
        async submit_btn() {
            if (this.selected_student?.id) {
                if (this.selected_points?.length || (this.reason && this.reason_point)) {
                    await this.$ModalManager.showdialog({ msg: "벌점을 부과하시겠습니까?", buttons: { yes: "확인", no: "취소" } }).then(async (res) => {
                        if (res?.result == "yes") {
                            let url = null;
                            let p = {
                                student_id: this.selected_student.id,
                                point: this.point_sum,
                                content: this.reason?.trim(),
                                points: this.selected_points.map((e) => Number(e)),
                            };
                            if (this.reason) url = "/point";
                            else url = "/points";

                            await PANELTY_DATA.request_panelty_point(p, url);
                            this.$ModalManager.showdialog("벌점을 부과했습니다.");
                            this.init_data();
                        }
                    });
                } else {
                    this.$ModalManager.showdialog("부과할 벌점을 선택하거나 입력해주세요.");
                }
            } else {
                this.$ModalManager.showdialog("선택된 학생이 없습니다.");
            }
        },
        search_student_scroll() {
            setTimeout(function () {
                const target = document.querySelector("li.search_matched");
                const parent = document.querySelector("div.stu_list");
                jquery(parent).animate(
                    {
                        scrollTop: target.offsetTop - 300,
                    },
                    500
                );
            }, 500);
        },
        only_number(e) {
            e = e ? e : window.event;
            const charCode = e.which ? e.which : e.keyCode;
            if (charCode < 48 || charCode > 57) {
                e.preventDefault();
            } else {
                return true;
            }
        },
        number_input(e) {
            if (e.target.value) {
                if (Number(e.target.value) === 0) {
                    e.target.value = 0;
                    this.reason_point = 0;
                } else {
                    let value = e.target.value > 0 ? -e.target.value : e.target.value;
                    e.target.value = value;
                    this.reason_point = value;
                    if (this.selected_points.length) this.selected_points = [];
                }
            } else {
                e.target.value = 0;
                this.reason_point = 0;
            }
        },
        text_input(e) {
            if (e.target.value.trim()) {
                this.reason = e.target.value;
                if (this.selected_points.length) this.selected_points = [];
            } else {
                this.reason = null;
                e.target.value = null;
            }
        },
    },
    computed: {
        point_sum: function () {
            var sum = 0;
            const list_arr = this.types;
            sum = this.selected_points.reduce((acc_sum, cur_value) => {
                const find_item = list_arr.find((li_e) => li_e.no === cur_value);
                return (acc_sum += Number(find_item.point));
            }, 0);
            sum += Number(this.reason_point);
            return sum;
        },
    },
    watch: {
        selected_points: {
            deep: true,
            handler(v) {
                if (v.length) {
                    this.reason = null;
                    this.reason_point = 0;
                }
            },
        },
        search_keyword(v) {
            this.init_data();
        },
    },
    created: function () {
        if (!this.center_no) {
            this.$ModalManager.showdialog("등록된 센터번호가 없습니다");
            this.$ModalManager.close(this);
        }
        this.init();
    },
};
</script>

<style lang="scss" scoped>
* {
    user-select: none;
}
/* custom style */

#badscore_addPop {
    /* display: none; */
    /* position: fixed; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    /* z-index: 106; */
    background: #fff;
    border-radius: 20px;
    padding: 30px;
    width: 1400px;
    .header {
        text-align: left;
        // background-color: blue;
        h1 {
            font-size: 25px;
            color: #1e3560;
            font-weight: bold;
        }
    }
    .main {
        // background-color: red;
        display: flex;
        padding: 10px;
        color: #6c6c6c;
        justify-content: space-between;
        > div {
            &:nth-of-type(1) {
                width: 45%;
            }
            &:nth-of-type(2) {
                width: 53%;
            }
        }
        .search_box {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 40px;
            align-items: center;
            // text-align: right;
            > div {
                width: 50%;
            }
            > div:nth-child(1) {
                text-align: left;
                font-size: 20px;
                color: #1e3560;
                font-weight: bold;
            }
            input {
                text-align: left;
                cursor: text;
            }
        }
        .list_title {
            background-color: #1e3560;
            font-weight: bold;
            font-size: 16px;
            color: #fff;
            pointer-events: none;
            position: sticky;
            top: 0;
        }
        li {
            display: flex;
            cursor: pointer;
            font-size: 15px;
            div {
                padding: 5px;
                flex: 1;
                border-right: 1px solid #9c9c9c;
                word-break: break-all;
                display: flex;
                align-items: center;
                justify-content: center;
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
                &:nth-of-type(4) {
                    flex: 1.5;
                }
            }
            div:nth-last-child(1) {
                border: none;
            }
        }
        li:hover {
            background-color: #e7fcff;
        }
        .stu_list {
            width: 100%;
            background-color: #f2f2f2;
            border-radius: 10px;
            flex-direction: column;
            margin-top: 10px;
            height: 500px;
            overflow-x: none;
            overflow-y: auto;
        }
        .stu_list::-webkit-scrollbar {
            width: 16px;
        }
        .stu_list::-webkit-scrollbar-thumb {
            background-color: #d9d9d9;
            border-radius: 0 10px 10px 0;
        }

        .point_title {
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            > div {
                display: flex;
                align-items: center;
                h1 {
                    font-size: 20px;
                    color: #1e3560;
                    font-weight: bold;
                    line-height: 40px;
                    text-align: left;
                    margin-right: 10px;
                }
                p {
                    max-width: 510px;
                }
            }
            button {
                // margin-top: 20px;
                width: 124px;
                height: 27px;
                background-color: #1e3560;
                color: #fff;
                border-radius: 30px;
            }
        }
        .point_box {
            background-color: #f2f2f2;
            border-radius: 10px;
            margin-top: 10px;
            flex-direction: column;
            height: 350px;
            overflow-x: none;
            overflow-y: auto;
            > div {
                width: 100%;
                display: flex;
                cursor: pointer;
                font-size: 15px;
                align-items: center;
                height: 30px;
                div.point_name {
                    justify-content: left;
                    flex: 5;
                    > div {
                        height: 100%;
                        border: none;
                        box-sizing: border-box;
                        button {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            // width: 100%;
                            // height: 100%;
                            background-color: transparent;
                            font-size: 20px;
                            font-weight: bold;
                            color: rgb(6, 139, 119);
                            &[selected="true"] {
                                color: yellow;
                            }
                        }
                    }
                    > p {
                        width: 90%;
                        text-align: left;
                    }
                }
                div:nth-last-child(1) {
                    flex: 1.5;
                }
                div {
                    height: 100%;
                    padding: 5px;
                    flex: 1;
                    border-right: 1px solid #9c9c9c;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                div:nth-last-child(1) {
                    border: none;
                }
                button {
                    width: 50px;
                    height: 20px;
                    font-size: 13px;
                    &:nth-of-type(2) {
                        margin-left: 5px;
                        background: rgb(203, 50, 8);
                    }
                }
                &:hover {
                    background-color: #e7fcff;
                }
            }

            li {
                .point_name {
                    flex: 5;
                }
                div:nth-last-child(1) {
                    flex: 1.5;
                }
            }
            div.checkbox {
                flex: 0.5;
            }
        }
        .point_box::-webkit-scrollbar {
            width: 16px;
        }
        .point_box::-webkit-scrollbar-thumb {
            background-color: #d9d9d9;
            border-radius: 0 10px 10px 0;
        }
        .point_box::-webkit-scrollbar-track {
            background-color: #f2f2f2;
            border-radius: 0 10px 10px 0;
        }
        .submit_box {
            margin-top: 10px;
            display: flex;
            justify-content: center;
            input:nth-child(1) {
                margin-right: 10px;
            }
            input {
                width: 170px;
                height: 40px;
                color: #fff;
                border: none;
            }
            input:nth-child(1) {
                background-color: #9c9c9c;
            }
            input:nth-child(2) {
                background-color: #1e3560;
            }
        }
        .content_box {
            margin-top: 10px;
            margin-left: 10px;
            > div {
                display: flex;
                align-items: center;
                > div {
                    margin: 10px;
                }
                p {
                    color: #1e3560;
                    font-weight: bold;
                }
                input {
                    cursor: text;
                    height: 25px;
                    &:nth-of-type(1) {
                        width: 300px;
                        margin-right: 10px;
                    }
                    &:nth-of-type(2) {
                        width: 50px;
                    }
                }
            }
        }
    }

    input {
        text-align: center;
        border: 1px solid #9c9c9c;
        border-radius: 20px;
        font-family: "nanum";
        font-size: 16px;
        cursor: pointer;
    }

    button {
        width: 170px;
        height: 40px;
        border-radius: 20px;
        border: none;
        color: #fff;
        font-size: 16px;
        background-color: #1e3560;
    }

    input[type="checkbox"] {
        width: 13px;
        height: 13px;
        // border-color: #6c6c6c;
        // accent-color: hsl(250, 100%, 34%);
    }
}
</style>