<template>
    <section class="list_main">
        <div class="search_box">
            <search-bar :placeholder_text="'학생 이름 검색'" @search="(s) => (this.search_keyword = s)" />
        </div>
        <div class="list_box">
            <table>
                <template v-for="student in this.getList" :key="student.id">
                    <tr v-show="student.name.indexOf(this.search_keyword) !== -1" @click="this.setStudent(student)" :selected="this.student?.id == student.id">
                        <td>{{ student.name ?? "-" }}</td>
                        <td>{{ student.id ?? "-" }}</td>
                        <td colspan="2">{{ this.getSchoolInfo(student) }}</td>
                    </tr>
                </template>
            </table>
        </div>
    </section>
</template>

<script>
// <StudentList :students="this.students" @select="(student) => this.setStudent(student)" />

import SearchBar from "@/components/common/SearchBar.vue";
import CommonGrade from "@/components/common/CommonGrade";
export default {
    name: "StudentList",
    components: {
        SearchBar,
    },
    props: {
        students: Array,
        setId: String,
    },
    mixins: [CommonGrade],
    emits: ["select"],
    data() {
        return {
            student: null,
            search_keyword: "",
        };
    },
    methods: {
        setStudent(student) {
            this.student = student;
            this.$emit("select", student);
        },
        getSchoolInfo(student) {
            const grade = this.gradeValue(student.grade) ?? null;
            const school = student.school_name ?? null;
            if (grade && school) {
                return school + " " + grade;
            } else {
                return "-";
            }
        },
    },
    computed: {
        getList() {
            let student_list = [];
            student_list = this.students.map((e) => {
                if (!e.id) e.id = e.student_id;
                if (!e.name) e.name = e.student_name;
                if(this.setId && e.id == this.setId) this.student = e;
                return e;
            });
            return student_list;
        },
    },
};
</script>

<style lang="scss" scoped>
.list_main {
    position: relative;
    width: 100%;
    height: 100%;
}
.search_box {
    width: 100%;
    margin-bottom: 10px;
}
.list_box {
    position: relative;
    width: 100%;
    height: calc(100% - 40px);
    overflow-y: scroll;
    background-color: #f2f2f2;
    table {
        width: 100%;
        height: fit-content;
        border-collapse: separate;
        border-spacing: 0 10px;
        table-layout: fixed;
        tr {
            cursor: pointer;
            &[selected="true"] {
                background-color: #f7fcff;
            }
            &:hover{
                background-color: #fff;
            }
        }
        td {
            padding: 3px 5px;
            border-right: 1px solid #9c9c9c;
            word-break: break-all;
            &:nth-of-type(1) {
                color: var(--color-sparta);
                font-weight: bold;
            }
            &:nth-last-of-type(1) {
                border: none;
                text-align: left;
                // padding-left: 10px;
            }
        }
    }
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #9c9c9c;
    }
    &::-webkit-scrollbar-track {
        background-color: #d9d9d9;
    }
}
</style>