import AppManager from "@/apps/AppManager";

const routes = [
    {
        path: "/manager",
        name: "AppManager",
        component: AppManager,
        redirect: "/manager/main",
        children: [
            {
                path: "/manager/main",
                component: () => import("../components/manager/ManagerMain.vue")
            },
            {
                path: "/manager/record",
                component: () => import("../components/manager/ManagerRecordMain.vue"),
                props: {
                    planner: true
                }
            },
            {
                path: "/manager/planner",
                component: () => import("../components/manager/ManagerPlanner.vue")
            },
            {
                path: "/manager/counsel",
                component: () => import("../components/manager/ManagerCounsel.vue")
            },

            {
                path: "/manager/employee",
                component: () => import("../components/manager/ManagerEmployee.vue")
            },
            {
                path: "/manager/archive",
                component: () => import("../components/manager/ManagerArchive.vue")
            },
            {
                path: "/manager/sms",
                name: "/manager/sms",
                component: () => import("@/components/admin/sms")
            },
            {
                path: "/manager/center",
                component: () => import("@/components/common/CenterNotify/CenterNotify")
            },
            {
                path: "/manager/schedule",
                component: () => import("../components/manager/ManagerSchedule.vue")
            },
            {
                path: "/manager/account",
                component: () => import("@/components/manager/account/ManagerAccountEdit.vue")
            },
            {
                path: "/manager/employee_schedule",
                component : ()=> import("@/components/common/EmployeeSchedule.vue"),
            },
        ]
    }
];

export default routes;
