<template>
    <div id="report_outcomePop">
        <div class="content_in">
            <h1>
                학생 보고 대응 결과
            </h1>
            <div>
                <ManagerStudentBar :name="important?.student_name" :id="important?.student_id" :school="important?.school_name" :grade="important?.grade" />
            </div>
            <div>
                <h4>보고 요약</h4>
                <p>{{important?.title ?? "-"}}</p>
            </div>
            <div>
                <h4>긴급성</h4>
                <p>{{priority}}</p>
            </div>
            <div>
                <h4>보고 선생님</h4>
                <p>{{important?.report_teacher_name ?? "-"}}</p>
            </div>
            <div>
                <h4>보고 일시</h4>
                <p>{{reportDate}}</p>
            </div>
            <div>
                <h4>보고 세부 내용</h4>
                <textarea readonly="true" v-model="reportContent"></textarea>
            </div>
            <div>
                <h4>대응 세부 내용</h4>
                <textarea readonly="" v-model="responseContent"></textarea>
            </div>
            <div>
                <!-- <input type="button" value="닫 기" @click="onClose"> -->
            </div>
            <div id="buttons">
                <button class="no" @click="onClose">닫기</button>
                <await-button class="yes" @click="onSave">대응 확인</await-button>
            </div>
        </div>
    </div>
</template>

<style scoped>

* {
    text-align: initial;
}

/*#manageMain .pops */#report_outcomePop { /* display: none; */ width: 510px; background-color: #fff; border-radius: 15px; position: fixed; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); padding: 30px; -webkit-box-sizing: border-box; box-sizing: border-box; color: #6c6c6c; z-index: 106; }

/*#manageMain .pops */#report_outcomePop .content_in { width: 450px; margin: 0 auto; }

/*#manageMain .pops */#report_outcomePop .content_in h1 { color: #1e3560; font-size: 24px; }

/*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(1) { padding: 20px 0; border-bottom: 1px solid #9c9c9c; display: -webkit-box; display: -ms-flexbox; display: flex; margin-bottom: 20px; }

/*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(1) p { margin-left: 20px; position: relative; }

/*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(1) p::before { position: absolute; content: ''; height: 80%; width: 1px; background-color: #9c9c9c; top: 50%; -webkit-transform: translateY(-50%); transform: translateY(-50%); left: -10px; }

/*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(1) p:nth-child(1) { color: #1e3560; font-weight: bold; margin-left: 0px; }

/*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(1) p:nth-child(1)::before { display: none; }

/*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(2) h4 { color: #1e3560; font-weight: bold; margin-bottom: 10px; }

/*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(2) p { margin-bottom: 20px; }

/*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(3), /*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(4), /*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(5) { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; margin-bottom: 20px; }

/*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(3) h4, /*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(4) h4, /*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(5) h4 { color: #1e3560; font-weight: bold; margin-right: 20px; }

/*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(3) p, /*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(4) p, /*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(5) p { margin: 0 23px 0 5px; }

/*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(6) { margin-bottom: 20px; }

/*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(6) h4, /*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(7) h4 { color: #1e3560; font-weight: bold; margin-bottom: 10px; }

/*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(6) textarea, /*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(7) textarea { width: 450px; height: 125px; padding: 15px; font-size: 16px; }

/*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(8) { width: 170px; margin: 0 auto; margin-top: 20px; }

/*#manageMain .pops */#report_outcomePop .content_in > div:nth-of-type(8) input { border-radius: 50px; width: 170px; height: 40px; background-color: #1e3560; }

textarea {    
    resize: none;
    border: 1px solid #9c9c9c;
    outline: none;
    color: #6c6c6c;
    padding: 15px;
    box-sizing: border-box;
    line-height: 22px;
}

input[type="button"] {
    border: none;
    color: #fff;
    background-color: #9c9c9c;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
}


div#buttons {
	width: 364px;
	height: 40px;
	margin: 0 auto;
	display: flex;
    justify-content: space-between;
}

div#buttons button {
    width: 170px;
    border-radius: 50px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    border: none;
    text-align: center;    
}

button.no {
    background-color: #9c9c9c;
}
button.yes {
    background-color: #1E3560;
}

</style>

<script>
import ManagerStudentBar from "@/components/manager/Common/ManagerStudentBar.vue"
import ManagerBasePopupVue from '../ManagerBasePopup.vue';
import CommonData from '@/components/common/data/CommonData';
import { ApiReq, Utils } from '../ManagerUtils';

export default {
    mixins : [ManagerBasePopupVue],
    components : {
        ManagerStudentBar
    },
    props : {
        important : Object ///reports/important
    },
    data() {
        return {
            
        };
    },
    computed : {
        priority() {
            let value = this.important?.priority;

            return CommonData.student_report_priority_type[value] ?? "-";
        },
        reportDate() {
            return this.important?.report_datetime ?? "-";
        },
        reportContent() {
            return this.important?.report_content ?? "";
        },
        responseContent() {
            return this.important?.response_content ?? "";
        }
    },
    methods : {
        async onSave() {            
            const no = this.important?.no;

            if(!no) {                
                Utils.showTextModal("보고 내용을 찾을 수 없습니다.");
                return;
            }

            let data = {
                status : CommonData.important_report_status.done,
            };

            let ret = await new ApiReq("/report/important/" +no, data).put();

            if(ret == true) {
                this.onResultOk();
            }
        }
    }
}
</script>