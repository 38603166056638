<template>
    <article class="leftwidget notify_min">
        <div class="notify_min_header">
            <h2>
                <p>공지 및 업무 주요사항</p>
            </h2>
            <section id="select_theme">
                <select-list v-model:pSelectedData="selectedType" :pVisibleCount="5" :pListItemArray="typeArray" />
            </section>
        </div>
        <div class="notify_min_list">
            <ul v-if="noticeArray?.length">
                <li v-for="noti in noticeArray" :key="noti">
                    <div>
                        <dl>
                            <dt class="notice_popup" @click="this.open_modal(noti)">
                                <div>{{ noti?.type_name }} {{ this.get_status_str(noti) }}</div>
                                <div>확인</div>
                            </dt>
                            <dd>{{ noti?.title }}</dd>
                        </dl>
                    </div>
                </li>
            </ul>
            <ul v-else class="empty_noti">
                <li>주요사항이 없습니다.</li>
            </ul>
        </div>
    </article>
</template>

<script>
import jwtService from "@/services/jwt.service";
import { ApiReq, Utils } from "../manager/ManagerUtils";
import FrontUtils from "../front/FrontJs/FrontUtils";
import CommonData from "./data/CommonData";
export default {
    name: "WidgetNotice",
    data() {
        return {
            myinfo: null,
            srcTypes: null,
            srcNotices: null,

            selectedType: null,
        };
    },
    methods: {
        async init(showError = true) {
            const center_no = this.myinfo?.center_no;
            const id = this.myinfo?.userid;

            if (!center_no || !id) {
                this.$ModalManager.showdialog("센터정보가 없습니다.");
                return;
            }

            let retTypes = await new ApiReq("/notice/center/types")
                .setResultCallback((res) => {
                    this.srcTypes = res;
                    return res instanceof Array;
                })
                .get();

            let srcDate = new Date();

            let start = Utils.dateObjectToDate26(srcDate);
            srcDate.setDate(srcDate.getDate() + 1);
            let end = Utils.dateObjectToDate26(srcDate);

            const p = {
                // ctr: center_no,
                begin: start,
                end: end,
                to: id,
            };

            let retNotices = await new ApiReq("/notices/center", p)
                .setResultCallback((res) => {
                    this.srcNotices = res;
                    return res instanceof Array;
                })
                .get();

            if (!retTypes || !retNotices) {
                if (showError) this.$ModalManager.showdialog("데이터를 불러오는데 실패하였습니다.");
            }
        },
        async open_modal(noti) {
            const id = this.myinfo?.userid;

            const isMine = noti.recipients.find((receiver) => {
                if (receiver.teacher_id != id) return false;
                if (receiver.status == CommonData.notice_status.none) {
                    return true;
                }
                return false;
            });
            // 미확인한 공지사항 및 업무전달 상태 변경.
            if (isMine) {
                const url = "/notice/center/" + noti?.no + "/" + id;
                await new ApiReq(url, { status: CommonData.notice_status.read })
                    .setFailMsg("수신자 상태 변경에 실패하였습니다.")
                    .put()
                    .then((res) => {
                        if (res == true) {
                            isMine.status = String(CommonData.notice_status.read);
                            this.$EventManager.EmitTo("center_notice_event", noti);
                        }
                        this.init(false);
                    });
            }

            let type_no = noti.type_no;

            const type = this.typeArray?.find((element) => {
                return element.value?.no == type_no;
            });

            let work_flag = type?.value?.no == 2 || type?.value?.parent_no == 2;

            this.$ModalManager.showEx("notice_modal", {
                bind: {
                    notice_data: noti,
                    work_flag: work_flag,
                },
            });
        },
        ws_receive(p) {
            // this.$ModalManager.showdialog("새로운 주요사항이 있습니다.");
            this.init(false);

            const scroll_div = document.querySelector("div.notify_min_list");
            if (scroll_div) scroll_div.scrollTop = 0;
            this.$EventManager.EmitTo("center_notice_event", p?.message?.data);
        },
        get_status_str(noti) {
            const isMine = noti.recipients.find((receiver) => receiver.teacher_id == this.myinfo?.userid);
            if (isMine?.status == CommonData.notice_status.none) return "- 미확인";
            else if (isMine?.progress_status_no == 1) return "- " + isMine?.progress_status_name;
        },
    },
    computed: {
        noticeArray() {
            const id = this.myinfo?.userid;

            if (!id) return [];

            const type = this.selectedType?.value;

            const array = this.srcNotices?.filter((element) => {
                let elementType = this.typeArray.find((type) => type.value?.no == element.type_no);

                let isWork = elementType.value.no == 2 || elementType.value.parent_no == 2;

                const isMine = element.recipients.find((receiver) => {
                    if (receiver.teacher_id != id) return false;

                    if (receiver.status == CommonData.notice_status.none || (isWork && receiver.progress_status_no == 1)) {
                        return true;
                    }

                    return false;
                });

                if (isMine) {
                    if (type === 0) return true;
                    return element.type_no == type.no;
                }

                return false;
            });

            return array;
        },
        typeArray() {
            let types_arr = [];
            types_arr.push({ description: "전체보기", value: 0 });

            let map = new Map();

            let parents = new Array();

            this.srcTypes?.forEach((element) => {
                if (!element.parent_no) {
                    parents.push(element);
                } else {
                    const key = element.parent_no;

                    if (map.has(key)) {
                        map.get(key).push(element);
                    } else {
                        map.set(key, [element]);
                    }
                }
            });

            parents.forEach((parent) => {
                types_arr.push({
                    isGroup: true,
                    // value : parent,
                    description: parent?.name,
                });

                const key = parent.no;

                map.get(key)?.forEach((child) => {
                    types_arr.push({
                        value: child,
                        description: child.name,
                    });
                });
            });

            return types_arr;
        },
    },
    watch: {
        typeArray() {
            const curType = this.selectedType;

            if (curType) {
                this.selectedType = this.typeArray.find((element) => element?.description == curType?.description);
            } else {
                this.selectedType = this.typeArray?.[0];
            }
        },
    },
    created() {
        this.myinfo = jwtService.getUserInfo();

        this.init();
        this.$EventManager.AddListener("center_notice_widget_event", "CenterNotify", () => this.init(false));
        this.$WSSocket.AddMessageHandler("msg/center_notice", this.ws_receive);
    },
    unmounted() {
        this.$EventManager.RemoveListener("center_notice_widget_event");
        this.$WSSocket.RemoveMessageHandler("msg/center_notice", this.ws_receive);
    },
};
</script>

<style scoped>
* {
    user-select: none;
}
.notify_min {
    width: 273px;
    border-radius: 15px;
    background-color: #fff;
    position: relative;
    padding-bottom: 15px;
}

.notify_min_header {
    padding-top: 15px;
    padding-bottom: 10px;
    width: 233px;
    margin: 0 auto;
    box-sizing: border-box;
    font-size: 18px;
}

.notify_min_header h2 p {
    font-size: 18px;
    font-weight: bold;
    color: #1e3560;
    text-align: left;
}
#select_theme {
    margin-top: 10px;
}
.notify_min_list {
    margin: 0 auto;
    top: 97px;
    width: 233px;
    height: 150px;
    overflow-y: scroll;
    padding-right: 10px;
    box-sizing: border-box;
    z-index: 10;
}
.notify_min_list > ul > li {
    font-size: 16px;
}
.notify_min_list::-webkit-scrollbar {
    width: 16px;
}
.notify_min_list::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
}
.notify_min_list::-webkit-scrollbar-track {
    background-color: #f2f2f2;
}
.notify_min_list > ul > li dl {
    border-bottom: 1px solid #9c9c9c;
}
.notify_min_list > ul > li dl dt {
    font-size: 14px;
    margin-bottom: 10px;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.notify_min_list > ul > li:first-child dl dt {
    padding-top: 0;
}
.notify_min_list > ul > li dl dt div:nth-child(1) {
    font-size: 14px;
    font-weight: bold;
    color: #6c6c6c;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.notify_min_list > ul > li dl dt div:nth-child(2) {
    font-size: 11px;
    width: 34px;
    height: 16px;
    border-radius: 50px;
    background-color: #1e3560;
    color: #fff;
    line-height: 16px;
    text-align: center;
    padding: 2px;
}
.notify_min_list > ul > li dl dd {
    font-size: 16px;
    margin-bottom: 15px;
    color: #6c6c6c;
    text-align: left;
}
/* notify_min end */

/* enterleave_favor */
.aside_in > article:nth-child(3) {
    width: 273px;
    height: 200px;
    border-radius: 15px;
    background-color: #fff;
    position: relative;
    margin-top: 35px;
}

.enterleave_favor h3 {
    position: fixed;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 52px;
    padding-left: 20px;
    box-sizing: border-box;
    font-size: 18px;
    color: #1e3560;
}
.enterleave_favor h3 p {
    font-size: 18px;
    font-weight: bold;
}
.enterleave_favor_main {
    position: absolute;
    top: 55px;
    width: 257px;
    height: 120px;
    overflow-y: scroll;
    padding-left: 20px;
    box-sizing: border-box;
}
.enterleave_favor_main > ul > li span {
    margin-right: 10px;
}
.enterleave_favor_main > ul > li {
    font-size: 16px;
    margin-bottom: 6px;
    color: #6c6c6c;
}
.enterleave_favor_main::-webkit-scrollbar {
    width: 16px;
}
.enterleave_favor_main::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
}
.enterleave_favor_main::-webkit-scrollbar-track {
    background-color: #f2f2f2;
}

.notice_popup {
    cursor: pointer;
}

.empty_noti {
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.empty_noti li {
    font-size: 18px;
    color: #6c6c6c;
}
</style>