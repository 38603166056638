<template>
    <article class="record_pop_header">
        <div>                
            <p>{{name ?? '-'}}</p>
            <span>{{id ?? '-'}}</span>
            <span>{{school ?? "-"}} {{gradeValue(grade)}}</span>
        </div>
    </article>
</template>

<script>
import CommonGradeN from '@/components/common/CommonGrade';

export default {
    mixins : [
        CommonGradeN
    ],
    props : {
        name : String,
        id : String,
        school : String,
        grade : String
    }
}
</script>

<style scoped>


.record_pop_header { padding: 0 0; }

.record_pop_header p { color: #1E3560; font-weight: bold; }

.record_pop_header div { display: -webkit-box; display: -ms-flexbox; display: flex; position: relative; }

.record_pop_header span { margin-left: 20px;}

/* .record_pop_header span::before { content: ''; top: 50%; -webkit-transform: translateY(-50%); transform: translateY(-50%); width: 1px; height: 80%; background-color: #9c9c9c; } */

.record_pop_header span::before { content: ''; position: absolute; top: 50%; -webkit-transform: translateY(-50%); transform: translateY(-50%); margin-left: -10px; width: 1px; height: 80%; background-color: #9c9c9c; }

</style>