import {ROLE} from "@/js/util/common";

const ID_ACCESS_TOKEN = "id_sparta_access_token";
const ID_REFRESH_TOKEN = "id_sparta_refresh_token";
const ID_USER_INFO = "id_sparta_user_info";
const ID_COMPANY_ADMIN_CENTER_INFO = "id_company_admin_center_info";


// token
const getAccessToken = () => {
  var at = window.localStorage.getItem(ID_ACCESS_TOKEN);
  if(at == undefined) at= "";
  return at;
};

const setAccessToken = token => {
  window.localStorage.setItem(ID_ACCESS_TOKEN, token);
};

const deleteCompanyAdminCenterInfo = () => {
  window.sessionStorage.removeItem(ID_COMPANY_ADMIN_CENTER_INFO);
}

const setCompanyAdminCenterInfo = (centerinfo) => {
  window.sessionStorage.setItem(ID_COMPANY_ADMIN_CENTER_INFO, JSON.stringify(centerinfo));
}

const getCompanyAdminCenterInfo = ()=> {
  var centerinfo = window.sessionStorage.getItem(ID_COMPANY_ADMIN_CENTER_INFO);
  if(!centerinfo)
  {
    return {
      no : -1,
      name : "",
    }
  }
  return JSON.parse(centerinfo);
}

const deleteAccessToken = () => {
  window.localStorage.removeItem(ID_ACCESS_TOKEN);
};

const getRefreshToken = () => {
  var rt = window.localStorage.getItem(ID_REFRESH_TOKEN);
  if(rt == undefined) rt= "";
  return rt;
}

const setRefreshToken = token => {
  window.localStorage.setItem(ID_REFRESH_TOKEN, token);
}

const deleteRefreshToken = () => {
  window.localStorage.removeItem(ID_REFRESH_TOKEN);
}

const deleteAllToken = () => {
  deleteAccessToken();
  deleteRefreshToken();
}

//username
const getUserInfo = () => {
  const user_info = JSON.parse(window.localStorage.getItem(ID_USER_INFO));
   // company admin
  if(user_info?.role == ROLE.COMPANYADMIN || user_info?.role == ROLE.ADMIN || user_info?.role == ROLE.FRANCHISEADMIN){
    user_info.is_admin = true;
    user_info.center_no = getCompanyAdminCenterInfo().no;
  }
    
  return user_info;
};

const setUserInfo = data => {
    console.log(data);
  data = JSON.stringify(data);
  window.localStorage.setItem(ID_USER_INFO, data);
};

const deleteUserInfo = () => {
  window.localStorage.removeItem(ID_USER_INFO);
};

export default { 
  getAccessToken, setAccessToken, deleteAccessToken,
  getRefreshToken, setRefreshToken, deleteRefreshToken,
  deleteAllToken,
  getUserInfo, setUserInfo, deleteUserInfo,
  setCompanyAdminCenterInfo,  getCompanyAdminCenterInfo,  deleteCompanyAdminCenterInfo,
};