import { ROLE } from "@/js/util/common";
import { date_format, num_format } from "@/js/util/math";
import ApiService from "@/services/api.service";
import jwtService from "@/services/jwt.service";
import CommonData from "../common/data/CommonData";

const debug = false;

export function planerFinishCheckValue(values) {   
    let total = values.reduce((sum, value) => {
        let v = Number(value);

        if(isNaN(v))
            return sum;

        return sum + v;
    }, 0);            
            
    let avg = total / values.length;

    if(avg == 0)
        return "-";
    
    if(avg > CommonData.planer_alert_value) {
        return "하";
    } else if(avg >= 1.5) {
        return "중";
    } else
        return "상";
}

export function defaultPageParam(map) {
    return {
        per_page : 500,
        ...map
    };
}
/*
function queryParam(map) {
    var queryStr = '';

    for(var key in map) {
        if(key && map[key]) {
            queryStr += key + '=' + map[key] + '&';
        }
    }

    if(queryStr.length > 0) {
        queryStr = queryStr.substring(0, queryStr.length - 1);
        return '?' + queryStr;
    }

    return '';
}*/

export class ApiReq {

    static paramCenterNo(myinfo, path, params, config) {
        let centerNo = myinfo?.center_no;
        if(myinfo.type == ROLE.COMPANYADMIN || myinfo.type == ROLE.ADMIN || myinfo.type == ROLE.FRANCHISEADMIN)
        // if(myinfo.type == ROLE.COMPANYADMIN)
        {
            centerNo = jwtService.getCompanyAdminCenterInfo().no;
        }

        if(!centerNo && ROLE.ADMIN != myinfo?.type) {
            Utils.showErrorModal("센터 정보가 없습니다.");
            return null;
        }

        return new ApiReq(path, {ctr:centerNo, ...params}, config);
    }

    constructor(path, params, config) {
        this.path = path;
        this.params = params;
        this.config = config;
    }

    //func : (data) => {} return false 시 실패, return true or null 이면 성공
    setResultCallback(func) {
        this.resultCallback = func;
        return this;
    }

    setFailCallback(func) {
        this.failCallback = func;
        return this;
    }

    setCompleteMsg(msg) {
        this.completeMsg = msg;
        return this;
    }

    setFailMsg(msg) {
        this.failMsg = msg;
        return this;
    }

    setExceptionMsg(msg) {
        this.exceptionMsg = msg;
        return this;
    }

    async get() {
        return this._call(ApiService.GET);
    }

    async put() {
        return this._call(ApiService.PUT);
    }

    async post() {
        return this._call(ApiService.POST);
    }

    async delete() {
        return this._call(ApiService.DELETE);
    }

    async postFile(){
        return this._call(ApiService.POSTFILE);
    }

    async _call(method) {

        const path = this.path;
        const params = this.params;
        const config = this.config;

        const resultCallback = this.resultCallback;
        const failCallback = this.failCallback;

        const completeMsg = this.completeMsg;
        const failMsg = this.failMsg;
        const exceptionMsg = this.exceptionMsg;

        try {            
            Utils.log("path : " + path);
            if(params)
                Utils.log("params : " + JSON.stringify(params));

            let ret = await ApiService.call(method, path, params, config);

            if(ret.data instanceof Array && ret.data.length > 0)
                Utils.log("ret : [" + JSON.stringify(ret.data[0]) + ", ...]");
            else 
                Utils.log("ret : " + JSON.stringify(ret.data));

            
            let success;

            if(resultCallback) {
                let callbackRet = await resultCallback(ret.data, ret.headers);

                success = ((callbackRet ?? true) != false);
            } else {
                success = ret.data?.result == true;
            }

            if(success) {
                if(completeMsg) {
                    await Utils.showTextModal(completeMsg);
                }
            } else {
                if(!debug) {
                    Utils.log("path : " + path, true);
                    if(params)
                        Utils.log("params : " + JSON.stringify(params), true);
                        
                    Utils.log("ret : " + JSON.stringify(ret.data), true);
                }

                if(failMsg) {
                    await Utils.showErrorModal(failMsg);
                }

                if(failCallback)
                    await failCallback();
            }
            
            return success;
        } catch(e) {
            let data = e.response?.data;
            if(data) {
                console.log(data);
            } else {
                console.log(e);
            }

            if(exceptionMsg) {
                await Utils.showErrorModal(exceptionMsg, e);
            }
            else if(failMsg) {
                await Utils.showErrorModal(failMsg, e);
            }
        }

        if(failCallback)
            failCallback();

        return false;
    }
}

class Utils {
    static _modalManager;

    static setModalManager(modalManager) {
        // this.log("setModalManager " + modalManager);
        Utils._modalManager = modalManager;
    }

    static log(log, force) {
        if(debug || force) {

            let date = new Date();

            let msg = date_format(date, "hh:mm:ss");
            msg += " : " + log;

            let err = new Error();
            let temp = err.stack?.split("\n");

            if(temp?.length > 2) {
                let start = temp[2].indexOf("(");

                if(start != -1) {
                    console.log(msg + " " + temp[2].substring(start));
                    return;    
                }
            }
            
            console.log(msg);
        }
    }

    static showErrorModal(msg, e) {
        if(e)
            console.log(e);

        return this.showTextModal(msg);
    }

    static showTextModal(msg) {
        if(!Utils._modalManager)
            return false;

        return Utils._modalManager.showdialog({
            msg: msg,
            buttons: {
              yes: "확인",
            },
          });
    }

    static async showYesNo(msg, yes, no) {
        let ret = await Utils._modalManager.showdialog({
            msg: msg,
            buttons: {
              yes: yes,
              no: no
            }
          });

        return ret?.result == "yes";
    }

    static async post(path, params, config, qresolve, qreject) {
        return await Utils._call(ApiService.POST, path, params, config, qresolve, qreject);
    }

    static async get(path, params, config, qresolve, qreject) {
        return await Utils._call(ApiService.GET, path, params, config, qresolve, qreject);
    }

    static async put(path, params, config, qresolve, qreject) {
        return await Utils._call(ApiService.PUT, path, params, config, qresolve, qreject);
    }

    static async delete(path, params, config, qresolve, qreject) {
        return await Utils._call(ApiService.DELETE, path, params, config, qresolve, qreject);
    }

    static async _call(method, path, params, config, qresolve, qreject) {
        try {            
            Utils.log("path : " + path);
            if(params)
                Utils.log("params : " + JSON.stringify(params));

            let ret = await ApiService.call(method, path, params, config, qresolve, qreject);

            if(ret.data instanceof Array)
                Utils.log("ret : " + ret.data.length + " [" + JSON.stringify(ret.data[0] ?? "") + ", ...]");
            else 
                Utils.log("ret : " + JSON.stringify(ret.data));

            return ret;
        } catch(e) {
            let data = e.response?.data;
            if(data) {
                console.log(data);
            } else {
                console.log(e);
            }
            throw e;
        }
    }

    
    static getWeekNumber(date = new Date()) {
        let currentdate = date;
        let oneJan = new Date(currentdate.getFullYear(),0,1);
        let numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
        let result = Math.ceil(numberOfDays / 7);
        return result;
    }

    static getDayCount(date, target = new Date()) {
        if(!date || !target)
            return "-";

        if(isNaN(date) || isNaN(target))
            return "-";

        let temp = 1000 * 60 * 60 * 24;

        return "+" + Math.ceil( (target.getTime() - date.getTime()) / temp);
    }

    static dateStr26ToObject(dateStr) {
        if(!dateStr)
            return null;

        // "2022-05-18 12:00:00"

        let sp = dateStr.split(" ");

        let date = new Date(sp[0]);
        

        sp = sp[1].split(":");

        let h = sp[0];
        let m = sp[1];
        let s = sp[2];

        date.setHours(h, m, s);

        if(isNaN(date)) {
            return null;
        } else {
            return date;
        }
    }

    static timeStr26ToObject(dateStr) {
        if(!dateStr)
            return null;
            
        let sp = dateStr.split(":");

        let h = sp[0];
        let m = sp[1];
        let s = sp[2];

        return Utils.Time26ToObject(h, m, s);
    }

    static Time26ToObject(hour, min, second) {
        if(!hour || !min)
            return null;        

        let date = new Date();

        let h = date.getHours();
        if(0 <= h && h <= 3) {
            date.setDate(date.getDate() - 1);
        }

        date.setHours(0, 0, 0, 0);        
        date.setHours(hour, min, second ?? 0);
        
        return date;
    }

    static dateObjectToTime26WithoutSec(date) {
        let ret = Utils.dateObjectToTime26(date);

        if(ret) {
            let index = ret.lastIndexOf(":");

            if(index != -1) {
                return ret.substring(0, index);
            }
        }

        return ret;
    }

    static dateObjectToDateTime26(srcDate) {
        if(!srcDate || isNaN(srcDate))
            return null;

        let h = srcDate.getHours();
        let date = new Date(srcDate);

        if(0 <= h && h <= 3) {
            date.setDate(date.getDate() - 1);
            h += 24;
        }
        
        let m = date.getMinutes();
        let s = date.getSeconds();

        return date.getFullYear() + "-" + num_format(date.getMonth() + 1, 2) + "-" + num_format(date.getDate(), 2) + " " + num_format(h, 2) + ":" + num_format(m, 2) + ":" + num_format(s, 2);
    }

    static dateObjectToDate26(date) {
        return Utils.dateObjectToDateTime26(date)?.split(" ")[0];
    }

    static dateObjectToTime26(date) {
        return Utils.dateObjectToDateTime26(date)?.split(" ")[1];
    }
}

export { Utils };