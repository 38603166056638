import axios from "axios";

const FingerPrint = {
    socket: null,
    msg_handler: null,
    fingerlogin_handler : null,
    onopen_handler : null,
    onerror_handler : null,
    status : "conneting",
    forcestop : false,
    reconnet_id : null,
    Connect: function () {
        const _this = this;
        _this.forcestop = false;
        this.socket = new WebSocket("ws://localhost:9158/websocket");
        const socket = this.socket;
        socket.onopen = () => {
            console.info("Fingerprint socket connected");
            if(_this.onopen_handler)
                _this.onopen_handler();
            
            this.AskStatus();
        };

        socket.onmessage = (ev) => {
            // console.log(ev);
            if (this.msg_handler != null) {
                var msg = JSON.parse(ev.data);
                if(!msg) return;
                switch(msg.command)
                {
                    case "res_fingerprint_status":
                        this.status = msg.status;
                        break;
                    case "req_login":
                        if(this.fingerlogin_handler != null)
                            this.fingerlogin_handler(msg);
                        break;
                }
                if(msg.command == "res_fingerprint_status")
                {
                    this.status = msg.status;
                    // console.log(this.status);
                } 
                this.msg_handler(msg);
            }
        };


        socket.onerror = function (error) {
            console.log(
                "FingerPrint",
                "Failed to connect to module, It will try to reconnect in 5 seconds"
            );

            if(this.onerror_handler)
                this.onerror_handler(error);

            // console.log(error);
            this.socket = null;
            clearTimeout(this.reconnet_id);
            this.reconnet_id = setTimeout(() => {
                if(!_this.forcestop)
                    _this.Connect();
            }, 5 * 1000);
        };

        socket.onclose = function(ev) {
            _this.StopService();
        }
    },

    Close: function () {
        console.log("finger ws closed");
        this.msg_handler = null;
        this.fingerlogin_handler = null;
        this.onopen_handler = null;
        this.onerror_handler = null;
        this.forcestop = true;
        this.StopService();
        this.socket.close();
    },

    AskStatus: function () //monitoring
    {
        const _this = this;
        if (this.socket && this.socket.readyState == this.socket.OPEN) {
            const request = {
                command: "req_status",
            };
            this.Send(request);

            setTimeout(() => {
                _this.AskStatus();
            }, 1 * 1000);
        }
    },

    Send: function (data) {
        if (this.socket && this.socket.readyState == this.socket.OPEN)
            this.socket.send(JSON.stringify(data));
    },

    QueryExist: function (userno) {
        const request = {
            command: "req_query_exist",
            params: {
                userno: Number(userno),
            },
        };
        this.Send(request);
    },

    Register : function(userno)
    {
        const request = {
            command : "req_register",
            params : {
                userno : Number(userno),
            }
        };
        this.Send(request);
    },

    StartService : function()
    {
        this.Send({command : "req_start_service"});
    },

    StopService : function()
    {
        this.Send({command : "req_stop_service"});

    },

    Exist : async function(user_no)
    {
        const req = {
            member_no : user_no
        };

        var r = false;
        r = await this.RestCall("exist", req);

        return r;
    },

    Remove : async function(user_no)
    {
        const req = {
            member_no : user_no
        };

        var r = await this.RestCall("remove", req);

        return r;
    },

    call_running : false,

    async RestCall(command, params)
    {
        const _this = this;
        // if(this.call_running)
        // {
        //     setTimeout((command, params) => {
        //         _this.RestCall(command, params);
        //     }, 1);

        //     return null;
        // }

        this.call_running = true;
        const header = {
            "Content-Type": 'application/json',
            "Accept" : 'application/json',
        };

        const ax = axios.create(
            {
                baseURL : "http://127.0.0.1:9158/rest/",
                Headers : header,
            }
        );

        var querystring = Object.entries(params).map(e=> e.join("=")).join("&");
        command += ("?" + querystring);
        var r;

        try {
            r = await ax.post(command);
        } catch(e)
        {
            console.log(e);
            r = null;
        }

        this.call_running = false;
        return r;
    }
    
    
};

export default FingerPrint;