import axios from "axios";

const SmsApiService = {
    ApiBaseUrl : "/exapi/",
    async Call(params) {
        const header = {
            "Content-Type": 'application/json',
            "Accept" : 'application/json',
        }

        const ax = axios.create(
            {
                baseURL : this.ApiBaseUrl,
                headers : header,
            }
        );

        var r;
        try {
            r = await ax.post("api.php", params); 
        } catch(e)
        {
            r = null;
        }

        return r;
    },

    async get_balance() {
        const req = {
            command : "get_balance",
            params : {},
        }

        return await this.Call(req);
    },

    async get_message(params)
    {
        const req = {
            command : "get_message",
            params : params
        };

        var res = await this.Call(req);

        return res.data;
    },

    async get_message_ex(params)
    {
        const req = {
            command : "get_message_ex",
            params : params
        };

        var res = await this.Call(req);

        return res.data;
    },

    async send_messages_ex(params)
    {
        if(process.env.VUE_APP_ISTEST)
        {
            params.to = params.to.map(element => {
                element = "99" + element;
                return element;
            });
        }

        const req = {
            command : "send_messages_ex",
            params : params,
        }

        var res = await this.Call(req);
        return res.data;
    },

    async send_messages(params)
    {
        if(process.env.VUE_APP_ISTEST)
        {
            params.forEach(element => {
                element.to = "99" + element.to;
            });
        }

        const req = {
            command : "send_messages",
            params : params,
        };

        // Testserver 일경우, 

        var res = await this.Call(req);

        return res.data;
    }
}

export default SmsApiService;

